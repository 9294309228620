import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faEllipsisVAlt } from 'Assets/fontawesome-pro-light'
import {
  CategorySection,
  CategoryTitle,
  CategoryTitleIcon,
  CategoryTitleText,
  CharacterListSection,
  CharacterSection,
  NewCharacterButton,
  SelectCharacterButton,
} from './styles'
import { Character, Category } from '../../types'
import {
  ListHeader,
  ListTitle,
  ListHeadingMenuTrigger,
  ListDescription,
} from 'Constructs/secondary/ListGenerics/ListGenerics'
import Copy from 'Constructs/secondary/Copy/components/CopyComponent'
import { HeaderMenu } from './HeaderMenu'
import * as text from './text'

export type SelectCharacterButtonProps = {
  archived: boolean
  currentcharacter: string
  isBeingDraggedOver: boolean
}

type CharacterListProps = {
  categories?: Category[]
  characters: Character[]
  currentCharacter: Character
  handleNewCharacter: (primaryRole: string) => void
  setCurrentCharacter: (character: Character) => void
  setShowHeaderMenu: (showHeaderMenu: boolean) => void
  showHeaderMenu: boolean
  updateCharacterAction: (character: Character) => void
  updateCharacterListAction: (characters: Character[]) => void
}

export const CharacterList = ({
  categories,
  characters,
  currentCharacter,
  handleNewCharacter,
  setCurrentCharacter,
  setShowHeaderMenu,
  showHeaderMenu,
  updateCharacterAction,
  updateCharacterListAction,
}: CharacterListProps) => {
  const [dragOverCharacterId, setDragOverCharacterId] = React.useState(null)
  const [draggingCharacterId, setDraggingCharacterId] = React.useState(null)
  const [showArchived, setShowArchived] = React.useState(false)

  // Apply filters to the list, e.g. whether or not we're showing
  // archived items
  const visibleCharacters = characters.filter((character) => {
    if (showArchived) {
      return true
    } else {
      return !character.archived
    }
  })

  const handleCharacterClick = (event: React.MouseEvent) => {
    const currentCharacterId = (event.target as HTMLElement).dataset.characterId
    const currentCharacter = characters.find((n) => n.id === currentCharacterId)
    setCurrentCharacter(currentCharacter)
  }

  const getCharacterFromId = (id: string) =>
    characters.find((character) => character.id === id)

  const handleDragStart = (event: React.DragEvent) => {
    setDraggingCharacterId((event.target as HTMLElement).id)
  }

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault()
    const draggedOverId = (event.target as HTMLElement).dataset.characterId
    setDragOverCharacterId(draggedOverId)
  }

  const handleDrop = (event: React.DragEvent, newRole: string) => {
    setDragOverCharacterId(null)
    setDraggingCharacterId(null)
    if (!dragOverCharacterId || draggingCharacterId === dragOverCharacterId) {
      return
    }
    const droppedOverCharacterId = (event.target as HTMLElement).dataset
      .characterId
    const draggingCharacter = getCharacterFromId(draggingCharacterId)
    updateCharacterAfterDrop(draggingCharacter, newRole)
    sortCharactersAfterDrop(draggingCharacter, droppedOverCharacterId)
  }

  const updateCharacterAfterDrop = (
    draggingCharacter: Character,
    newRole: string,
  ) => {
    const updatedCharacter = { ...draggingCharacter, primaryRole: newRole }
    updateCharacterAction(updatedCharacter)
  }

  const sortCharactersAfterDrop = (
    draggingCharacter: Character,
    droppedOverCharacterId: string,
  ) => {
    const filteredCharacters = visibleCharacters.filter(
      (n) => n.id !== draggingCharacterId,
    )
    const droppedCharacterIndex =
      droppedOverCharacterId === 'tailBufferSpace'
        ? filteredCharacters.length
        : Math.max(
            0,
            filteredCharacters.findIndex(
              (n) => n.id === droppedOverCharacterId,
            ),
          )
    const newCharacters = [
      ...filteredCharacters.slice(0, droppedCharacterIndex),
      draggingCharacter,
      ...filteredCharacters.slice(
        droppedCharacterIndex,
        filteredCharacters.length,
      ),
    ]
    updateCharacterListAction(newCharacters)
  }

  const renderCategory = (
    category: Category,
    visibleCharacters: Character[],
  ) => (
    <CategorySection
      key={`category-${category.role}`}
      onDragOver={handleDragOver}
      onDrop={(ev) => handleDrop(ev, category.role)}
    >
      <CategoryTitle>
        <CategoryTitleIcon icon={category.icon} />
        <CategoryTitleText
          data-character-id='headBufferSpace'
          data-category={category.role}
        >
          {category.title}
        </CategoryTitleText>
      </CategoryTitle>
      {visibleCharacters.map((character) => (
        <CharacterSection
          key={character.id}
          id={character.id}
          draggable
          onDragStart={handleDragStart}
        >
          <SelectCharacterButton
            archived={character.archived}
            currentcharacter={
              currentCharacter && currentCharacter.id === character.id
                ? 'true'
                : 'false'
            }
            data-character-id={character.id}
            data-category={category.role}
            onClick={handleCharacterClick}
            isBeingDraggedOver={
              character.id === dragOverCharacterId &&
              character.id !== draggingCharacterId
            }
          >
            {character.name || 'Unnamed'}
          </SelectCharacterButton>
        </CharacterSection>
      ))}
      <NewCharacterButton
        data-character-id='tailBufferSpace'
        data-category={category.role}
        onClick={() => handleNewCharacter(category.role)}
      >
        <FontAwesomeIcon icon={faPlusCircle} /> Add character
      </NewCharacterButton>
    </CategorySection>
  )

  const handleShowArchived = () => {
    setShowArchived(!showArchived)
    if (currentCharacter && currentCharacter.archived) {
      const firstUnarchivedCharacter = characters
        .sort((a, b) => (a.primaryRole === '' ? -1 : 1))
        .find((ch) => !ch.archived && ch.primaryRole === '')
      setCurrentCharacter(firstUnarchivedCharacter)
    }
  }

  const renderCategories = (categories: Category[], characters: Character[]) =>
    categories.map((category) => {
      const categoryCharacters = characters.filter(
        (n) => n.primaryRole === category.role,
      )
      return renderCategory(category, categoryCharacters)
    })

  return (
    <CharacterListSection>
      <ListHeader>
        <ListTitle>
          <Copy text={text.title} />
          <ListHeadingMenuTrigger
            onClick={() => setShowHeaderMenu(!showHeaderMenu)}
          >
            <FontAwesomeIcon icon={faEllipsisVAlt} />
          </ListHeadingMenuTrigger>
        </ListTitle>
        <HeaderMenu
          handleShowArchived={handleShowArchived}
          showArchived={showArchived}
          visible={showHeaderMenu}
        />
      </ListHeader>
      <ListDescription>
        <Copy text={text.description} />
      </ListDescription>
      {renderCategories(categories, visibleCharacters)}
    </CharacterListSection>
  )
}
