import styled from '@emotion/styled'
import { css } from '@emotion/react'

type Props = {
  visible?: boolean
  width?: number
}

export const EditableTitleInput = styled.input`
  border: 1px dashed transparent;

  ${(props: Props) =>
    !props.visible
      ? css`
          width: 0px;
          overflow: hidden;
          height: 0px;
          position: absolute;
          left: -10000px;
        `
      : css`
          width: ${props.width + 20}px;
          line-height: 36px;
          min-width: 240px;
          max-width: 90vw;
        `};

  &::after {
    content: 'h';
    display: block;
  }
  &:hover {
    border: 1px dashed #ddd;
  }
  &:focus {
    border: 1px dashed #bbb;
    outline: none;
  }
  &[disabled] {
    border: 1px dashed transparent;
    background: transparent;
    &:hover {
      cursor: auto;
    }
  }
`
