import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditableTitle from 'Constructs/secondary/EditableTitle'
import * as snippets from 'Constructs/secondary/StyleKit/styleSnippets'

const leftGutter = 40
const topMargin = 20
const statusBarHeight = 30
const navBarHeight = 48

export const BrainstormEditorSection = styled.section`
  ${snippets.fadeIn}
  grid-area: brainstormEditor;
  height: calc(100vh - ${statusBarHeight}px - ${navBarHeight}px);
`

export const BreadcrumbLink = styled(Link)`
  padding-left: ${leftGutter}px;
  margin: ${topMargin}px 0;
  display: block;
  text-decoration: none;
  color: #aaa;
`

export const BreadcrumbIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`

export const BreadcrumbText = styled.span``

export const Title = styled(EditableTitle)`
  margin-left: ${leftGutter}px;
  font-size: 36px;
  font-weight: bold;
`
