import { connect } from 'react-redux'
import { fetchProject, updateProjectAction } from '../stagesActions'
import { ProjectNav } from '../../../secondary/ProjectNav/components/ProjectNavComponent'
import * as selectors from '../stagesSelectors'
import * as s from '../stagesStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from 'Assets/fontawesome-pro-light'
import {
  StagesComponentProps,
  StageComponentState,
  StageLinkInput,
} from '../stagesTypes'
import * as React from 'react'
import { stageLinkInputs } from '../stagesConstants'
import { get } from 'lodash'
import { Redirect } from 'react-router'
import * as text from './../stages.text'
import StatusBar from 'Constructs/secondary/StatusBar/components/StatusBar'

class Stages extends React.Component<
  StagesComponentProps,
  StageComponentState
> {
  state = {
    title: get(this, 'props.project.title'),
  }

  onTitleChange = (title: string) => {
    this.setState({ title })
    this.props.updateProjectAction({ ...this.props.project, title })
  }

  componentDidMount = () => {
    this.props.fetchProject(this.props.match.params.projectId)
  }

  renderStageLink = (stageLinkInput: StageLinkInput) => {
    const project = this.props.project
    const baseProjectRoute = `/app/project/${project.id}`
    const {
      color,
      background,
      title,
      pathSegment,
      icon,
      enabled,
    } = stageLinkInput
    return (
      enabled && (
        <s.StageLink
          key={`stage-link-${title}`}
          to={`${baseProjectRoute}/${pathSegment}`}
        >
          <div>
            <s.StageLinkIcon color={color} background={background}>
              <FontAwesomeIcon icon={icon} />
            </s.StageLinkIcon>
            <s.StageLinkTitle>{title}</s.StageLinkTitle>
          </div>
        </s.StageLink>
      )
    )
  }

  renderStageLinks = (stageLinkInputs: StageLinkInput[]) =>
    stageLinkInputs.map((sli) => this.renderStageLink(sli))

  render() {
    const project = this.props.project
    const linkCount = stageLinkInputs.filter((sli) => sli.enabled).length
    if (!project) {
      if (this.props.telegrams.find((s) => s.type === 'error')) {
        return <Redirect to='/app/projects' />
      }
      return (
        <section>
          <s.ProjectNavPlaceholder />
        </section>
      )
    }

    return (
      <section>
        <ProjectNav
          currentInterface='Stages'
          project={project}
          showTitle={false}
        />
        <s.Heading>
          <s.Title
            placeholder='Untitled'
            value={this.state.title}
            onChange={this.onTitleChange}
          />
          <s.SettingsLink to={`/app/project/${project.id}/config`}>
            <s.SettingsButton icon={faCog} />
          </s.SettingsLink>
        </s.Heading>
        <s.StagesSection linkCount={`${linkCount}`}>
          {this.renderStageLinks(stageLinkInputs)}
        </s.StagesSection>
        <StatusBar help={text.help} />
      </section>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    project: selectors.project(state),
    key: get(selectors.project(state), 'id'),
    telegrams: selectors.telegrams(state),
  }
}

const mapDispatchToProps = { fetchProject, updateProjectAction }

export default connect(mapStateToProps, mapDispatchToProps)(Stages)
