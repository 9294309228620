import * as React from 'react'
import styled from '@emotion/styled'
import * as text from '../projectsText'
import Copy from '../../../secondary/Copy/components/CopyComponent'

type Props = {
  children: React.ReactChild
  className?: string
}

const component = ({ className, children }: Props) => {
  return (
    <div className={className}>
      {children || <Copy text={text.placeholderTitle} />}
    </div>
  )
}

export default styled(component)`
  font-weight: bold;
  font-size: 26px;
  color: hsl(0, 0%, 29%);
  width: 100%;
  height: 100%;
`
