import * as React from 'react'
import { faArrowLeft } from 'Assets/fontawesome-pro-solid'
import { Editor } from 'Constructs/secondary/Editor/EditorBase'
import { Segment } from '../../types'
import { useParams } from 'react-router-dom'
import { updateSegment } from '../../resources/update'
import { ProjectNav } from 'Constructs/secondary/ProjectNav/components/ProjectNavComponent'
import { Header } from 'Constructs/secondary/EditorGenerics/EditorGenerics'
import {
  BreadcrumbLink,
  BreadcrumbIcon,
  BreadcrumbText,
  Title,
  SegmentEditorSection,
} from './segmentEditor.styles'
import { RevisionList } from './RevisionList'

type Props = {
  currentSegment: Segment
  displaystyle?: string
  handleTrash: () => void
  readOnly?: boolean
  header: JSX.Element
  showToolbar?: boolean
  setSegments: (segments: Segment[]) => void
  segments: Segment[]
}

const debounceTime = 1000
const throttleTime = 1000
const maxSaveGap = 6000

type Params = {
  projectId: string
}

export const SegmentEditor = ({
  currentSegment,
  displaystyle,
  handleTrash,
  readOnly,
  header,
  showToolbar,
  setSegments,
  segments,
}: Props) => {
  const { projectId } = useParams<Params>()

  const projectTitle = localStorage.getItem(`project-${projectId}-title`)

  const [title, setTitle] = React.useState(currentSegment.title)
  const [content, setContent] = React.useState(currentSegment.content)
  const [lastEditorChange, setLastEditorChange] = React.useState(Date.now())
  const [lastEditorSave, setLastEditorSave] = React.useState(Date.now())
  const [editorTimeout, setEditorTimeout] = React.useState(null)

  const cleanup: any = React.useRef()

  React.useEffect(() => {
    cleanup.current = {
      lastEditorChange,
      lastEditorSave,
      content,
      currentSegment,
      title,
    }
  }, [lastEditorChange, lastEditorSave, content, currentSegment, title])

  React.useEffect(() => {
    return () => {
      const current = cleanup.current
      if (current.lastEditorChange > current.lastEditorSave) {
        clearTimeout(editorTimeout)
        updateSegment({
          ...current.currentSegment,
          content: current.content,
          title: current.title,
        })
      }
    }
  }, [])

  const onEditorChange = (newContent: string) => {
    setContent(newContent)
    const now = Date.now()
    const timeSinceLastChange = now - lastEditorChange
    const timeSinceLastSave = now - lastEditorSave

    if (
      timeSinceLastSave > maxSaveGap ||
      (timeSinceLastChange > throttleTime && timeSinceLastSave > debounceTime)
    ) {
      clearTimeout(editorTimeout)

      const updatedSegment = { ...currentSegment, content: newContent, title }
      updateSegment(updatedSegment)
      setSegments(
        segments.map((seg) =>
          seg.id === currentSegment.id ? updatedSegment : seg,
        ),
      )

      setLastEditorSave(now)
    } else {
      clearTimeout(editorTimeout)
      setEditorTimeout(
        setTimeout(() => onEditorChange(newContent), throttleTime + 1),
      )
    }
    
    setLastEditorChange(now)
  }

  const onTitleChange = (title: string) => {
    setTitle(title)
    const updatedSegment = { ...currentSegment, title }
    updateSegment(updatedSegment)
    setSegments(
      segments.map((seg) =>
        seg.id === currentSegment.id ? updatedSegment : seg,
      ),
    )
  }

  return (
    <SegmentEditorSection displaystyle={displaystyle}>
      {/* <RevisionList /> */}
      {projectId && (
        <BreadcrumbLink to={`/app/project/${projectId}`}>
          <BreadcrumbIcon icon={faArrowLeft} />
          <BreadcrumbText>Back to Project Stages</BreadcrumbText>
        </BreadcrumbLink>
      )}
      {header && <Header>{header}</Header>}
      <Title
        placeholder='Untitled'
        value={title}
        onChange={onTitleChange}
        displaystyle={displaystyle}
        disabled={readOnly}
      />
      <Editor
        handleTrash={handleTrash}
        initialValue={currentSegment.content}
        onChange={onEditorChange}
        placeholder='Start writing'
        readOnly={readOnly}
        showToolbar={showToolbar}
        displaystyle={displaystyle}
        key={title}
      />
    </SegmentEditorSection>
  )
}
