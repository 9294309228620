import {
  ActivateDocumentParams,
  CreateProjectParams,
  GetProjectParams,
  ProjectIdParams,
  Token,
  UpdateProjectParams,
} from './codec.types'

declare var LWK_API_URL: 'string'

export const fetchAllProjectsForUserRequest = ({ token }: Token) => {
  return fetch(`${LWK_API_URL}/projects/all`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
  })
}

export const getProjectRequest = ({ token, id }: GetProjectParams) => {
  return fetch(`${LWK_API_URL}/projects/id/${id}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
  })
}

export const createProjectRequest = ({
  token,
  project,
}: CreateProjectParams) => {
  return fetch(`${LWK_API_URL}/projects/create`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    method: 'POST',
    body: JSON.stringify(project),
  })
}

export const updateProjectRequest = ({
  token,
  project,
}: UpdateProjectParams) => {
  const formattedProject = { ...project } as any

  if (project.targetCompletionDate) {
    formattedProject.targetCompletionDate = new Date(
      project.targetCompletionDate,
    ).toISOString()
  }

  return fetch(`${LWK_API_URL}/projects/id/${project.id}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    method: 'PUT',
    body: JSON.stringify(formattedProject),
  })
}

export const deleteProjectRequest = ({ token, projectId }: ProjectIdParams) => {
  return fetch(`${LWK_API_URL}/projects/id/${projectId}/delete`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    method: 'DELETE',
  })
}

export const fetchProjectAllInactiveDocumentsRequest = ({
  token,
  projectId,
}: ProjectIdParams) => {
  return fetch(`${LWK_API_URL}/projects/id/${projectId}/all-inactive`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
  })
}

export const activateDocumentRequest = ({
  token,
  projectId,
  documentId,
  entityName,
}: ActivateDocumentParams) => {
  return fetch(
    `${LWK_API_URL}/projects/id/${projectId}/activate/${documentId}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Token: token,
      },
      method: 'PUT',
      body: JSON.stringify({ entityName }),
    },
  )
}
