import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import store from './store'
import Brainstorms from './constructs/primary/Brainstorms/components/Brainstorms'
import Characters from './constructs/primary/Characters/components/Characters'
import Intro from './constructs/primary/Intro/IntroRootComponent'
import Legal from './constructs/primary/Legal/Legal'
import Login from './constructs/generic/Auth/components/Login'
import Logout from './constructs/generic/Auth/components/Logout'
import Manuscript from './constructs/primary/Manuscript/components/Manuscript'
import Plotmaps from './constructs/primary/Plotmaps/components/PlotmapsSection'
import MasterSpinner from './constructs/secondary/Telegram/components/MasterSpinner'
import Premise from './constructs/primary/Premise/components/PremiseComponent'
import Projects from './constructs/primary/Projects/components/ProjectsComponent'
import ProjectConfig from './constructs/primary/Stages/components/ProjectConfig'
import ResetPassword from './constructs/generic/Auth/components/ResetPassword'
import RequestResetPasswordEmail from './constructs/generic/Auth/components/RequestResetPasswordEmail'
import ResetPasswordEmailSent from './constructs/generic/Auth/components/ResetPasswordEmailSent'
import Signup from './constructs/generic/Auth/components/Signup'
import Stages from './constructs/primary/Stages/components/Stages'
import Synopsis from './constructs/primary/Synopsis/components/Synopsis'
import * as auth from './constructs/generic/Auth/components/AuthenticatedRoute'
import AccounConfirmationPending from 'Constructs/generic/Auth/components/AccounConfirmationPending'
import ConfirmAccount from 'Constructs/generic/Auth/components/ConfirmAccount'
import { setStatus } from 'Constructs/secondary/Telegram'
import Settings from 'Constructs/primary/Settings/components/Settings'

declare var HTTPS_ALWAYS: string
declare var NODE_ENV: string
;(window as any).envVars = {
  HTTPS_ALWAYS: HTTPS_ALWAYS,
  NODE_ENV: NODE_ENV,
}

if (HTTPS_ALWAYS === 'true' && !location.protocol.startsWith('https')) {
  window.location.replace(`https://${location.host}${location.pathname}`)
}

// Clear telegram status so that things like 'waitingInForeground' don't persist
// after a refresh
setStatus('idle')

const App = () => {
  return (
    <section>
      <Switch>
        <Route exact path='/app/projects' component={Projects} />
        <Route exact path='/app/project/:projectId' component={Stages} />
        <Route
          path='/app/project/:projectId/config'
          component={ProjectConfig}
        />
        <Route
          path='/app/project/:projectId/brainstorms/:brainstormId?'
          component={Brainstorms}
        />
        <Route path='/app/project/:projectId/premise' component={Premise} />
        <Route path='/app/project/:projectId/synopsis' component={Synopsis} />
        <Route
          path='/app/project/:projectId/characters/:characterId?'
          component={Characters}
        />
        <Route
          path='/app/project/:projectId/settings/:settingId?'
          component={Settings}
        />
        <Route
          path='/app/project/:projectId/plotmaps/:outlineId?'
          component={Plotmaps}
        />
        <Route
          path='/app/project/:projectId/manuscript/:outlineId?/:segmentId?'
          component={Manuscript}
        />
        <Route render={() => <Redirect to='/app/projects' />} />
      </Switch>
    </section>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route
          exact
          path='/'
          component={auth.authenticationAwareRoute(Intro)}
        />
        <Route exact path='/login' component={Login} />
        <Route exact path='/signup' component={Signup} />
        <Route exact path='/trials/beta' component={Signup} />
        <Route exact path='/logout' component={Logout} />
        <Route
          exact
          path='/account-confirmation-pending'
          component={AccounConfirmationPending}
        />
        <Route exact path='/confirm-account' component={ConfirmAccount} />
        <Route exact path='/reset-password' component={ResetPassword} />
        <Route
          exact
          path='/request-reset-password-email'
          component={RequestResetPasswordEmail}
        />
        <Route
          exact
          path='/reset-password-email-sent'
          component={ResetPasswordEmailSent}
        />
        <Route exact path='/terms' render={() => <Legal document='terms' />} />
        <Route
          exact
          path='/privacy-policy'
          render={() => <Legal document='privacy' />}
        />
        <Route path='/app' component={auth.authenticatedRoute(App)} />
        <Route render={() => <Redirect to='/' />} />
      </Switch>
    </Router>
    <MasterSpinner />
  </Provider>,
  document.querySelector('App'),
)
