import * as React from 'react'
import LogoMark from '../../../assets/LogoMark'
import LogoType from '../../../assets/LogoType'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faRocket,
  faUserAstronaut,
  faTachometerAlt,
} from 'Assets/fontawesome-pro-light'
import * as copy from './text'
import Copy from '../../secondary/Copy/components/CopyComponent'
import * as snippets from '../../secondary/StyleKit/styleSnippets'

export const PublicNavSection = styled.section`
  background: #333;
  width: 100%;
  display: grid;
  place-items: center;
  ${snippets.delayedFadeIn}
`

export const PublicNavContainer = styled.section`
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center left;
  padding: 0 30px;
  width: 80vw;
  min-width: 480px;
  svg {
    margin-right: 10px;
  }
`

export const Logo = styled.section`
  min-width: 153px;
`

export const PublicNavLinks = styled.section`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-area: 'publicNavLinks';
  place-items: end;
  place-self: center end;
  min-width: 206px;
`

export const NavLink = styled(Link)`
  color: #fafafa;
  text-decoration: none;
  margin: 0 18px 0 14px;
  &:hover {
    color: white;
    text-decoration: underline;
  }
`

export const NavLinkDivider = styled.div`
  width: 1px;
  height: 100%;
  border-right: 1px solid #999;
`

export const NavLinkIcon = styled(FontAwesomeIcon)`
  color: #03dac6;
  margin-right: 8px;
`
type Props = {
  isLoggedIn: boolean
}

const loggedInLinks = () => (
  <PublicNavLinks>
    <span></span>
    <span></span>
    <NavLink to={`/app/projects`}>
      <NavLinkIcon icon={faTachometerAlt} />
      <Copy text={copy.publicNav.projects} />
    </NavLink>
  </PublicNavLinks>
)

const notLoggedInLinks = () => (
  <PublicNavLinks>
    <NavLink to={`/signup`}>
      <NavLinkIcon icon={faRocket} />
      <Copy text={copy.publicNav.signup} />
    </NavLink>
    <NavLinkDivider />
    <NavLink to={`/login`}>
      <NavLinkIcon icon={faUserAstronaut} />
      <Copy text={copy.publicNav.login} />
    </NavLink>
  </PublicNavLinks>
)

export default ({ isLoggedIn }: Props) => (
  <PublicNavSection>
    <PublicNavContainer>
      <Logo>
        <LogoMark />
        <LogoType />
      </Logo>
      {isLoggedIn ? loggedInLinks() : notLoggedInLinks()}
    </PublicNavContainer>
  </PublicNavSection>
)
