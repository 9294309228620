import * as React from 'react'
import { CopyText, Language } from 'Constructs/secondary/Copy/types'
import { Toolbar } from './sub/Toolbar/Toolbar'
import { SupplementalBar } from './EditorBase.styles'
import { EditorSection, ContentSection } from './EditorBase.styles'
import { isKeyHotkey } from 'is-hotkey'

type Props = {
  className?: string
  handleTrash?: () => void
  initialValue: string
  language?: Language
  onChange: (params: Object) => void
  onFocus?: (params: Object) => void
  onKeyDown?: (params: Object) => void
  onSelect?: (params: Object) => void
  placeholder?: string
  readOnly?: boolean
  showToolbar?: boolean
  displaystyle?: string
}

const activeMarkTags = ['UL', 'OL', 'HIGHLIGHT']

const terminalTags = ['DIV', 'P', 'SECTION', 'LI', 'UL', 'OL']

const findActiveMarks = (parent: HTMLElement): string[] => {
  let tags = ['UL', 'OL', 'HIGHLIGHT']
  let activeMarks = [] as string[]
  while (parent) {
    const tag = tags.find((t) => t === parent.tagName)
    if (tag) {
      activeMarks.push(tag)
      tags = tags.filter((t) => t !== tag)
    }

    const pid = parent.getAttribute('id')
    if (pid === 'editor') {
      parent = null
    } else {
      parent = parent.parentElement
    }
  }

  return activeMarks
}

const flatten = (el: HTMLElement | ChildNode) =>
  el.childNodes && el.childNodes.length
    ? [...el.childNodes].flatMap((nn): any => flatten(nn))
    : el

type flatMetaNode = {
  el: ChildNode
  startsWithBreak: boolean
  endsWithBreak: boolean
}

const metaFlatten = (
  el: HTMLElement | ChildNode,
  startsWithBreak: boolean = false,
  endsWithBreak: boolean = false,
) =>
  el.childNodes && el.childNodes.length
    ? [...el.childNodes].flatMap((nn, i): any =>
        metaFlatten(
          nn,
          (startsWithBreak ||
            (el as any).computedStyleMap().get('display').value === 'block') &&
            i === 0,
          (endsWithBreak ||
            (el as any).computedStyleMap().get('display').value === 'block') &&
            i === el.childNodes.length - 1,
        ),
      )
    : {
        el,
        startsWithBreak,
        endsWithBreak,
      }

export const Editor = ({
  initialValue,
  onChange,
  placeholder,
  showToolbar = true,
  handleTrash,
  displaystyle,
  readOnly,
  className,
}: Props) => {
  const [loaded, setLoaded] = React.useState(false)
  const [activeMarks, setActiveMarks] = React.useState([] as string[])

  const keydownListener = (e: KeyboardEvent) => {
    const sel = window.getSelection()
    
    if (isKeyHotkey('mod+right')(e) && sel.type === 'Range') {
      e.preventDefault()

      const range = sel.getRangeAt(0)
      console.log("🚀 ~ file: EditorBase.tsx ~ line 104 ~ keydownListener ~ range", range)

      const content = range.extractContents()

      const flatContents = metaFlatten(
        document.querySelector('#editor'),
      ) as flatMetaNode[]

      let flatIndex = flatContents.findIndex(
        (mnode) => mnode.el === range.endContainer,
      )
      console.log("🚀 ~ file: EditorBase.tsx ~ line 114 ~ keydownListener ~ flatIndex", flatIndex)
      if (flatIndex === -1 ) {
        console.log(range)
        console.log(range.endContainer)
        console.log("🚀 ~ file: EditorBase.tsx ~ line 111 ~ keydownListener ~ flatContents", flatContents.map(x => x.el))
        return
      }
      let insertionOffset = range.endOffset
      let insertionNode
      const breaker = /\W|\s/g

      for (let i = flatIndex; i < flatContents.length; i++) {
        const mnode = flatContents[i]
        let text = mnode.el.textContent
        let trimmedStarterLength = 0
        if (flatIndex === i) {
          text = text.slice(range.endOffset, text.length)
          const startingSpaceMatch = text.match(/^\s+/)
          if (startingSpaceMatch) {
            trimmedStarterLength = startingSpaceMatch[0].length
            text = text.replace(/^\s+/, '')
          }
        }

        if (flatIndex !== i && mnode.startsWithBreak) {
          insertionNode = mnode.el
          insertionOffset = 0
          break
        }

        if (breaker) {
          const wordBreakIndex = text.search(breaker)
          if (wordBreakIndex > -1) {
            const isInclusiveBreaker = false
            // granularity === 'sentence' && /\.|!|\?/.test(text)
            insertionNode = mnode.el
            insertionOffset =
              wordBreakIndex +
              (flatIndex === i ? insertionOffset : 0) +
              (isInclusiveBreaker ? 1 : 0) +
              trimmedStarterLength
            break
          }
        }

        if (mnode.endsWithBreak) {
          insertionNode = mnode.el
          insertionOffset =
            text.length + (flatIndex === i ? insertionOffset : 0)
          break
        }
      }

      insertionNode
      insertionOffset
      console.log(
        '🚀 ~ file: EditorBase.tsx ~ line 158 ~ keydownListener ~ insertionOffset',
        insertionOffset,
      )
      console.log(
        '🚀 ~ file: EditorBase.tsx ~ line 158 ~ keydownListener ~ insertionNode',
        insertionNode,
      )
      if (content.firstChild) {
        insertionNode.textContent = insertionNode.textContent + ' '
        insertionOffset += 1
      }

      range.setStart(insertionNode, insertionOffset) 
      range.insertNode(content)
      sel.setBaseAndExtent(insertionNode, insertionOffset, content, 0)
    }

    if (isKeyHotkey('mod+shift+e')(e)) {
      // determine what's selected now: nothing, word, phrase, sentence, paragraph
      let granularity
      const selectionText = sel.toString()
      if (!selectionText) {
        granularity = 'word'
        // crawl left until space
      } else if (/\.|!|\?/.test(selectionText)) {
        granularity = 'paragraph'
      } else {
        granularity = 'sentence'
      }

      const startingRange = sel.getRangeAt(0)
      const endingRange = sel.getRangeAt(sel.rangeCount - 1) // presumably, this will always be the same as the starting range

      let breaker
      if (granularity === 'word') {
        breaker = /\W|\s/g
      } else if (granularity === 'sentence') {
        breaker = /\n|\r|\.|!|\?/g
      }

      let anchorNode = startingRange.startContainer
      let anchorOffset = startingRange.startOffset
      let focusNode = endingRange.startContainer
      let focusOffset = endingRange.endOffset

      const flatContents = metaFlatten(
        document.querySelector('#editor'),
      ) as flatMetaNode[]

      let flatIndex = flatContents.findIndex((mnode) => mnode.el === anchorNode)

      for (let i = flatIndex; i >= 0; i--) {
        const mnode = flatContents[i]
        let text = mnode.el.textContent
        if (flatIndex === i) {
          text = text.slice(0, anchorOffset)
        }

        if (flatIndex !== i && mnode.endsWithBreak) {
          anchorNode = mnode.el
          anchorOffset = text.length
          break
        }

        if (breaker) {
          const matchingWordBreak = text.match(breaker)
          if (matchingWordBreak) {
            const wordBreakIndex = text.lastIndexOf(
              matchingWordBreak[matchingWordBreak.length - 1],
            )
            anchorNode = mnode.el
            anchorOffset = wordBreakIndex + 1
            break
          }
        }

        if (mnode.startsWithBreak) {
          anchorNode = mnode.el
          anchorOffset = 0
          break
        }
      }

      for (let i = flatIndex; i < flatContents.length; i++) {
        const mnode = flatContents[i]
        let text = mnode.el.textContent
        if (flatIndex === i) {
          text = text.slice(focusOffset, text.length)
        }

        if (flatIndex !== i && mnode.startsWithBreak) {
          focusNode = mnode.el
          focusOffset = 0
          break
        }

        if (breaker) {
          const wordBreakIndex = text.search(breaker)
          if (wordBreakIndex > -1) {
            const isInclusiveBreaker =
              granularity === 'sentence' && /\.|!|\?/.test(text)
            focusNode = mnode.el
            focusOffset =
              wordBreakIndex +
              (flatIndex === i ? focusOffset : 0) +
              (isInclusiveBreaker ? 1 : 0)
            break
          }
        }

        if (mnode.endsWithBreak) {
          focusNode = mnode.el
          focusOffset = text.length + (flatIndex === i ? focusOffset : 0)
          break
        }
      }

      sel.setBaseAndExtent(anchorNode, anchorOffset, focusNode, focusOffset)

      // conditionally expand selection
    }
  }

  const keyupListener = (e: KeyboardEvent) => {
    const sel = window.getSelection()
    setActiveMarks(findActiveMarks(sel.anchorNode.parentElement))
  }

  const clickListener = (e: MouseEvent) => {
    const sel = window.getSelection()
    setActiveMarks(findActiveMarks(sel.anchorNode.parentElement))
  }

  const inputListener = (e: InputEvent) => {
    const el = e.target as HTMLElement

    onChange(el.innerHTML)
  }

  React.useEffect(() => {
    const editorEl = document.querySelector('#editor')

    editorEl.innerHTML = initialValue

    editorEl.addEventListener('keyup', keyupListener)
    editorEl.addEventListener('keydown', keydownListener)
    editorEl.addEventListener('click', clickListener)
    editorEl.addEventListener('input', inputListener)
    // editorEl.addEventListener('beforeinput', inputListener)
    return () => {
      editorEl.removeEventListener('keyup', keyupListener)
      editorEl.removeEventListener('keydown', keydownListener)
      editorEl.removeEventListener('click', clickListener)
      editorEl.removeEventListener('input', inputListener)
      // editorEl.removeEventListener('beforeinput', inputListener)
    }
  }, [loaded])

  return (
    <EditorSection className={className} displaystyle={displaystyle}>
      {showToolbar && !readOnly && (
        <React.Fragment>
          <Toolbar
            handleTrash={handleTrash}
            toggleBackspace={() => {}}
            backspaceEnabled={true}
            isActive={(type) => {
              if (activeMarkTags.includes(type)) {
                return activeMarks.includes(type)
              }
              return document.queryCommandState(type) === true
            }}
            toggle={(type, value) => {
              document.execCommand(type, true, value)
              setActiveMarks([type, ...activeMarks])
            }}
            wrap={() => {}}
          />
          <SupplementalBar enabled={true}>
            {/* <Synonyms editor={editor.current} value={content} /> */}
            {/* <Wordcount>{wordCount}</Wordcount> */}
          </SupplementalBar>
        </React.Fragment>
      )}
      <ContentSection
        contentEditable
        data-domlabel={'contentEditor'}
        readOnly={readOnly}
        displaystyle={displaystyle}
        id='editor'
        placeholder={placeholder}
      ></ContentSection>
    </EditorSection>
  )
}
