import { createAction, createAsyncAction } from 'typesafe-actions'
import { Dispatch } from 'redux'

import { Signal, SignalCreator, TransmissionStatus } from './types'
import * as constants from './constants'

export const addSignalToQueue = createAction(
  'ADD_TELEGRAM_SIGNAL',
  (resolve) => (signal: Signal) => {
    return resolve(signal)
  },
)

export const removeSignalFromQueue = createAction(
  'REMOVE_TELEGRAM_SIGNAL',
  (resolve) => (signal: Signal) => {
    return resolve(signal)
  },
)

export const setStatus = createAction(
  'SET_TELEGRAM_STATUS',
  (resolve) => (status: TransmissionStatus) => {
    return resolve(status)
  },
)

export const clearQueue = createAction('CLEAR_QUEUE')

export const createSignal = (signalCreator: SignalCreator) => ({
  timestamp: new Date(),
  type: signalCreator.type,
  text: signalCreator.text,
  copy: signalCreator.copy,
  dismissable:
    'dismissable' in signalCreator ? signalCreator.dismissable : false,
  momentary:
    'momentary' in signalCreator
      ? signalCreator.momentary
      : signalCreator.type === 'request'
      ? false
      : true,
  persistence: signalCreator.persistence || 'bumpable',
  blocking: signalCreator.blocking || false,
})

export const signal = (signalCreator: SignalCreator) => (
  dispatch: any,
  getState: Function,
) => {
  const signal = createSignal(signalCreator)
  dispatch(addSignalToQueue(signal))
  switch (signal.type) {
    case 'request':
      if (signal.blocking) {
        dispatch(setStatus('waitingForeground'))
      } else {
        dispatch(setStatus('waitingBackground'))
      }
      break
    case 'error':
      console.error(signal.text, signal)
    case 'success':
      dispatch(setStatus('idle'))
  }
  signal.momentary
    ? setTimeout(() => {
        dispatch(removeSignalFromQueue(signal))
      }, constants.momentarySignalDuration)
    : null
}
