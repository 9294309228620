import * as requests from './requests/getByProjectId'
import { getResource } from '../../../../integrations/litwitkit/shared'
import { Character } from '../types'
import { setStatus } from 'Constructs/secondary/Telegram'

export const getByProjectId = async (
  projectId: string,
  handleSuccess: (characters: Character[]) => void,
  handleError: (e: any) => void,
): Promise<void> => {
  try {
    setStatus('waitingForeground')
    const storageKey = `project-${projectId}-characters`
    const request = (token: string) => requests.getByProjectId(token, projectId)

    const resource = await getResource(storageKey, request)
    handleSuccess(resource)
    setStatus('idle')
  } catch (e) {
    handleError(e)
  }
}
