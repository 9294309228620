import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { fadeIn } from '../../secondary/StyleKit/styleSnippets'

export const ProjectsSection = styled.section`
  ${fadeIn}
  background: #eee;
  display: grid;
  grid-template-areas:
    'projectNav'
    'projectsContainer';
`

export const ProjectsContainer = styled.section`
  grid-area: projectsContainer;
  label: projectsContainer;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 150px;
  grid-gap: 40px;
  min-height: 100vh;
  padding: 50px 80px 80px;
`

export const ProjectTile = styled.div`
  background: #fafafa;
  border-radius: 4px;
  box-sizing: border-box;
  display: grid;
  min-height: 150px;
  height: fit-content;
  padding: 23px 31px;
  place-items: center;
  width: 100%;
  display: grid;
  grid-template-areas:
    'projectTitle projectProgress'
    'projectMetaData projectMetaData';
`

export const ProjectLink = styled(Link)`
  text-decoration: inherit;
  width: 100%;
  height: 100%;
  color: inherit;
  opacity: 0.74;
  &:hover {
    opacity: 1;
  }
`

export const DeleteProjectButton = styled.button`
  border-width: 3px;
  padding: 10px;
  text-align: left;
  width: 100%;
`
