import * as React from 'react'
import styled from '@emotion/styled'
import Metadata from './Metadata'
import { faUser, faTag, faClock } from 'Assets/fontawesome-pro-light'
import { Project } from '../../../../integrations/litwitkit/entities'

type Props = {
  project: Project
}

const MetadataSection = styled.section`
  display: grid;
  grid-area: projectMetaData;
  place-items: start;
  width: 100%;
`

export default ({ project }: Props) => {
  return (
    <MetadataSection>
      <Metadata
        values={project.authors.length ? project.authors : ['Anonymous']}
        icon={faUser}
        defaultValue='Anonymous'
      />
      <Metadata
        values={[
          project.updatedAt
            ? new Date(project.updatedAt).toLocaleDateString()
            : new Date(project.createdAt).toLocaleDateString(),
        ]}
        icon={faClock}
        defaultValue='Anonymous'
      />
      {/* <Metadata
        values={project.bisacCategories || ['General']}
        icon={faTag}
        defaultValue='General'
      /> */}
    </MetadataSection>
  )
}
