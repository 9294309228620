import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { PremiseComponentProps } from '../premiseTypes'

import { ChecklistEditor } from '../../../secondary/ChecklistEditor'
import {
  fetchPremiseNote,
  fetchPremiseChecklist,
  updateNoteAction,
  updateChecklistAction,
  resetPremise,
} from '../premiseActions'
import * as selectors from '../premiseSelectors'
import { ProjectNav } from '../../../secondary/ProjectNav/components/ProjectNavComponent'
import { PremiseSection } from './premiseStyles'
import * as text from '../premiseText'
import { RootState } from '../../../../rootReducer'

class Premise extends React.Component<PremiseComponentProps> {
  componentDidMount = () => {
    this.props.fetchPremiseNote(this.props.match.params.projectId)
    this.props.fetchPremiseChecklist(this.props.match.params.projectId)
  }

  componentWillUnmount = () => {
    this.props.resetPremise()
  }

  render() {
    return (
      <PremiseSection>
        <ProjectNav currentInterface='Premise' project={this.props.project} />
        {this.props.premiseNote && (
          <React.Fragment>
            {/* <ChecklistEditor
              title={text.checklistEditor.title}
              description={text.checklistEditor.description}
              updateChecklistAction={this.props.updateChecklistAction}
              checklist={this.props.premiseChecklist}
            /> */}
            {/* <NoteEditor
              key={`premise-editor-${this.props.premiseNote.id}`}
              note={this.props.premiseNote}
              isTitleEditable={false}
              updateNoteAction={this.props.updateNoteAction}
              project={this.props.project}
            /> */}
          </React.Fragment>
        )}
      </PremiseSection>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  premiseNote: selectors.premiseNote(state),
  premiseChecklist: selectors.premiseChecklist(state),
  project: selectors.project(state),
})

const mapDispatchToProps = {
  fetchPremiseNote,
  fetchPremiseChecklist,
  resetPremise,
  updateNoteAction,
  updateChecklistAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(Premise)
