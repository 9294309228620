import * as React from 'react'
import { get } from 'lodash'
import {
  Setting,
  SettingLabel,
  SettingTextListInput,
  AddButton,
  AddButtonIcon,
} from '../stagesStyles'
import { updateProjectAction } from '../stagesActions'
import * as selectors from '../stagesSelectors'
import { connect } from 'react-redux'
import { faPlusCircle } from 'Assets/fontawesome-pro-light'
import { Project } from '../../../../integrations/litwitkit/entities'

type Props = {
  buttonText: string
  description: string
  field: string
  label: string
  placeholder: string
  project: Project
  updateProjectAction: (project: Project) => void
}

const NameListSetting = ({
  buttonText,
  description,
  field,
  label,
  placeholder,
  project,
  updateProjectAction,
}: Props) => {
  const [valArr, setValArr] = React.useState(get(project, field) || [])

  const handleChange = (newVal: string, changedIndex: number) => {
    const newArr = valArr.map((name: string, index: number) =>
      index === changedIndex ? newVal : name,
    )
    setValArr(newArr)
    updateProjectAction({
      ...project,
      ...{ [field]: newArr },
    })
  }

  const handleBlur = (newVal: string, changedIndex: number) => {
    const newArr = valArr.filter(
      (name: string, index: number, a: string[]) =>
        index === a.length - 1 || Boolean(name),
    )
    setValArr(newArr)
    if (newArr.length === valArr.length) {
      return
    }
    updateProjectAction({
      ...project,
      ...{ [field]: newArr },
    })
  }

  const handleAdd = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (valArr.length && !valArr[valArr.length - 1]) {
      return
    }
    setValArr([...valArr, ''])
  }

  return (
    <Setting key={field}>
      <SettingLabel>{label}</SettingLabel>
      {valArr.map((name: string, i: number) => (
        <SettingTextListInput
          type='text'
          placeholder={placeholder}
          key={`${field}-${i}`}
          value={name}
          onChange={(e) => handleChange(e.target.value, i)}
          onBlur={(e) => handleBlur(e.target.value, i)}
        ></SettingTextListInput>
      ))}
      <AddButton onClick={handleAdd}>
        <AddButtonIcon icon={faPlusCircle} />
        {buttonText}
      </AddButton>
    </Setting>
  )
}

const mapStateToProps = (state: any) => {
  return {
    project: selectors.project(state),
  }
}

const mapDispatchToProps = { updateProjectAction }

export default connect(mapStateToProps, mapDispatchToProps)(NameListSetting)
