import { Signal, SignalCreator, TransmissionStatus } from './types'
import * as constants from './constants'
import { jsonTransformer } from '../../../utilities/useLocalState'

export const addSignalToQueue = (signal: Signal) => {
  const signalQueue = jsonTransformer.app(
    localStorage.getItem('telegram-signal-queue'),
    [],
  ) as Signal[]

  const newSignalQueue = [
    ...signalQueue.filter((s) => s.persistence !== 'bumpable'),
    signal,
  ]

  localStorage.setItem(
    'telegram-signal-queue',
    jsonTransformer.storage(newSignalQueue, []),
  )
}

export const removeSignalFromQueue = (signal: Signal) => {
  const signalQueue = jsonTransformer.app(
    localStorage.getItem('telegram-signal-queue'),
    [],
  ) as Signal[]

  const newSignalQueue = signalQueue.filter(
    (s) => s.timestamp === signal.timestamp,
  )

  localStorage.setItem(
    'telegram-signal-queue',
    jsonTransformer.storage(newSignalQueue, []),
  )
}

export const setStatus = (status: TransmissionStatus) => {
  localStorage.setItem('telegram-transmission-status', status)
}

export const clearQueue = () => {
  localStorage.setItem('telegram-signal-queue', jsonTransformer.storage([], []))
}

const createSignal = (signalCreator: SignalCreator) => ({
  timestamp: new Date(),
  type: signalCreator.type,
  text: signalCreator.text,
  copy: signalCreator.copy,
  dismissable:
    'dismissable' in signalCreator ? signalCreator.dismissable : false,
  momentary:
    'momentary' in signalCreator
      ? signalCreator.momentary
      : signalCreator.type === 'request'
      ? false
      : true,
  persistence: signalCreator.persistence || 'bumpable',
  blocking: signalCreator.blocking || false,
})

export const signal = (signalCreator: SignalCreator) => {
  const signal = createSignal(signalCreator)
  addSignalToQueue(signal)
  switch (signal.type) {
    case 'request':
      if (signal.blocking) {
        setStatus('waitingForeground')
      } else {
        setStatus('waitingBackground')
      }
      break
    case 'error':
      console.error(signal.text, signal)
    case 'success':
      setStatus('idle')
  }
  signal.momentary
    ? setTimeout(() => {
        removeSignalFromQueue(signal)
      }, constants.momentarySignalDuration)
    : null
}
