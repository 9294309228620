import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRocket, faUserAstronaut } from 'Assets/fontawesome-pro-light'
import Copy from '../../secondary/Copy/components/CopyComponent'
import validator from 'validator'
import * as copy from './text'

const FooterSection = styled.section`
  color: #999;
  width: 100%;
  display: grid;
  place-items: center;
`

const FooterContent = styled.section`
  width: 80vw;
  text-align: center;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-area: 'footerContent';
  place-items: center;
  min-width: 206px;
  padding: 80px 0;
`

export const FooterCopy = styled(Copy)`
  color: white;
  margin-bottom: 35px;
  font-size: 22px;
`

export const FooterLink = styled(Link)`
  border-radius: 6px;
  border: 2px solid hsla(174, 100%, 44%, 0.3);
  color: #efefef;
  font-size: 25px;
  margin: 0 18px 0 14px;
  opacity: 0.8;
  padding: 20px;
  text-decoration: none;
  &:hover {
    background: hsla(174, 100%, 44%, 0.1);
    border: 2px solid hsla(174, 100%, 44%, 0.66);
    color: white;
    opacity: 1;
    svg {
      opacity: 1;
    }
  }
  svg {
    opacity: 0.7;
  }
`

export const NavLinkDivider = styled.div`
  width: 1px;
  height: 100%;
  border-right: 1px solid #999;
`

export const FooterLinkIcon = styled(FontAwesomeIcon)`
  color: #03dac6;
  margin-right: 8px;
`

export const Form = styled.form`
  font-size: 17px;
`

export const FormInput = styled.input`
  display: block;
  margin-bottom: 20px;
  width: 100%;
  padding: 13px;
  box-sizing: border-box;
  border: 1px solid #eee;
  border-radius: 3px;
  box-shadow: 0 0 0px 100px #fafafa inset;
`

export default () => {
  const [email, setEmail] = React.useState('')
  const [validationMet, setValidationMet] = React.useState(false)

  const handleEmailInput = (e: any) => {
    const newEmail = e.target.value
    setEmail(newEmail)
    setValidationMet(validator.isEmail(newEmail))
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (!validationMet) {
      return
    }
  }

  return (
    <FooterSection>
      <FooterContent>
        <FooterCopy text={copy.footer.blurb} isStyled={true} />
        <Form>
          <FooterLink to={`/signup`}>
            <FooterLinkIcon icon={faRocket} />
            <Copy text={copy.footer.action} />
          </FooterLink>
        </Form>
      </FooterContent>
    </FooterSection>
  )
}
