import { transformLwkResponse } from '../../../../../integrations/litwitkit/shared'
import { Setting } from '../../types'

declare var LWK_API_URL: 'string'

export type UpdateSettingRequest = {
  archived: boolean
  description: string
  category: string
  title: string
  projectId: string
}

export const updateById = async (
  token: string,
  id: string,
  settingUpdate: UpdateSettingRequest,
): Promise<Setting> => {
  const rawResponse = await fetch(`${LWK_API_URL}/settings/id/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    body: JSON.stringify(settingUpdate),
  })

  const { response } = await transformLwkResponse(rawResponse)
  return response as Setting
}
