export const publicNav = {
  signup: {
    english: 'Sign up',
  },
  login: {
    english: 'Log in',
  },
  projects: {
    english: 'Dashboard',
  },
}

export const header = {
  headline: {
    english: 'Write your best work.',
  },
  definition: {
    english: `From idea to manuscript, LitWitKit has a comprehensive set of 
    tools for every stage of your creative process. `,
  },
}

export const stripe = {
  plotMapping: {
    english: 'Visual Plot Mapping',
  },
  progressTracking: {
    english: 'Customizable Progress Tracking',
  },
  characterDev: {
    english: 'Character Development Aids',
  },
  revisionHistory: {
    english: 'Revision History',
  },
}

export const footer = {
  blurb: {
    english: 'Try LitWitKit for free.',
  },
  action: {
    english: 'Sign up!'
  }
}
