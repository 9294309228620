import { Category } from './types'
import {
  faUserAstronaut,
  faTrophyAlt,
  faScarecrow,
  faDiamond,
  faAppleAlt,
  faHeart,
  faPeopleCarry,
  faHamsa,
} from 'Assets/fontawesome-pro-solid'

export const categories: Category[] = [
  { title: 'General', role: '', icon: faUserAstronaut },
  { title: 'Protagonists', role: 'protagonists', icon: faTrophyAlt },
  { title: 'Antagonists', role: 'antagonists', icon: faScarecrow },
  { title: 'Foils', role: 'foils', icon: faDiamond },
  { title: 'Mentors', role: 'mentors', icon: faAppleAlt },
  { title: 'Love Interests', role: 'love-interests', icon: faHeart },
  { title: 'Supports', role: 'supports', icon: faPeopleCarry },
  { title: 'Guides', role: 'guides', icon: faHamsa },
]
