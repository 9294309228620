import * as React from 'react'
import { Editor } from 'Constructs/secondary/Editor/EditorBase'
import {
  Title,
  BreadcrumbLink,
  BreadcrumbIcon,
  BreadcrumbText,
  CharacterEditorSection,
  EditorHeader,
} from './styles'
import {
  Character,
  // Project,
} from '../../types'
import { faArrowLeft } from 'Assets/fontawesome-pro-solid'
import { Header } from '../../../../secondary/EditorGenerics/EditorGenerics'

type CharacterEditorProps = {
  currentCharacter: Character
  handleTrash: () => void
  projectId?: string
  readOnly?: boolean
  header: JSX.Element
  updateCharacterAction: Function
}

const debounceTime = 1000
const throttleTime = 1000
const maxSaveGap = 6000

export const CharacterEditor = ({
  currentCharacter,
  handleTrash,
  projectId,
  readOnly,
  header,
  updateCharacterAction,
}: CharacterEditorProps) => {
  const [name, setName] = React.useState(currentCharacter.name)
  const [description, setDescription] = React.useState(
    currentCharacter.description,
  )
  const [biography, setBiography] = React.useState(currentCharacter.biography)
  const [lastDescriptionChange, setLastDescriptionChange] = React.useState(0)
  const [lastDescriptionSave, setLastDescriptionSave] = React.useState(0)
  const [descriptionTimeout, setDescriptionTimeout] = React.useState(null)
  // const [lastBiographyChange, setLastBiographyChange] = React.useState(0)
  // const [lastBiographySave, setLastBiographySave] = React.useState(0)
  // const [BiographyTimeout, setBiographyTimeout] = React.useState(null)

  const onNameChange = (name: string) => {
    setName(name)
    updateCharacterAction({ ...currentCharacter, name, description, biography })
  }

  const onDescriptionChange = (newContent: string) => {
    setDescription(newContent)
    const timeSinceLastChange = Date.now() - lastDescriptionChange
    const timeSinceLastSave = Date.now() - lastDescriptionSave

    if (
      timeSinceLastSave > maxSaveGap ||
      (timeSinceLastChange > throttleTime && timeSinceLastSave > debounceTime)
    ) {
      clearTimeout(descriptionTimeout)
      updateCharacterAction({
        ...currentCharacter,
        name,
        description: newContent,
        biography,
      })
      setLastDescriptionSave(Date.now())
    } else {
      clearTimeout(descriptionTimeout)
      setDescriptionTimeout(
        setTimeout(() => onDescriptionChange(newContent), throttleTime + 1),
      )
    }
    setLastDescriptionChange(Date.now())
  }

  // const onBiographyChange = (newContent: any) => {
  //   setBiography(newContent)
  //   updateCharacterAction({
  //     ...currentCharacter,
  //     name,
  //     description,
  //     biography: newContent,
  //   })
  // }

  return (
    <CharacterEditorSection>
      <EditorHeader>
        {projectId && (
          <BreadcrumbLink to={`/app/project/${projectId}`}>
            <BreadcrumbIcon icon={faArrowLeft} />
            <BreadcrumbText>Back to Project Stages</BreadcrumbText>
          </BreadcrumbLink>
        )}
        {header && <Header>{header}</Header>}
        <Title
          placeholder='Unnamed'
          value={name}
          onChange={onNameChange}
          disabled={readOnly}
        />
      </EditorHeader>

      <Editor
        onChange={onDescriptionChange}
        placeholder='Start writing'
        readOnly={readOnly}
        handleTrash={handleTrash}
        initialValue={currentCharacter.description}
        key={name}
      />
    </CharacterEditorSection>
  )
}
