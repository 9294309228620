import { transformLwkResponse } from '../../../../../integrations/litwitkit/shared'
import { Outline, Segment, SegmentType } from '../../types'

declare var LWK_API_URL: 'string'

export type CreateOutlineRequest = {
  placement: number
  projectId: string
}

export type CreateSegmentRequest = {
  projectId: string
  type: SegmentType
}

export const createOutline = async (
  token: string,
  outlineRequest: CreateOutlineRequest,
): Promise<Outline> => {
  const rawResponse = await fetch(
    `${LWK_API_URL}/manuscripts/outlines/create`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Token: token,
      },
      body: JSON.stringify(outlineRequest),
    },
  )
  const { response } = await transformLwkResponse(rawResponse)
  return response as Outline
}

export const createSegment = async (
  token: string,
  segmentRequest: CreateSegmentRequest,
): Promise<Segment> => {
  const rawResponse = await fetch(
    `${LWK_API_URL}/manuscripts/segments/create`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Token: token,
      },
      body: JSON.stringify(segmentRequest),
    },
  )
  const { response } = await transformLwkResponse(rawResponse)
  return response as Segment
}
