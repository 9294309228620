export const title = {
  english: 'Characters',
}

export const description = {
  english: 'Characters are the building blocks of your story.',
}

export const showArchived = {
  english: 'Show archived',
}
