import * as React from 'react'

export default (
  <section>
    <h1>Terms of Service</h1>
    <p>
      These Terms of Service ("Terms") are a contract between you and LitWitKit.
      They govern your use of LitWitKit’s sites, services, mobile apps,
      products, and content ("Services").
    </p>
    <p>
      By using LitWitKit, you agree to these Terms. If you don’t agree to any of
      the Terms, you can’t use LitWitKit.
    </p>
    <p>
      We can change these Terms at any time. We keep a historical record of all
      changes to our Terms. If a change is material, we’ll let you know before
      they take effect. By using LitWitKit on or after that effective date, you
      agree to the new Terms. If you don’t agree to them, you should delete your
      account before they take effect, otherwise your use of the site and
      content will be subject to the new Terms.
    </p>
    <h2>Content rights and responsibilities</h2>
    <strong>
      You retain ownership of any intellectual property rights to the content
      you create on LitWitKit.
    </strong>
    <p>
      By creating content through our Services, you give LitWitKit a
      nonexclusive, worldwide license to use, host, store, reproduce, reformat,
      and transmit such content. The rights you grant in this license are for
      the limited purpose of operating, promoting, and improving our Services,
      and to develop new ones.
    </p>
    <p>
      You’re responsible for the content you create using our Services. This
      means you assume all risks related to it, including someone else’s
      reliance on its accuracy, or claims relating to intellectual property or
      other legal rights.
    </p>
    <p>
      Our automated systems analyze your content to provide you relevant product
      features, such as editing suggestions, content analytics, advertisements,
      and reward points. This analysis occurs as the content is sent, received,
      and when it is stored.
    </p>
    <p>
      We believe that you own your data and preserving your access to such data
      is important. If we discontinue our Service, where reasonably possible, we
      will give you reasonable advance notice and a chance to get your data out
      of that Service.
    </p>
    <p>
      You can delete any of your content, or your account, anytime. Processing
      the deletion may not be immediate and may take time. We may retain backup
      copies of your deleted content or account on our servers.
    </p>
    <h2>Our content and services</h2>
    <p>
      We reserve all rights in LitWitKit’s look, feel, and user interface. Some
      parts of LitWitKit are licensed under third-party open source licenses. We
      also make some of our own code available under open source licenses. As
      for other parts of LitWitKit, you may not copy or adapt any portion of our
      code or visual design elements (including logos) without express written
      permission from LitWitKit unless otherwise permitted by law.
    </p>
    <p>
      You may not do, or try to do, the following: (1) access or tamper with
      non-public areas of the Services, our computer systems, or the systems of
      our technical providers; (2) access or search the Services by any means
      other than the currently available, published interfaces (e.g., APIs) that
      we provide; (3) forge any TCP/IP packet header or any part of the header
      information in any email or posting, or in any way use the Services to
      send altered, deceptive, or false source-identifying information; or (4)
      interfere with, or disrupt, the access of any user, host, or network,
      including sending a virus, overloading, flooding, spamming, mail-bombing
      the Services, or by scripting the creation of content or accounts in such
      a manner as to interfere with or create an undue burden on the Services.
    </p>
    <p>
      Crawling the Services is allowed if done in accordance with the provisions
      of our robots.txt file, but scraping the Services is prohibited.
    </p>
    <p>
      We may change, terminate, or restrict access to any aspect of the service,
      at any time, without notice.
    </p>
    <h2>No children</h2>
    <p>
      LitWitKit is only for people 13 years old and over. By using LitWitKit,
      you affirm that you are over 13. If we learn someone under 13 is using
      LitWitKit, we’ll terminate their account.
    </p>
    <h2>Security</h2>
    <p>
      If you find a security vulnerability on LitWitKit, tell us. We have a bug
      bounty disclosure program.
    </p>
    <h2>Incorporated rules and policies</h2>
    <p>
      By using the Services, you agree to let LitWitKit collect and use
      information as detailed in our Privacy Policy. If you’re outside the
      United States, you consent to letting LitWitKit transfer, store, and
      process your information (including your personal information and content)
      in and out of the United States.
    </p>
    <p>
      To enable a functioning community, we have Rules. Under our DMCA
      Policy, we’ll remove material after receiving a valid takedown notice.
      Under our Trademark Policy, we’ll investigate any use of another’s
      trademark and respond appropriately.
    </p>
    <p>
      To use the Services, you’ll have to create an account ("Account"). 
      You can do this via the Site or App. As part of the registration process, 
      we require you to take steps to confirm your identity, including ownership of 
      your email address. You authorize us to make any inquiries we consider necessary 
      to validate your identity. These inquiries may include asking you for further information, 
      requiring you to provide your full address or contact information, or verifying 
      information you provide against third party databases or through other sources. 
      If you do not provide this information or LitWitKit cannot verify your identity, 
      we may suspend or delete your account.
    </p>
    <p>
      By using LitWitKit, you agree to follow these Rules and Policies. If you
      don’t, we may remove content, or suspend or delete your account.
    </p>
    <h2>Miscellaneous</h2>
    <p>
      <em>Disclaimer of warranty.</em> LitWitKit provides the Services to you as
      is. You use them at your own risk and discretion. That means they don’t
      come with any warranty. None expressed, none implied. No implied warranty
      of merchantability, fitness for a particular purpose, availability,
      security, title or non-infringement.
    </p>
    <p>
      <em>Limitation of Liability.</em> LitWitKit won’t be liable to you for any
      damages that arise from your using the Services. This includes if the
      Services are hacked or unavailable. This includes all types of damages
      (indirect, incidental, consequential, special or exemplary). And it
      includes all kinds of legal claims, such as breach of contract, breach of
      warranty, tort, or any other loss.
    </p>
    <p>
      <em>No waiver.</em> If LitWitKit doesn’t exercise a particular right under
      these Terms, that doesn’t waive it.
    </p>
    <p>
      <em>Severability.</em> If any provision of these terms is found invalid by
      a court of competent jurisdiction, you agree that the court should try to
      give effect to the parties’ intentions as reflected in the provision and
      that other provisions of the Terms will remain in full effect.
    </p>
    <p>
      <em>Choice of law and jurisdiction.</em> These Terms are governed by New
      York law, without reference to its conflict of laws provisions. You agree
      that any suit arising from the Services must take place in a court located
      in New York, New York.
    </p>
  </section>
)
