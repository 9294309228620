import {
  Segment,
  SegmentPlacement,
  SegmentPlace,
  Outline,
  SegmentType,
} from '../../types'
import { Props } from './SegmentList'
import { updateOutline } from '../../resources/update'

export const dragDropHandlers = (
  setDraggingSegmentId: (segmentId: string) => void,
  setDragOverSegmentId: (segmentId: string) => void,
  draggingSegmentId: string,
  dragOverSegmentId: string,
  outline: Outline,
  setOutline: (outline: Outline) => void,
) => {
  const handleDragStart = (event: React.DragEvent) => {
    const fromSection = (event.target as HTMLElement).dataset
      .section as SegmentPlace
    const draggedOverSegmentId = (event.target as HTMLElement).id
    setDraggingSegmentId(draggedOverSegmentId)
  }

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault()
    const draggedOverId = (event.target as HTMLElement).dataset.segmentId
    const draggedOverSection = (event.target as HTMLElement).dataset
      .section as SegmentPlace
    if (draggedOverSection === 'unstaged') {
      return
    }

    setDragOverSegmentId(draggedOverId)
  }

  const handleDrop = (section: SegmentPlace) => (event: React.DragEvent) => {
    const draggedOverSection = (event.target as HTMLElement).dataset
      .section as SegmentPlace

    if (!dragOverSegmentId || draggingSegmentId === dragOverSegmentId) {
      return
    }
    const droppedOverSegmentId = (event.target as HTMLElement).dataset.segmentId
    if (section === 'staged') {
      applyDropToOutlineSection(draggingSegmentId, droppedOverSegmentId)
    }

    setDragOverSegmentId(null)
    setDraggingSegmentId(null)
  }

  const applyDropToOutlineSection = (
    draggingSegmentId: string,
    droppedOverSegmentId: string,
  ) => {
    let stagedSegmentIds = outline.stagedSegmentIds || []

    const updateState = (outline: Outline) => setOutline(outline)

    const filteredSegmentIds = stagedSegmentIds.filter(
      (sid) => sid !== draggingSegmentId,
    )

    if (droppedOverSegmentId === 'tailBufferSpace') {
      updateOutline(
        {
          ...outline,
          stagedSegmentIds: [...filteredSegmentIds, draggingSegmentId],
        },
        updateState,
      )
      return
    }

    const droppedIndex = filteredSegmentIds.findIndex(
      (id) => id === droppedOverSegmentId,
    )

    stagedSegmentIds = [
      ...filteredSegmentIds.slice(0, droppedIndex),
      draggingSegmentId,
      ...filteredSegmentIds.slice(droppedIndex),
    ]
    const newOutline = { ...outline, stagedSegmentIds }
    updateOutline(newOutline, updateState)
  }

  return { handleDragOver, handleDragStart, handleDrop }
}
