import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as snippets from '../StyleKit/styleSnippets'

const basePadding = 15

export const ListHeader = styled.section`
  label: ListHeader;
  border-bottom: 1px solid #ccc;
  padding: 0px ${basePadding}px;
  position: relative;
`

export const ListTitle = styled.h1`
  label: ListTitle;
  font-weight: normal;
  font-size: 20px;
`

export const ListDescription = styled.p`
  padding: 0px 20px 10px ${basePadding}px;
`

export const ContentNav = styled.section`
  label: ContentNav;
`

export const ContentTypeButton = styled.button`
  label: ContentTypeButton;
  ${(props: { active?: boolean }) =>
    props.active &&
    css`
      color: red;
    `}
`

export const ContentTypeIcon = styled(FontAwesomeIcon)`
  color: #c4c4c4;
  margin-right: 10px;
`

export const MenuCaretUpBorder = styled(FontAwesomeIcon)`
  label: MenuCaretUpBorder;
  position: absolute;
  right: 11px;
  margin-top: -22px;
  color: #ddd;
  font-size: 24px;
`

export const MenuCaretUpFill = styled(FontAwesomeIcon)`
  label: MenuCaretUpFill;
  position: absolute;
  right: 8px;
  margin-top: -18px;
  color: #eee;
  font-size: 31px;
`

export const ListHeadingMenuTrigger = styled.span`
  label: ListHeadingMenuTrigger;
  color: #c4c4c4;
  float: right;
  padding: 0 10px;
  margin-right: -10px;
  position: relative;
  z-index: 10;
  &:hover {
    color: #333;
    cursor: pointer;
  }
`

export const ListHeadingMenu = styled.section`
  label: ListHeadingMenu;
  background: #eee;
  border: 1px solid #ddd;
  border-radius: 3px;
  position: absolute;
  right: 0;
  width: 66%;
  margin-top: -7px;
  box-shadow: 5px 4px 2px rgba(0, 0, 0, 0.04);
`

export const ListHeadingMenuItem = styled.section`
  label: ListHeadingMenuItem;
  padding: 10px 15px;
  color: #777;
  &:hover {
    cursor: pointer;
    color: #333;
  }
`

export const ChecklistEditorSection = styled.div`
  ${snippets.fadeIn}
  background: #fafafa;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  width: 20vw;
  min-width: 275px;
  /* display: grid; */
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  grid-area: checklistEditor;
  z-index: 10;
`

export const ChecklistItem = styled.div`
  padding-left: ${basePadding}px;
`

export type ChecklistCheckboxProps = {
  checked: string
}

export const ChecklistCheckbox = styled.input`
  appearance: none;
  width: 14px;
  height: 14px;
  margin-top: 1px;
  z-index: 30;
  position: absolute;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: 1px solid hsl(0, 0%, 75%);
    outline-offset: 1px;
  }
`

export const ChecklistCheckboxVisible = styled(FontAwesomeIcon)`
  margin-right: 6px;
`

export const ChecklistText = styled.span``

export const listHeadingMouseMoveHandler = (
  setShowHeaderMenu: (showHeaderMenu: boolean) => void,
  setShowHeaderMenuTimeout: (showHeaderMenuTimeout: NodeJS.Timeout) => void,
  showHeaderMenuTimeout: NodeJS.Timeout,
) => (e: React.SyntheticEvent) => {
  const hardBoundMargin = 200
  const softBoundMargin = 5
  const softBoundTime = 1000

  const menuElement: HTMLElement = document.querySelector('#listHeadingMenu')
  if (!menuElement) {
    return
  }
  const width = menuElement.offsetWidth
  const height = menuElement.offsetHeight
  const leftOffset = menuElement.offsetLeft
  const topOffset = menuElement.offsetLeft

  const softBounds = {
    x: leftOffset + width + softBoundMargin,
    y: topOffset + height + softBoundMargin,
  }

  const hardBounds = {
    x: softBounds.x + hardBoundMargin,
    y: softBounds.y + hardBoundMargin,
  }

  const mouseEvent = e.nativeEvent as MouseEvent
  const mousePos = {
    x: mouseEvent.clientX,
    y: mouseEvent.clientY,
  }

  if (mousePos.x > hardBounds.x || mousePos.y > hardBounds.y) {
    setShowHeaderMenu(false)
    return
  }
  if (mousePos.x > softBounds.x || mousePos.y > softBounds.y) {
    if (!showHeaderMenuTimeout) {
      const timeout = setTimeout(() => {
        setShowHeaderMenu(false)
        setShowHeaderMenuTimeout(null)
      }, softBoundTime)
      setShowHeaderMenuTimeout(timeout)
    }
    return
  }
  // if we're neither in hard nor soft bounds, don't close
  // the menu on the user – clear any soft bound timeouts
  if (showHeaderMenuTimeout) {
    setShowHeaderMenuTimeout(null)
  }
}
