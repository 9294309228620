import { getType } from 'typesafe-actions'

import { TelegramReducerState } from './types'
import * as actions from './actions'

const initialState: TelegramReducerState = {
  signalQueue: [],
  log: [],
  transmissionStatus: 'idle',
}

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case getType(actions.setStatus):
      return { ...state, transmissionStatus: action.payload }

    case getType(actions.addSignalToQueue):
      return {
        ...state,
        signalQueue: [
          ...state.signalQueue.filter((s) => s.persistence !== 'bumpable'),
          action.payload,
        ],
      }

    case getType(actions.removeSignalFromQueue):
      return {
        ...state,
        signalQueue: state.signalQueue.filter(
          (s) => s.timestamp != action.payload.timestamp,
        ),
      }

    case getType(actions.clearQueue):
      return { ...state, signalQueue: [] }

    default:
      return state
  }
}
