import { getType } from 'typesafe-actions'

import { PremiseReducerState } from './premiseTypes'
import * as actions from './premiseActions'

const initialState: PremiseReducerState = {
  premiseNote: null,
  premiseChecklist: null,
}

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case getType(actions.resetPremise):
      return initialState
    case getType(actions.setPremiseNote):
      return { ...state, premiseNote: action.payload }

    case getType(actions.setPremiseChecklist):
      return { ...state, premiseChecklist: action.payload }

    default:
      return state
  }
}
