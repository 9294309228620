import { transformLwkResponse } from '../../../../../integrations/litwitkit/shared'
import { Setting } from '../../types'

declare var LWK_API_URL: 'string'

export type CreateSettingRequest = {
  category: string
  projectId: string
}

export const create = async (
  token: string,
  setting: CreateSettingRequest,
): Promise<Setting> => {
  const rawResponse = await fetch(`${LWK_API_URL}/settings/create`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    body: JSON.stringify(setting),
  })
  const { response } = await transformLwkResponse(rawResponse)
  return response as Setting
}
