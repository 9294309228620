import { faQuestionCircle, faTimes } from 'Assets/fontawesome-pro-light'
import * as React from 'react'
import {
  StatusBarActionsSection,
  ActionIcon,
  StatusBarButton,
  Shoot,
  Canopy,
  HelpBox,
  HelpMenu,
  HelpTabButton,
  HelpSection,
  HelpSections,
  HelpColumnTitle,
  HelpColumnContent,
  HelpColumn,
  HelpTabBacklight,
  HelpTabBacklightRightCaret,
  KeyboardShortcutAreaLabel,
  KeyboardShortcutLabel,
  KeyboardShortcutArea,
  KeyboardShortcut,
  KeyboardShortcutCommand,
  KeyboardShortcuts,
  KeyboardShortcutCommands,
  ContactForm,
  ContactFormDescription,
  ContactFormTextField,
  ContactFormSubmit,
} from './StatusBar.styles'
import Telegram from 'Constructs/secondary/Telegram/components/Telegram'
import { StatusBarSection } from './StatusBar.styles'
import { booleanTransformer, useLocalState } from 'Utilities/useLocalState'
import { faCaretRight } from 'Assets/fontawesome-pro-solid'
import { contactFormDescription, contactFormError, contactFormSuccess } from './StatusBar.text'
import {
  Help,
  HelpSectionName,
  HelpTab,
  OverviewColumn,
  Shortcut,
  ShortcutArea,
} from './StatusBar.types'
import { createConversation } from '../resources/create'

type Props = {
  help?: Help
}

export default ({ help }: Props) => {
  const [expanded, setExpanded] = useLocalState(
    'current-status-bar-expanded',
    false,
    booleanTransformer,
  )
  const [currentHelpSection, setCurrentHelpSection] = React.useState(
    'overview' as HelpSectionName,
  )
  const [feedbackSubmitted, setFeedbackSubmitted] = React.useState(
    null as 'success' | 'pending' | 'error',
  )
  const [feedback, setFeedback] = React.useState('')

  const helpTabs: HelpTab[] = [
    { label: `${help ? help.screenName : ''} Overview`, section: 'overview' },
  ]
  if (help && help.keys.length > 0) {
    helpTabs.push({
      label: 'Keyboard Shortcuts',
      section: 'keyboard-shortcuts',
    })
  }

  helpTabs.push({ label: 'Feedback/Support', section: 'contact-form' })

  const handleHelpClick = () => {
    setExpanded(!expanded)
    setFeedbackSubmitted(null)
    setFeedback('')
  }

  const handleTabClick = (section: HelpSectionName) => {
    setCurrentHelpSection(section)
  }

  const handleContactFormSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    setFeedbackSubmitted('pending')

    createConversation({
      content: feedback,
      title: 'Feedback Form Submission',
      conversationType: 'feedback'
    }, (success: boolean) => {
      if (success) {
        setFeedbackSubmitted('success')
      } else {
        setFeedbackSubmitted('error')
      }
    })

  }

  return (
    <StatusBarSection expanded={expanded}>
      <Canopy expanded={expanded} onClick={() => setExpanded(!expanded)} />
      <Shoot>
        <Telegram />
        <StatusBarActionsSection>
          <StatusBarButton onClick={handleHelpClick}>
            {help && !expanded && <ActionIcon icon={faQuestionCircle} />}
            {expanded && <ActionIcon icon={faTimes} />}
          </StatusBarButton>
        </StatusBarActionsSection>
      </Shoot>
      {help && (
        <HelpBox>
          <HelpMenu>
            <HelpTabBacklight
              activeIndex={helpTabs.findIndex(
                (ht) => ht.section === currentHelpSection,
              )}
            >
              <HelpTabBacklightRightCaret icon={faCaretRight} />
            </HelpTabBacklight>
            {helpTabs.map((tab) => (
              <HelpTabButton
                key={`HelpTabButton-${tab.section}`}
                section={tab.section}
                active={currentHelpSection === tab.section}
                onClick={() => handleTabClick(tab.section)}
              >
                {tab.label}
              </HelpTabButton>
            ))}
          </HelpMenu>
          <HelpSections>
            <HelpSection
              name='overview'
              active={currentHelpSection === 'overview'}
            >
              {help.overview.map((overviewColumn) => (
                <HelpColumn key={`HelpColumn-${overviewColumn.title.english}`}>
                  <HelpColumnTitle
                    text={overviewColumn.title}
                    isStyled={true}
                  ></HelpColumnTitle>
                  <HelpColumnContent
                    text={overviewColumn.content}
                    isStyled={true}
                  ></HelpColumnContent>
                </HelpColumn>
              ))}
            </HelpSection>
            <HelpSection
              name='keyboard-shortcuts'
              active={currentHelpSection === 'keyboard-shortcuts'}
            >
              {help.keys.map((area) => (
                <KeyboardShortcutArea key={`KeyboardShortcutArea-${area.area}`}>
                  <KeyboardShortcutAreaLabel
                    text={area.label}
                    isStyled={true}
                  />
                  <KeyboardShortcuts>
                    {area.shortcuts.map((shortcut) => (
                      <KeyboardShortcut
                        key={`KeyboardShortcut-${shortcut.label.english}`}
                      >
                        <KeyboardShortcutCommands>
                          {shortcut.commands.map((command, i) => (
                            <span key={`KeyboardShortcutCommand-${command}`}>
                              {i > 0 && '/'}{' '}
                              <KeyboardShortcutCommand>
                                {command}
                              </KeyboardShortcutCommand>
                            </span>
                          ))}
                        </KeyboardShortcutCommands>
                        <KeyboardShortcutLabel
                          text={shortcut.label}
                          isStyled={true}
                        />
                      </KeyboardShortcut>
                    ))}
                  </KeyboardShortcuts>
                </KeyboardShortcutArea>
              ))}
            </HelpSection>
            <HelpSection
              name='contact-form'
              active={
                currentHelpSection === 'contact-form' && !feedbackSubmitted
              }
            >
              <ContactForm onSubmit={handleContactFormSubmit}>
                <ContactFormDescription
                  text={contactFormDescription}
                  isStyled={true}
                />
                <ContactFormTextField
                  value={feedback}
                  onChange={(ev) => setFeedback(ev.target.value)}
                />
                <ContactFormSubmit>Send</ContactFormSubmit>
              </ContactForm>
            </HelpSection>
            <HelpSection
              name='contact-form-success'
              active={
                currentHelpSection === 'contact-form' &&
                feedbackSubmitted === 'success'
              }
            >
              <ContactForm>
                <ContactFormDescription
                  text={contactFormSuccess}
                  isStyled={true}
                />
              </ContactForm>
            </HelpSection>
            <HelpSection
              name='contact-form-error'
              active={
                currentHelpSection === 'contact-form' &&
                feedbackSubmitted === 'error'
              }
            >
              <ContactForm>
                <ContactFormDescription
                  text={contactFormError}
                  isStyled={true}
                />
              </ContactForm>
            </HelpSection>
          </HelpSections>
        </HelpBox>
      )}
    </StatusBarSection>
  )
}
