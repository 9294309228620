import * as React from 'react'
import { Brainstorm, BrainstormType } from '../types'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { BrainstormList } from './BrainstormsList/BrainstormList'

import { categories } from '../constants'
import { ProjectNav } from '../../../secondary/ProjectNav/components/ProjectNavComponent'
import { BrainstormSection } from './styles'
import { useParams } from 'react-router-dom'
// import { Language } from '../../../secondary/Copy/types'
import { BasicEditor } from '../../../secondary/BasicEditor'
import StatusBar from 'Constructs/secondary/StatusBar/components/StatusBar'
import * as text from './text'
import Copy from '../../../secondary/Copy/components/CopyComponent'
import { faGhost } from 'Assets/fontawesome-pro-light'
import { NoItems, NoItemsIcon } from '../../../secondary/StyleKit/styleSnippets'
import { InlineTextButton } from '../../../secondary/EditorGenerics/EditorGenerics'
import { listHeadingMouseMoveHandler } from 'Constructs/secondary/ListGenerics/ListGenerics'
import { getByProjectId } from '../resources/getByProjectId'
import { handlers } from './Brainstorms.handlers'

type Params = {
  projectId: string
  brainstormId: string
}

const Brainstorms = () => {
  const { projectId, brainstormId } = useParams<Params>()
  const history = useHistory()

  const projectTitle = localStorage.getItem(`project-${projectId}-title`)

  const [currentBrainstormId, setCurrentBrainstormId] = React.useState(
    brainstormId,
  )
  const [brainstorms, setBrainstorms] = React.useState([] as Brainstorm[])
  const [loaded, setLoaded] = React.useState(false)

  if (!currentBrainstormId && brainstorms.length > 0) {
    const firstActiveBrainstorm = brainstorms
      .sort((a, b) => (a.brainstormType === 'general' ? -1 : 1))
      .find((ch) => !ch.archived)
    if (firstActiveBrainstorm) {
      setCurrentBrainstormId(firstActiveBrainstorm.id)
    }
  }

  let brainstorm =
    currentBrainstormId && brainstorms
      ? brainstorms.find((c) => c.id == currentBrainstormId)
      : null

  React.useEffect(() => {
    setCurrentBrainstormId(brainstormId)
  }, [brainstormId])

  React.useEffect(() => {
    getByProjectId(
      projectId,
      (brainstorms) => {
        setBrainstorms(brainstorms)
        setLoaded(true)
      },
      (e) => history.push(`/app`),
    )
  }, [projectId])

  const [showHeaderMenu, setShowHeaderMenu] = React.useState(false)
  const [showHeaderMenuTimeout, setShowHeaderMenuTimeout] = React.useState(null)
  React.useEffect(() => {
    // clean up timeout
    return () => {
      if (showHeaderMenuTimeout) {
        clearTimeout(showHeaderMenuTimeout)
        setShowHeaderMenuTimeout(null)
      }
    }
  }, [showHeaderMenuTimeout])

  const {
    handleTrash,
    handleNew,
    handleSetCurrent,
    handleUpdate,
    handleDelete,
  } = handlers(
    brainstorm,
    brainstorms,
    setBrainstorms,
    setCurrentBrainstormId,
    projectId,
    history,
  )

  return (
    <BrainstormSection
      onMouseMove={listHeadingMouseMoveHandler(
        setShowHeaderMenu,
        setShowHeaderMenuTimeout,
        showHeaderMenuTimeout,
      )}
      onClick={(e) => {
        if (showHeaderMenu) {
          setShowHeaderMenu(false)
          setShowHeaderMenuTimeout(null)
        }
      }}
    >
      <ProjectNav
        currentInterface='Brainstorm'
        projectId={projectId}
        title={projectTitle}
      />
      {brainstorms && (
        <React.Fragment>
          <BrainstormList
            categories={categories}
            currentBrainstorm={brainstorm}
            handleNewBrainstorm={handleNew}
            brainstorms={brainstorms}
            setCurrentBrainstorm={handleSetCurrent}
            updateBrainstormAction={handleUpdate}
            updateBrainstormListAction={setBrainstorms}
            showHeaderMenu={showHeaderMenu}
            setShowHeaderMenu={setShowHeaderMenu}
          />
          {brainstorm && (
            <BasicEditor
              id={brainstorm.id}
              key={`brainstorm-editor-${brainstorm.id}`}
              initialContent={brainstorm.content}
              initialTitle={brainstorm.title}
              setContent={() => {}}
              setTitle={() => {}}
              readOnly={brainstorm && brainstorm.archived}
              header={
                brainstorm.archived && (
                  <span>
                    <Copy text={text.currentlyArchived} />{' '}
                    <InlineTextButton onClick={() => handleDelete(brainstorm)}>
                      <Copy text={text.deletePermanently} />
                    </InlineTextButton>
                  </span>
                )
              }
              update={(newTitle, newContent) =>
                handleUpdate({
                  ...brainstorm,
                  title: newTitle || brainstorm.title,
                  content: newContent || brainstorm.content,
                })
              }
              projectId={projectId}
              handleTrash={() => handleTrash(brainstorm)}
              language={'english'}
            />
          )}
          {!brainstorm && loaded && (
            <NoItems>
              <section>
                <NoItemsIcon icon={faGhost} />
                <p>
                  <Copy text={text.noItems} />
                </p>
              </section>
            </NoItems>
          )}
        </React.Fragment>
      )}
      <StatusBar help={text.help} />
    </BrainstormSection>
  )
}

export default Brainstorms
