import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Copy from 'Constructs/secondary/Copy/components/CopyComponent'

const statusBarHeight = 35

type StatusBarSectionProps = {
  expanded: boolean
}
export const StatusBarSection = styled.section`
  label: StatusBarSection;
  ${(props: StatusBarSectionProps) => css`
    height: ${props.expanded ? 300 : statusBarHeight}px;
  `}
  bottom: 0;
  background: #222;
  display: grid;
  grid-area: statusBar;
  position: fixed;
  transition: all 0.33s cubic-bezier(0.04, 0.54, 0.86, 0.34);
  width: 100%;
  z-index: 200;
`

export const Shoot = styled.section`
  label: Shoot;
  display: grid;
  background: #333;
  grid-area: statusBarShoot;
  grid-template-areas: 'telegramStatus globalProjectActions';
  grid-template-columns: 1fr 1fr;
  height: ${statusBarHeight}px;
  padding: 0px 15px;
  place-content: center left;
  position: fixed;
  width: 100%;
  z-index: 25;
`

export const Canopy = styled.section`
  label: Canopy;
  ${(props: StatusBarSectionProps) => css`
    opacity: ${props.expanded ? 1 : 0};
    width: ${props.expanded ? 100 : 0}vw;
    height: ${props.expanded ? 100 : 0}vh;
    transition: ${props.expanded
      ? `opacity 0.4s ease, width 0s, height 0s`
      : `opacity 0.4s ease, width 0s 0.4s, height 0s 0.4s`};
  `}
  background: hsla(0, 0%, 0%, 0.4);
  left: 0;
  position: fixed;
  top: 0;
  z-index: 15;
`

export const StatusBarButton = styled.button`
  color: hsla(0, 0%, 100%, 0.8);
  &:hover {
    color: hsla(0, 0%, 100%, 1);
  }
  transition: color 0.2s ease;
`

export const ActionIcon = styled(FontAwesomeIcon)``

export const StatusBarActionsSection = styled.section`
  grid-area: globalProjectActions;
  place-content: end;
  display: grid;
`

export const HelpBox = styled.section`
  label: Helpbox;
  margin-top: ${statusBarHeight}px;
  color: hsla(0, 0%, 100%, 0.66);
  position: relative;
  z-index: 20;
  display: grid;
  grid-template-columns: 250px 3fr;
`

export const HelpMenu = styled.section`
  label: HelpMenu;
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  background: #222;
  padding: 24px 0;
  position: relative;
`

export const HelpSections = styled.section`
  label: HelpSections;
  position: relative;
`

type HelpSectionProps = {
  name: string
  active: boolean
}
export const HelpSection = styled.section`
  ${(props: HelpSectionProps) => css`
    label: HelpSection;
    display: ${props.name === 'overview' ? 'grid' : 'inherit'};
    left: 0;
    grid-template-columns: ${props.name === 'overview'
      ? `repeat(3, 1fr)`
      : `none`};
    opacity: ${props.active ? 1 : 0};
    padding: 25px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: ${props.active ? 10 : 0};
  `}
`

type HelpTabProps = {
  section: string
  active: boolean
}
export const HelpTabButton = styled.button`
  ${(props: HelpTabProps) => css`
    label: HelpTab;
    border-radius: 3px;
    color: ${props.active ? 'hsla(0,0%,100%,0.75)' : 'hsla(0,0%,100%,0.5)'};
    padding: 0 20px;
    z-index: 10;
  `}
`

type HelpTabBacklightProps = {
  activeIndex: number
}
export const HelpTabBacklight = styled.div`
  label: HelpTabBacklight;
  ${(props: HelpTabBacklightProps) => css`
    position: absolute;
    background: hsla(0, 0%, 19%, 1);
    top: ${props.activeIndex * 44 + 24}px;
    width: 100%;
    height: 43px;
    z-index: 0;
    transition: top 0.08s ease-out;
  `}
`

export const HelpTabBacklightRightCaret = styled(FontAwesomeIcon)`
  label: HelpTabBacklightRightCaret;
  position: absolute;
  right: -7px;
  margin-top: 10px;
  color: hsla(0, 0%, 19%, 1);
  font-size: 24px;
`

export const HelpColumn = styled.section`
  label: HelpColumn;
  display: block;
  padding: 0 20px 0 40px;
  &:not(:last-child) {
    border-right: 1px solid hsla(0, 0%, 100%, 0.16);
  }
  height: 100%;
`

export const HelpColumnContent = styled(Copy)`
  label: HelpColumnContent;
  display: block;
  font-size: 16px;
  line-height: 22px;
`

export const HelpColumnTitle = styled(Copy)`
  label: HelpColumnTitle;
  color: hsla(0, 0%, 100%, 0.88);
  display: block;
  font-size: 22px;
  font-weight: bold;
  padding: 0 0 20px 0;
`

export const KeyboardShortcutArea = styled.section`
  label: KeyboardShortcutArea;
`

export const KeyboardShortcutAreaLabel = styled(Copy)`
  label: KeyboardShortcutAreaLabel;
  border-bottom: 1px solid #333;
  display: block;
  font-size: 17px;
  font-style: italic;
  margin: 0 0 15px;
`

export const KeyboardShortcuts = styled.section`
  label: KeyboardShortcuts;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`

export const KeyboardShortcut = styled.div`
  label: KeyboardShortcut;
  margin-bottom: 15px;
`

export const KeyboardShortcutCommands = styled.div`
  label: KeyboardShortcutCommands;
  display: inline-block;
`

export const KeyboardShortcutCommand = styled.span`
  label: KeyboardShortcutCommand;
  display: inline-block;
  margin: 0 8px 0 6px;
  border: 1px solid #444;
  border-radius: 4px;
  letter-spacing: 1px;
  padding: 1px 7px;
  box-shadow: 0 0 7px inset hsla(0, 0%, 100%, 0.05);
  font-size: 15px;
`

export const KeyboardShortcutLabel = styled(Copy)`
  label: KeyboardShortcutLabel;
  color: hsla(0, 0%, 100%, 0.85);
  margin-left: 5px;
`

// Contact Form

export const ContactForm = styled.form`
  label: ContactForm;
  display: grid;
  grid-template-rows: 0.8fr 2fr 1fr;
  height: 100%;
  width: 63%;
  padding: 10px;
`

export const ContactFormDescription = styled(Copy)`
  label: ContactFormDescription;
  color: hsla(0, 0%, 100%, 0.85);
`

export const ContactFormTextField = styled.textarea`
  label: ContactFormTextField;
  border: 1px solid transparent;
  border-radius: 3px;
  background: hsla(0, 0%, 100%, 0.85);
  margin: 3px;
  &:focus {
    outline: none;
    outline: 1px dashed hsla(170, 50%, 50%, 0.3);
    outline-offset: 3px;
  }
`

export const ContactFormSubmit = styled.button`
  label: ContactFormSubmit;
  background: hsla(170, 0%, 100%, 0.1);
  border-radius: 4px;
  border: 1px solid hsla(0, 0%, 100%, 0.06);
  color: hsla(0, 0%, 100%, 0.7);
  display: block;
  font-size: 20px;
  height: 64%;
  letter-spacing: 1.5px;
  margin: 3px;
  padding: 0 30px;
  place-self: center left;
  text-align: center;
  &:hover {
    color: hsla(0, 0%, 100%, 0.8);
    background: hsla(170, 0%, 100%, 0.2);
  }
  &:focus {
    outline: none;
    outline: 1px dashed hsla(170, 50%, 50%, 0.3);
    outline-offset: 3px;
  }
`
