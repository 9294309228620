import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditableTitle from '../../../../secondary/EditableTitle'
import * as snippets from '../../../../secondary/StyleKit/styleSnippets'

import { css } from '@emotion/react'

const leftGutter = 40
const topMargin = 20

type displaystyleProps = {
  displaystyle: string
}

export const SegmentEditorSection = styled.section`
  label: SegmentEditorSection;
  ${snippets.fadeIn}
  ${(props: displaystyleProps) =>
    props.displaystyle === 'mini'
      ? css`
          margin: 3em 7em 0 4em;
          padding: 1em 0;
          border: 1px solid hsl(0, 0%, 90%);
          border-radius: 4px;
        `
      : css``}
`

export const BreadcrumbLink = styled(Link)`
  label: BreadcrumbLink;
  padding-left: ${leftGutter}px;
  margin: ${topMargin}px 0;
  display: block;
  text-decoration: none;
  color: #aaa;
`

export const BreadcrumbIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`

export const BreadcrumbText = styled.span``

export const Title = styled(EditableTitle)`
  label: Title;
  margin-left: ${leftGutter}px;
  font-weight: bold;
  ${(props: displaystyleProps) =>
    props.displaystyle === 'mini'
      ? css`
          font-size: 27px;
          color: #555;
        `
      : css`
          font-size: 36px;
        `}
`
