import { Setting } from '../types'
import * as H from 'history'
import { updateById } from '../resources/updateById'
import { create } from '../resources/create'
import { deleteById } from '../resources/deleteById'

export const handlers = (
  setting: Setting,
  settings: Setting[],
  setSettings: (settings: Setting[]) => void,
  setCurrentSettingId: (settingId: string) => void,
  projectId: string,
  history: H.History<H.LocationState>,
) => {
  const handleTrash = async () => {
    const updated = { ...setting, archived: true }

    await setCurrentSettingId(null)
    await updateById(updated, projectId)
    await setSettings(
      settings.map((st) => (setting.id === st.id ? updated : st)),
    )

    history.push(`/app/project/${projectId}/settings`)
  }

  const handleNew = (category: string) => {
    const updateState = (st: Setting) => {
      setSettings([...settings, st])
      setCurrentSettingId(st.id)
      history.push(`/app/project/${projectId}/settings/${st.id}`)
    }
    create({ category, projectId }, updateState)
  }

  const handleSetCurrent = (setting: Setting) => {
    if (setting) {
      setCurrentSettingId(setting.id)
      history.push(`/app/project/${projectId}/settings/${setting.id}`)
    } else {
      setCurrentSettingId(null)
    }
  }

  const handleUpdate = async (setting: Setting) => {
    await updateById(setting, projectId)

    setSettings(
      settings.map((st) => (setting.id === st.id ? setting : st)),
    )
    setCurrentSettingId(setting.id)
  }

  const handleDelete = () => {
    deleteById(setting)
    setSettings(settings.filter((st) => st.id !== setting.id))
    const firstSetting = settings.sort((a, b) =>
      a.category === '' ? -1 : 1,
    )[0]
    setCurrentSettingId(firstSetting ? firstSetting.id : null)
  }

  return {
    handleTrash,
    handleNew,
    handleSetCurrent,
    handleUpdate,
    handleDelete,
  }
}
