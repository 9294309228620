import { transformLwkResponse } from '../../../../../integrations/litwitkit/shared'

declare var LWK_API_URL: 'string'

export const confirmEmail = async (
  token: string,
  userId: string,
  handleSuccess: () => void,
  handleError: (e: any) => void,
): Promise<void> => {
  const rawResponse = await fetch(
    `${LWK_API_URL}/users/id/${userId}/confirm-email`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Token: token,
      },
    },
  )
  try {
    const { response } = await transformLwkResponse(rawResponse)
    handleSuccess()
  } catch (e) {
    handleError(e)
  }
}
