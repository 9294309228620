import { createAction } from 'typesafe-actions'
import { Dispatch, Action, ActionCreator } from 'redux'
import * as telegram from '../../secondary/Telegram/actions'
import * as copy from './text'
import { Language } from '../../secondary/Copy/types'
import {
  LoginCredentials,
  ResetPasswordParams,
  SendResetPasswordEmailParams,
} from './types'
import {
  resetPasswordRequest,
  sendPasswordResetEmailRequest,
} from '../../../integrations/litwitkit/users'

declare var LWK_API_URL: 'string'

export const setToken = createAction(
  'SET_TOKEN',
  (resolve) => (token: string) => {
    return resolve(token)
  },
)

export const resetToken = createAction('RESET_TOKEN')

export const setUserUnconfirmed = createAction('SET_USER_UNCONFIRMED')

export const clearTelegramQueue = telegram.clearQueue

export const login = ({ email, password }: LoginCredentials) => (
  dispatch: Dispatch<any>,
  getState: Function,
) => {
  dispatch(telegram.signal({ type: 'request', blocking: true }))
  return fetch(`${LWK_API_URL}/identity/token`, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (res.headers.has('Token')) {
        dispatch(setToken(res.headers.get('Token')))
        localStorage.setItem('current-user-email', email)
      }

      if (res.ok) {
        dispatch(telegram.signal({ type: 'success', copy: copy.login.success }))
      } else {
        return res.json().then((json) => {
          const errorCopy = copy.errors[json.errorType]
          throw {
            copy: errorCopy,
            message: json.message,
            errorType: json.errorType,
          }
        })
      }
    })
    .catch((e) => {
      if (e.errorType === 'PLEASE_CONFIRM_ACCOUNT') {
        dispatch(telegram.signal({ type: 'success', copy: copy.login.success }))
        dispatch(setUserUnconfirmed())
        return
      }

      dispatch(
        telegram.signal({
          type: 'error',
          text: e.message,
          copy:
            e.copy ||
            copy.errorMessageToCopyMap[e.message] ||
            copy.errors['UNKNOWN'],
          momentary: false,
        }),
      )
      dispatch(resetToken())
    })
}

export const refreshToken = (token: string) => (dispatch: any) => {
  dispatch(telegram.signal({ type: 'request', text: '' }), 'request')
  return fetch(`${LWK_API_URL}/identity/token/refresh`, {
    method: 'POST',
    body: JSON.stringify({ token }),
    headers: {
      'Content-Type': 'application/json',
      Token: token,
    },
  })
    .then((res) => {
      if (res.headers.has('Token')) {
        dispatch(setToken(res.headers.get('Token')))
      }

      if (res.ok) {
        dispatch(telegram.signal({ type: 'success', copy: copy.login.success }))
        dispatch(setToken(res.headers.get('Token')))
      } else {
        return res.json().then((json) => {
          const errorCopy = copy.errors[json.errorType]
          throw {
            copy: errorCopy,
            message: json.message,
            errorType: json.errorType,
          }
        })
      }
    })
    .catch((e) => {
      console.log('failed refreshing token. error:', e)

      if (e.errorType === 'PLEASE_CONFIRM_ACCOUNT') {
        dispatch(telegram.signal({ type: 'success', copy: copy.login.success }))
        dispatch(setUserUnconfirmed())
        return
      }

      dispatch(
        telegram.signal({
          type: 'error',
          text: e.message,
          copy:
            e.copy ||
            copy.errorMessageToCopyMap[e.message] ||
            copy.errors['UNKNOWN'],
          momentary: false,
        }),
      )

      dispatch(resetToken())
    })
}

type ServerError = {
  name: string
}
type ServerErrorResponse = {
  error: ServerError
}

export const signup = ({ email, password }: LoginCredentials) => (
  dispatch: any,
) => {
  dispatch(
    telegram.signal({ type: 'request', text: '', blocking: true }),
    'request',
  )
  return fetch(`${LWK_API_URL}/users/create`, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (res.headers.has('Token')) {
        dispatch(setToken(res.headers.get('Token')))
        dispatch(setUserUnconfirmed())
        localStorage.setItem('current-user-email', email)
      }

      if (res.ok) {
        dispatch(telegram.signal({ type: 'success', copy: copy.login.success }))
      } else {
        return res.json().then((json) => {
          const errorCopy = copy.errors[json.errorType]
          throw { copy: errorCopy, message: json.message }
        })
      }
    })
    .catch((e) => {
      dispatch(
        telegram.signal({
          type: 'error',
          text: e.message,
          copy:
            e.copy ||
            copy.errorMessageToCopyMap[e.message] ||
            copy.errors['UNKNOWN'],
          momentary: false,
        }),
      )
    })
}

export const resetPassword = ({
  userId,
  password,
  token,
}: ResetPasswordParams): ((dispatch: any) => Promise<boolean>) => (
  dispatch,
) => {
  dispatch(
    telegram.signal({ type: 'request', text: '', blocking: true }),
    'request',
  )
  return resetPasswordRequest({ userId, password, token })
    .then((rawResponse) => {
      if (!rawResponse.ok) {
        return rawResponse
          .clone()
          .json()
          .then((json) => {
            const errorCopy = copy.errors[json.errorType]
            throw { copy: errorCopy }
          })
      }
      return rawResponse.json()
    })
    .then((res) => {
      dispatch(
        telegram.signal({
          type: 'success',
          copy: copy.resetPassword.success,
          momentary: false,
        }),
      )
      return Promise.resolve(true)
    })
    .catch((e) => {
      dispatch(
        telegram.signal({
          type: 'error',
          text: e.message,
          copy:
            e.copy ||
            copy.errorMessageToCopyMap[e.message] ||
            copy.errors['UNKNOWN'],
          momentary: false,
        }),
      )
      return Promise.reject(false)
    })
}

export const sendResetPasswordEmail = ({
  email,
}: SendResetPasswordEmailParams): ((dispatch: any) => Promise<boolean>) => (
  dispatch,
) => {
  dispatch(
    telegram.signal({ type: 'request', text: '', blocking: true }),
    'request',
  )
  return sendPasswordResetEmailRequest({ email })
    .then((rawResponse) => {
      if (!rawResponse.ok) {
        return rawResponse
          .clone()
          .json()
          .then((json) => {
            const errorCopy = copy.errors[json.errorType]
            throw { copy: errorCopy }
          })
      }
      dispatch(telegram.signal({ type: 'success' }))
      return Promise.resolve(true)
    })
    .catch((e) => {
      dispatch(
        telegram.signal({
          type: 'error',
          text: e.message,
          copy:
            e.copy ||
            copy.errorMessageToCopyMap[e.message] ||
            copy.errors['UNKNOWN'],
          momentary: false,
        }),
      )
      return Promise.reject(false)
    })
}
