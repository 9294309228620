import * as React from 'react'
import { faSpinner } from 'Assets/fontawesome-pro-regular'
import * as s from '../telegramStyles'

import { useLocalState } from 'Utilities/useLocalState'

const MasterSpinner = () => {
  const [transmissionStatus] = useLocalState(
    'telegram-transmission-status',
    'idle',
  )

  return (
    <React.Fragment>
      {transmissionStatus === 'waitingForeground' && (
        <s.MasterSpinnerSection>
          <s.SpinnerIcon icon={faSpinner} pulse />
        </s.MasterSpinnerSection>
      )}
    </React.Fragment>
  )
}

export default MasterSpinner
