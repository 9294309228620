import * as requests from './requests/deleteById'
import { Character } from '../types'
import { deleteResource } from '../../../../integrations/litwitkit/shared'

export const deleteById = async (character: Character): Promise<void> => {
  const storageKey = `character-${character.id}`

  const request = (token: string) => {
    return requests.deleteById(token, character)
  }

  const transformData = (rawCharacter: any) => {
    return rawCharacter
  }

  const [status, resource] = await deleteResource(storageKey, request)
}
