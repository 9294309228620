import { keyboardShortcutLegend } from 'Constructs/secondary/Editor/Editor.constants'
import * as React from 'react'

export const currentlyArchived = {
  english: 'This brainstorm is currently archived.',
}

export const deletePermanently = {
  english: 'Delete permanently.',
}

export const noItems = {
  english: `You don't have any brainstorms yet. Create your first one!`,
}

export const help = {
  screenName: 'Brainstorms',
  overview: [
    {
      content: {
        english: `This is the Brainstorming tool for your project, a place 
      for you to get down anything and everything that might help shape your 
      vision of your project. Use the fixed set of categories as a guide 
      when you’re not sure what to add, but don't feel constrained by them.`,
      },
      title: { english: 'Your Idea Engine' },
    },
    {
      content: {
        english: `You can convert brainstorms into characters, scenes, and locations, 
        so think of brainstorming as planting seeds that you can reap in future 
        stages of your creative process.`,
      },
      title: { english: 'Reap What You Sow' },
    },
    {
      content: {
        english: `You can archive your brainstorms or delete them permanently, so don't 
        feel like you're writing gospel. It's okay to create a bit of a mess here, and it's 
        good to experiment. This is a place to blur the lines between thinking 
        and typing. Don't do one, and then the other. Do both simultaneously. 
        Think with your fingertips.`,
      },
      title: { english: 'Tips' },
    },
  ],
  keys: [keyboardShortcutLegend],
}
