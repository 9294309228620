import * as React from 'react'
import { get } from 'lodash'
import { Setting, SettingLabel, SettingTextInput } from '../stagesStyles'
import { updateProjectAction } from '../stagesActions'
import * as selectors from '../stagesSelectors'
import { connect } from 'react-redux'
import { Project } from '../../../../integrations/litwitkit/entities'

type Props = {
  field: string
  label: string
  placeholder: string
  project: Project
  updateProjectAction: (project: Project) => void
}

const TextFieldSetting = ({
  field,
  label,
  placeholder,
  project,
  updateProjectAction,
}: Props) => {
  const [val, setVal] = React.useState(get(project, field) || '')
  const handleChange = (newVal: string) => {
    setVal(newVal)
    updateProjectAction({
      ...project,
      ...{ [field]: newVal },
    })
  }

  return (
    <Setting key={field}>
      <SettingLabel>{label}</SettingLabel>
      <SettingTextInput
        type='text'
        placeholder={placeholder}
        value={val}
        onChange={(e) => handleChange(e.target.value)}
      ></SettingTextInput>
    </Setting>
  )
}

const mapStateToProps = (state: any) => {
  return {
    project: selectors.project(state),
  }
}

const mapDispatchToProps = { updateProjectAction }

export default connect(mapStateToProps, mapDispatchToProps)(TextFieldSetting)
