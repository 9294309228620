import { keyboardShortcutLegend } from 'Constructs/secondary/Editor/Editor.constants'

export const help = {
  screenName: 'Synopsis',
  overview: [
    {
      title: { english: 'Just a Paragraph or Two' },
      content: {
        english: `This is the Synopsis Tool of your project, designed to help you to 
        summarize your idea into a few paragraphs. It describes what happens 
        in your story in broad strokes.`,
      },
    },
    {
      title: { english: 'The Definition of Done' },
      content: {
        english: `Use the highlighters to help you determine how strong your synopsis is. 
        Highlight the parts of your synopsis that reflect key story elements. Craft your 
        synopsis until it most or all of it can be fairly highlighted.`,
      },
    },
    {
      title: { english: 'Save the Structure for Later' },
      content: {
        english: `A synopsis describes what happens in your story, but it doesn’t describe 
        how the story is told. Try to include the elements of your story, but don’t worry 
        about the order of them. Save the organization and structure for the plot 
        mapping stage.`,
      },
    },
  ],
  keys: [keyboardShortcutLegend],
}

export const checklistEditor = {
  title: {
    english: `Synopsis Checklist`,
  },
  description: {
    english: `The synopsis summarizes the story in several sentences, and includes some of these story elements:`,
  },
}
