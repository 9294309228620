import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { resetToken } from '../actions'

const Logout = ({ resetToken }: any) => {
  resetToken()
  return <Redirect to='/' />
}

export default connect(() => {}, { resetToken })(Logout)
