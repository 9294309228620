import * as React from 'react'
import Copy from '../Copy/components/CopyComponent'
import {
  ChecklistEditorSection,
  ChecklistItem,
  ChecklistCheckbox,
  ChecklistCheckboxVisible,
  ChecklistText,
  ListDescription,
  ListHeader,
  ListTitle,
} from '../ListGenerics/ListGenerics'

import { faCheckSquare } from 'Assets/fontawesome-pro-solid'
import { faSquare } from 'Assets/fontawesome-pro-regular'

import { CopyText } from '../Copy/types'

type ChecklistItem = {
  [key: string]: boolean
}

type Checklist = {
  items?: ChecklistItem[]
}

export type Props = {
  checklist: Checklist
  title?: CopyText
  description?: CopyText
  updateChecklist: (checklist: Checklist) => void
}

export const ChecklistEditor = ({
  checklist,
  updateChecklist,
  title,
  description,
}: Props) => {
  const updateChecklistItem = (index: number, checked: boolean) => {
    const newItem = { ...checklist.items[index], checked }
    let newItems = []
    if (checklist.items) {
      newItems = checklist.items.map((item: any, mapIndex: number) =>
        index === mapIndex ? newItem : item,
      )
    }
    updateChecklist({ ...checklist, items: newItems })
  }

  return !checklist ? null : (
    <ChecklistEditorSection>
      {title && (
        <ListHeader>
          <ListTitle>
            <Copy text={title} />
          </ListTitle>
        </ListHeader>
      )}
      {description && (
        <ListDescription>
          <Copy text={description} />
        </ListDescription>
      )}
      {checklist.items &&
        checklist.items.map((checklistItem, i) => (
          <ChecklistItem key={i}>
            <ChecklistCheckbox
              type='checkbox'
              key={i}
              disabled={checklistItem.disabled}
              checked={checklistItem.checked}
              onChange={(ev) => updateChecklistItem(i, ev.target.checked)}
            />
            <ChecklistCheckboxVisible
              icon={checklistItem.checked ? faCheckSquare : faSquare}
            />
            <ChecklistText>{checklistItem.name}</ChecklistText>
          </ChecklistItem>
        ))}
    </ChecklistEditorSection>
  )
}
