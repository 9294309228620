import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as snippets from 'Constructs/secondary/StyleKit/styleSnippets'

export const fadeIn = css`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: 0.37s ease fadeIn;
`

export const delayedFadeIn = css`
  @keyframes delayedFadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: 0.59s ease delayedFadeIn;
`

export const navBar = css`
  background: #343434;
  box-sizing: border-box;
  height: 48px;
  padding: 0;
  place-items: center;
  z-index: 100;
  grid-area: projectNav;
`

export const NoItems = styled.section`
  ${snippets.fadeIn}
  label: NoItems;
  display: grid;
  place-items: center;
  height: 100%;
  grid-area: editor;
  width: 80vw;
  p {
    color: hsl(0, 0%, 70%);
    font-size: 24px;
    font-style: italic;
  }
  > section {
    display: grid;
    place-items: center;
  }
`

export const NoItemsIcon = styled(FontAwesomeIcon)`
  font-size: 20vh;
  color: hsl(0, 0%, 93%);
`
