import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faEllipsisVAlt } from 'Assets/fontawesome-pro-light'
import {
  CategorySection,
  CategoryTitle,
  CategoryTitleIcon,
  CategoryTitleText,
  SettingListSection,
  SettingSection,
  NewSettingButton,
  SelectSettingButton,
} from './styles'
import { Setting, Category } from '../../types'
import {
  ListHeader,
  ListTitle,
  ListHeadingMenuTrigger,
  ListDescription,
} from 'Constructs/secondary/ListGenerics/ListGenerics'
import Copy from 'Constructs/secondary/Copy/components/CopyComponent'
import { HeaderMenu } from './HeaderMenu'
import * as text from './text'

export type SelectSettingButtonProps = {
  archived: boolean
  currentsetting: string
  isBeingDraggedOver: boolean
}

type SettingListProps = {
  categories?: Category[]
  settings: Setting[]
  currentSetting: Setting
  handleNewSetting: (category: string) => void
  setCurrentSetting: (setting: Setting) => void
  setShowHeaderMenu: (showHeaderMenu: boolean) => void
  showHeaderMenu: boolean
  updateSettingAction: (setting: Setting) => void
  updateSettingListAction: (settings: Setting[]) => void
}

export const SettingList = ({
  categories,
  settings,
  currentSetting,
  handleNewSetting,
  setCurrentSetting,
  setShowHeaderMenu,
  showHeaderMenu,
  updateSettingAction,
  updateSettingListAction,
}: SettingListProps) => {
  const [dragOverSettingId, setDragOverSettingId] = React.useState(null)
  const [draggingSettingId, setDraggingSettingId] = React.useState(null)
  const [showArchived, setShowArchived] = React.useState(false)

  // Apply filters to the list, e.g. whether or not we're showing
  // archived items
  const visibleSettings = settings.filter((setting) => {
    if (showArchived) {
      return true
    } else {
      return !setting.archived
    }
  })

  const handleSettingClick = (event: React.MouseEvent) => {
    const currentSettingId = (event.target as HTMLElement).dataset.settingId
    const currentSetting = settings.find((n) => n.id === currentSettingId)
    setCurrentSetting(currentSetting)
  }

  const getSettingFromId = (id: string) =>
    settings.find((setting) => setting.id === id)

  const handleDragStart = (event: React.DragEvent) => {
    setDraggingSettingId((event.target as HTMLElement).id)
  }

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault()
    const draggedOverId = (event.target as HTMLElement).dataset.settingId
    setDragOverSettingId(draggedOverId)
  }

  const handleDrop = (event: React.DragEvent, newRole: string) => {
    setDragOverSettingId(null)
    setDraggingSettingId(null)
    if (!dragOverSettingId || draggingSettingId === dragOverSettingId) {
      return
    }
    const droppedOverSettingId = (event.target as HTMLElement).dataset
      .settingId
    const draggingSetting = getSettingFromId(draggingSettingId)
    updateSettingAfterDrop(draggingSetting, newRole)
    sortSettingsAfterDrop(draggingSetting, droppedOverSettingId)
  }

  const updateSettingAfterDrop = (
    draggingSetting: Setting,
    newRole: string,
  ) => {
    const updatedSetting = { ...draggingSetting, category: newRole }
    updateSettingAction(updatedSetting)
  }

  const sortSettingsAfterDrop = (
    draggingSetting: Setting,
    droppedOverSettingId: string,
  ) => {
    const filteredSettings = visibleSettings.filter(
      (n) => n.id !== draggingSettingId,
    )
    const droppedSettingIndex =
      droppedOverSettingId === 'tailBufferSpace'
        ? filteredSettings.length
        : Math.max(
            0,
            filteredSettings.findIndex(
              (n) => n.id === droppedOverSettingId,
            ),
          )
    const newSettings = [
      ...filteredSettings.slice(0, droppedSettingIndex),
      draggingSetting,
      ...filteredSettings.slice(
        droppedSettingIndex,
        filteredSettings.length,
      ),
    ]
    updateSettingListAction(newSettings)
  }

  const renderCategory = (
    category: Category,
    visibleSettings: Setting[],
  ) => (
    <CategorySection
      key={`category-${category.role}`}
      onDragOver={handleDragOver}
      onDrop={(ev) => handleDrop(ev, category.role)}
    >
      {/* <CategoryTitle>
        <CategoryTitleIcon icon={category.icon} />
        <CategoryTitleText
          data-setting-id='headBufferSpace'
          data-category={category.role}
        >
          {category.title}
        </CategoryTitleText>
      </CategoryTitle> */}
      {visibleSettings.map((setting) => (
        <SettingSection
          key={setting.id}
          id={setting.id}
          draggable
          onDragStart={handleDragStart}
        >
          <SelectSettingButton
            archived={setting.archived}
            currentsetting={
              currentSetting && currentSetting.id === setting.id
                ? 'true'
                : 'false'
            }
            data-setting-id={setting.id}
            data-category={category.role}
            onClick={handleSettingClick}
            isBeingDraggedOver={
              setting.id === dragOverSettingId &&
              setting.id !== draggingSettingId
            }
          >
            {setting.title || 'Unnamed Location'}
          </SelectSettingButton>
        </SettingSection>
      ))}
      <NewSettingButton
        data-setting-id='tailBufferSpace'
        data-category={category.role}
        onClick={() => handleNewSetting(category.role)}
      >
        <FontAwesomeIcon icon={faPlusCircle} /> Add setting
      </NewSettingButton>
    </CategorySection>
  )

  const handleShowArchived = () => {
    setShowArchived(!showArchived)
    if (currentSetting && currentSetting.archived) {
      const firstUnarchivedSetting = settings
        .sort((a, b) => (a.category === '' ? -1 : 1))
        .find((ch) => !ch.archived && ch.category === '')
      setCurrentSetting(firstUnarchivedSetting)
    }
  }

  const renderCategories = (categories: Category[], settings: Setting[]) =>
    categories.map((category) => {
      const categorySettings = settings.filter(
        (n) => n.category === category.role,
      )
      return renderCategory(category, categorySettings)
    })

  return (
    <SettingListSection>
      <ListHeader>
        <ListTitle>
          <Copy text={text.title} />
          <ListHeadingMenuTrigger
            onClick={() => setShowHeaderMenu(!showHeaderMenu)}
          >
            <FontAwesomeIcon icon={faEllipsisVAlt} />
          </ListHeadingMenuTrigger>
        </ListTitle>
        <HeaderMenu
          handleShowArchived={handleShowArchived}
          showArchived={showArchived}
          visible={showHeaderMenu}
        />
      </ListHeader>
      <ListDescription>
        <Copy text={text.description} />
      </ListDescription>
      {renderCategories(categories, visibleSettings)}
    </SettingListSection>
  )
}
