import { transformLwkResponse } from '../../../../../integrations/litwitkit/shared'
import { Character } from '../../types'

declare var LWK_API_URL: 'string'

export type CreateCharacterRequest = {
  primaryRole: string
  projectId: string
}

export const create = async (
  token: string,
  character: CreateCharacterRequest,
): Promise<Character> => {
  const rawResponse = await fetch(`${LWK_API_URL}/characters/create`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    body: JSON.stringify(character),
  })
  const { response } = await transformLwkResponse(rawResponse)
  return response as Character
}
