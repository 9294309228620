import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import Copy from '../../../secondary/Copy/components/CopyComponent'
import * as text from '../text'
import * as telegram from '../../../secondary/Telegram/actions'
import { SignupComponentProps, SignupComponentState } from '../types'
import { signup, clearTelegramQueue } from '../actions'
import * as selectors from '../selectors'
import {
  FormScreen,
  FormContainerSection,
  FormTitle,
  Form,
  FormInput,
  FormSubmit,
  FormLink,
  BreadcrumbIcon,
  BreadcrumbText,
  FormCheckbox,
  FormLabel,
  TelegramStation,
  FormCenterIcon,
} from './styles'
import { faArrowLeft } from 'Assets/fontawesome-pro-light'
import { faPaperPlane } from 'Assets/fontawesome-pro-solid'
import validator from 'validator'

class Signup extends React.Component<
  SignupComponentProps,
  SignupComponentState
> {
  state = {
    email: '',
    password: '',
    passwordConfirmation: '',
    iagree: false,
    validationMet: false,
  }

  componentWillUnmount = () => {
    this.props.clearTelegramQueue()
  }

  validate = () => {
    if (
      !validator.isEmail(this.state.email) ||
      this.state.iagree !== true ||
      this.state.password.length < 8 ||
      this.state.password !== this.state.passwordConfirmation
    ) {
      return this.setState({ validationMet: false })
    }
    return this.setState({ validationMet: true })
  }

  handleEmailInput = async (e: any) => {
    await this.setState({ email: e.target.value })
    this.validate()
  }

  handlePasswordInput = async (e: any) => {
    await this.setState({ password: e.target.value })
    this.validate()
  }

  handleIAgree = async (e: any) => {
    await this.setState({ iagree: !this.state.iagree })
    this.validate()
  }

  handlePasswordConfirmationInput = async (e: any) => {
    const passwordConfirmation = e.target.value
    await this.setState({ passwordConfirmation })
    if (
      passwordConfirmation.length >= this.state.password.length &&
      passwordConfirmation !== this.state.password
    ) {
      this.props.telegramSignal({
        type: 'info',
        text: '',
        copy: text.signup.passwordConfirmationMismatch,
        blocking: false,
        persistence: 'bumpable',
        momentary: false,
      })
    } else {
      this.props.clearTelegramQueue()
    }
    this.validate()
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    if (!this.state.validationMet) {
      return
    }

    const { email, password } = this.state
    this.props.signup({ email, password })
  }

  render = () =>
    this.props.isLoggedIn ? (
      <Redirect push to='/app/projects' />
    ) : (
      <FormScreen>
        <FormContainerSection>
          <BreadcrumbText>
            <BreadcrumbIcon icon={faArrowLeft} />
            <FormLink to={`/`}>Return to the intro screen</FormLink>, or{' '}
            <FormLink to={`/login`}>log in</FormLink> instead
          </BreadcrumbText>
          <FormTitle>
            <Copy text={text.signup.heading} />
          </FormTitle>
          <FormCenterIcon icon={faPaperPlane} />
          <Form>
            <FormInput
              type='email'
              value={this.state.email}
              onChange={this.handleEmailInput}
              placeholder='Enter your email address'
              autoComplete='none'
            />
            <FormInput
              type='password'
              value={this.state.password}
              onChange={this.handlePasswordInput}
              placeholder='Enter your password (8 character minimum)'
              autoComplete='none'
            />
            <FormInput
              type='password'
              value={this.state.passwordConfirmation}
              onChange={this.handlePasswordConfirmationInput}
              placeholder='Please confirm your password'
              autoComplete='none'
            />
            <FormCheckbox
              type='checkbox'
              name='iagree'
              onChange={this.handleIAgree}
              checked={this.state.iagree}
            />
            <FormLabel>
              I agree to the <Link to={'/terms'}>Terms of Service</Link> and{' '}
              <Link to={'/privacy-policy'}>Privacy Policy</Link>
            </FormLabel>
            <FormSubmit
              type='submit'
              value='Sign Up'
              disabled={!this.state.validationMet}
              onClick={this.handleSubmit}
            />
          </Form>
          {
            <TelegramStation nonempty={Boolean(this.props.telegrams.length)}>
              {this.props.telegrams.map((t) => (
                <span key={t.timestamp.toString()}>
                  {t.copy ? <Copy text={t.copy} /> : t.text}
                </span>
              ))}
            </TelegramStation>
          }
        </FormContainerSection>
      </FormScreen>
    )
}

const mapStateToProps = (state: any) => ({
  token: selectors.token(state),
  isLoggedIn: selectors.isLoggedIn(state),
  telegrams: selectors.telegrams(state),
})

const mapDispatchToProps = {
  signup,
  clearTelegramQueue,
  telegramSignal: telegram.signal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
