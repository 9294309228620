import { transformLwkResponse } from '../../../../../integrations/litwitkit/shared'
import { Plotmap, Beat, PlotmapType } from '../../types'

declare var LWK_API_URL: 'string'

export type CreatePlotmapRequest = {
  placement: number
  projectId: string
  type: PlotmapType
}

export type CreateBeatRequest = {
  placementX: number
  placementY: number
  placementZ: number
}

export const createPlotmap = async (
  token: string,
  plotmapRequest: CreatePlotmapRequest,
): Promise<Plotmap> => {
  const rawResponse = await fetch(`${LWK_API_URL}/plotmaps/create`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    body: JSON.stringify(plotmapRequest),
  })
  const { response } = await transformLwkResponse(rawResponse)
  return response as Plotmap
}

export const createBeat = async (
  token: string,
  plotmapId: string,
  beatPlacementRequest: CreateBeatRequest,
): Promise<Beat> => {
  const rawResponse = await fetch(
    `${LWK_API_URL}/plotmaps/id/${plotmapId}/beats/create`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Token: token,
      },
      body: JSON.stringify(beatPlacementRequest),
    },
  )
  const { response } = await transformLwkResponse(rawResponse)
  return response as Beat
}
