import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Project } from '../../../integrations/litwitkit/entities'

export type NavInterface =
  | 'Brainstorm'
  | 'Characters'
  | 'Config'
  | 'Manuscript'
  | 'Plotmaps'
  | 'Premise'
  | 'Projects'
  | 'Publication'
  | 'Revisions'
  | 'Settings'
  | 'Stages'
  | 'Synopsis'
  | 'WasteBasket'

export type ProjectNavProps = {
  currentInterface: NavInterface
  project?: Project
  projectId?: string
  title?: string
  showTitle?: boolean
}

export type NavTile = {
  description: string
  icon: IconProp
  navInterface?: NavInterface
  isDisabled?: boolean
  path?: (tokens?: any) => string
  shouldShow: (currentNavInterface?: string) => boolean
}

export type NavGroup = NavTile[]
export type Nav = Array<NavGroup | NavTile>

export const isGroup = (navItem: any): navItem is NavGroup =>
  navItem.navInterface === undefined

export type NavBarTileLinkProps = {
  iscurrentinterface: boolean
  isdisabled?: boolean
}
