import * as React from 'react'

import {
  PlotmapsSectionStyled,
  PlotmapSelectionStyled,
} from './PlotmapsSection.styles'
import { PlotmapEditor } from './PlotmapEditor/PlotmapEditor'
import { PlotmapList } from './PlotmapList/PlotmapList'
import { ProjectNav } from '../../../secondary/ProjectNav/components/ProjectNavComponent'
import { useParams, useHistory } from 'react-router-dom'
import { Plotmap, Beat, BeatPlacement } from '../types'
import { getByProjectId } from '../resources/read'
import StatusBar from 'Constructs/secondary/StatusBar/components/StatusBar'

type Params = {
  projectId: string
  plotmapId: string
}

const PlotmapsSection = () => {
  const { projectId, plotmapId } = useParams<Params>()
  const history = useHistory()

  const projectTitle = localStorage.getItem(`project-${projectId}-title`)
  const [currentPlotmapId, setCurrentPlotmapId] = React.useState(plotmapId)
  const [plotmaps, setPlotmaps] = React.useState([] as Plotmap[])
  const [beats, setBeats] = React.useState([] as Beat[])
  const [beatPlacements, setBeatPlacements] = React.useState(
    [] as BeatPlacement[],
  )

  if (!currentPlotmapId && plotmaps.length > 0) {
    setCurrentPlotmapId(plotmaps[0].id)
  }

  React.useEffect(() => {
    setCurrentPlotmapId(plotmapId)
  }, [plotmapId])

  React.useEffect(() => {
    getByProjectId(
      projectId,
      setPlotmaps,
      setCurrentPlotmapId,
      setBeats,
      setBeatPlacements,
      currentPlotmapId,
    )
  }, [projectId])

  if (!currentPlotmapId || !beats.length || !beatPlacements.length) {
    return null
  }

  const currentPlotmap = plotmaps.find((c) => c.id == currentPlotmapId)

  if (!currentPlotmap) {
    return null
  }

  return (
    currentPlotmap &&
    beats.length && (
      <PlotmapsSectionStyled>
        <ProjectNav
          currentInterface='Plotmaps'
          projectId={projectId}
          title={projectTitle}
        />
        <PlotmapSelectionStyled>
          <PlotmapList
            plotmaps={plotmaps}
            currentPlotmap={currentPlotmap}
            setCurrentPlotmapId={setCurrentPlotmapId}
            setPlotmaps={setPlotmaps}
          />
        </PlotmapSelectionStyled>
        <PlotmapEditor
          key={currentPlotmap.id}
          plotmap={currentPlotmap}
          beats={beats}
          beatPlacements={beatPlacements}
          setListTitle={(title: string) =>
            setPlotmaps(
              plotmaps.map((pm) =>
                pm.id === currentPlotmapId ? { ...pm, title } : pm,
              ),
            )
          }
          setBeats={setBeats}
          setBeatPlacements={setBeatPlacements}
        />
        <StatusBar />
      </PlotmapsSectionStyled>
    )
  )
}

export default PlotmapsSection
