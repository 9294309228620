import { transformLwkResponse } from '../../../../../integrations/litwitkit/shared'
import { Plotmap, Beat, BeatPlacement } from '../../types'

declare var LWK_API_URL: 'string'

export type UpdatePlotmapRequest = {
  archived: boolean
  description: string
  placement: number
  title: string
}

export type UpdateBeatRequest = {
  archived: boolean
  content: string
  title: string
}

export const updatePlotmapById = async (
  token: string,
  id: string,
  plotmapUpdate: UpdatePlotmapRequest,
): Promise<Plotmap> => {
  const rawResponse = await fetch(`${LWK_API_URL}/plotmaps/id/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    body: JSON.stringify(plotmapUpdate),
  })

  const { response } = await transformLwkResponse(rawResponse)
  return response as Plotmap
}

export const updateBeatById = async (
  token: string,
  id: string,
  beatUpdate: UpdateBeatRequest,
): Promise<Beat> => {
  const rawResponse = await fetch(`${LWK_API_URL}/plotmaps/beats/id/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    body: JSON.stringify(beatUpdate),
  })

  const { response } = await transformLwkResponse(rawResponse)
  return response as Beat
}

export const updateBeatPlacement = async (
  token: string,
  beatPlacement: BeatPlacement,
): Promise<any> => {
  const rawResponse = await fetch(
    `${LWK_API_URL}/plotmaps/id/${beatPlacement.plotmapId}/beats/id/${beatPlacement.beatId}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Token: token,
      },
      body: JSON.stringify(beatPlacement),
    },
  )

  const { response } = await transformLwkResponse(rawResponse)
  return response
}
