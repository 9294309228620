export const contactFormDescription = {
  english: `Say anything! This is your direct line to support at LitWitKit, and we welcome 
  feedback, whether it's a help request, a suggestion, a bug report, or just a comment.`,
}

export const contactFormSuccess = {
  english: `Your message has been sent! Thanks 
  for taking the time to submit your feedback. We'll get back to you as soon as we can.`,
}

export const contactFormError = {
  english: `There was a problem sending your message. Please copy and paste your message someplace safe, 
  check your internet connection, and try again later. Sorry for the inconvenience.`,
}
