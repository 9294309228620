import {
  ProjectIdParams,
  FetchByProjectByTagParams,
  ChecklistParams,
  NewChecklistParams,
} from './codec.types'

declare var LWK_API_URL: 'string'

export const fetchChecklistsByProjectRequest = ({
  token,
  projectId,
}: ProjectIdParams) => {
  return fetch(`${LWK_API_URL}/checklists/by/project/${projectId}/`, {
    headers: {
      Token: token,
    },
  })
}

export const fetchChecklistsByProjectByTagRequest = ({
  token,
  projectId,
  tag,
}: FetchByProjectByTagParams) => {
  return fetch(
    `${LWK_API_URL}/checklists/by/project/${projectId}/by/tag/${tag}`,
    {
      headers: {
        Token: token,
      },
    },
  )
}

export const fetchFirstChecklistByProjectByTagRequest = ({
  token,
  projectId,
  tag,
}: FetchByProjectByTagParams) => {
  return fetch(
    `${LWK_API_URL}/checklists/first/by/project/${projectId}/by/tag/${tag}`,
    {
      headers: {
        Token: token,
      },
    },
  )
}

export const createChecklistRequest = ({
  token,
  checklist,
}: NewChecklistParams) => {
  return fetch(`${LWK_API_URL}/checklists/create`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    method: 'POST',
    body: JSON.stringify(checklist),
  })
}

export const updateChecklistRequest = ({
  token,
  checklist,
}: ChecklistParams) => {
  return fetch(`${LWK_API_URL}/checklists/${checklist.id}/update`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    method: 'PUT',
    body: JSON.stringify(checklist),
  })
}
