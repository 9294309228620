import { Plotmap } from '../types'
import { updatePlotmap } from '../resources/update'
import { deleteBeatFromPlotmap } from '../resources/delete'
import { createPlotmap } from '../resources/create'
import { PlotmapType, Beat } from '../types'
import { updateBeat } from '../resources/update'

export const createPlotmapHandler = (
  templateName: PlotmapType,
  projectId: string,
  plotmaps: Plotmap[],
  setCurrentPlotmapId: React.Dispatch<React.SetStateAction<any>>,
  setPlotmaps: React.Dispatch<React.SetStateAction<any>>,
) =>
  createPlotmap(
    {
      type: templateName,
      placement: 0,
      projectId,
    },
    (pm: Plotmap) => {
      setCurrentPlotmapId(pm.id)
      setPlotmaps([pm, ...plotmaps])
    },
  )

export const archivePlotmapHandler = async (
  plotmap: Plotmap,
  plotmaps: Plotmap[],
  setPlotmaps: React.Dispatch<React.SetStateAction<any>>,
  setCurrentPlotmapId: React.Dispatch<React.SetStateAction<any>>,
) => {
  await updatePlotmap({ ...plotmap, archived: true })
  setPlotmaps(plotmaps.filter((pm) => plotmap.id !== pm.id))
  setCurrentPlotmapId(null)
}

export const deleteBeatHandler = async (
  plotmapId: string,
  beatId: string,
  beats: Beat[],
  setBeats: React.Dispatch<React.SetStateAction<any>>,
) => {
  await deleteBeatFromPlotmap(plotmapId, beatId)
  setBeats(beats.filter((beat) => beat.id !== beatId))
}

export const updatePlotmapHandler = async (
  plotmap: Plotmap,
  plotmaps: Plotmap[],
  setPlotmaps: React.Dispatch<React.SetStateAction<any>>,
  setCurrentPlotmapId: React.Dispatch<React.SetStateAction<any>>,
) => {
  await updatePlotmap(plotmap)
  setPlotmaps(plotmaps.map((pm) => (plotmap.id === pm.id ? plotmap : pm)))
  setCurrentPlotmapId(plotmap.id)
}

export const updateBeatHandler = async (
  beat: Beat,
  beats: Beat[],
  setBeats: React.Dispatch<React.SetStateAction<any>>,
) => {
  await updateBeat(beat)
  setBeats(beats.map((bt) => (beat.id === bt.id ? beat : bt)))
}
