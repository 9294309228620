import { Brainstorm } from '../types'
import * as H from 'history'
import { updateById } from '../resources/updateById'
import { create } from '../resources/create'
import { deleteById } from '../resources/deleteById'

export const handlers = (
  brainstorm: Brainstorm,
  brainstorms: Brainstorm[],
  setBrainstorms: (brainstorms: Brainstorm[]) => void,
  setCurrentBrainstormId: (brainstormId: string) => void,
  projectId: string,
  history: H.History<H.LocationState>,
) => {
  const handleTrash = async (brainstorm: Brainstorm) => {
    const updated = { ...brainstorm, archived: true }
    await setCurrentBrainstormId(null)
    await updateById(updated, projectId)
    await setBrainstorms(
      brainstorms.map((bs) => (brainstorm.id === bs.id ? updated : bs)),
    )

    history.push(`/app/project/${projectId}/brainstorms`)
  }

  const handleNew = (brainstormType: string) => {
    const updateState = (brainstorm: Brainstorm) => {
      setBrainstorms([...brainstorms, brainstorm])
      setCurrentBrainstormId(brainstorm.id)
      history.push(`/app/project/${projectId}/brainstorms/${brainstorm.id}`)
    }
    create({ brainstormType, projectId }, updateState)
  }

  const handleSetCurrent = (brainstorm: Brainstorm) => {
    if (brainstorm) {
      setCurrentBrainstormId(brainstorm.id)
      history.push(`/app/project/${projectId}/brainstorms/${brainstorm.id}`)
    } else {
      setCurrentBrainstormId(null)
    }
  }

  const handleUpdate = async (brainstorm: Brainstorm) => {
    await updateById(brainstorm, projectId)

    setBrainstorms(
      brainstorms.map((bs) => (brainstorm.id === bs.id ? brainstorm : bs)),
    )
    setCurrentBrainstormId(brainstorm.id)
  }

  const handleDelete = (brainstorm: Brainstorm) => {
    deleteById(brainstorm)
    setBrainstorms(brainstorms.filter((ch) => ch.id !== brainstorm.id))
    const firstBrainstorm = brainstorms.sort((a, b) =>
      a.brainstormType === 'general' ? -1 : 1,
    )[0]
    setCurrentBrainstormId(firstBrainstorm ? firstBrainstorm.id : null)
  }

  return {
    handleTrash,
    handleNew,
    handleSetCurrent,
    handleUpdate,
    handleDelete,
  }
}
