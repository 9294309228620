import * as React from 'react'
import { Editor } from 'Constructs/secondary/Editor/EditorBase'
import { faArrowLeft } from 'Assets/fontawesome-pro-solid'
import { Beat } from '../../types'
import { BeatEditorSection, Title } from './beatEditorStyles'
import { updateBeat } from '../../resources/update'

type BeatEditorProps = {
  currentBeat: Beat
  handleTrash: () => void
  setBeat: (beat: Beat) => void
}

const debounceTime = 1000
const throttleTime = 1000
const maxSaveGap = 6000

export const BeatEditor = ({
  currentBeat,
  handleTrash,
  setBeat,
}: BeatEditorProps) => {
  const [title, setTitle] = React.useState(currentBeat.title)
  const [content, setContent] = React.useState(currentBeat.content)
  const [lastEditorChange, setLastEditorChange] = React.useState(Date.now())
  const [lastEditorSave, setLastEditorSave] = React.useState(Date.now())
  const [editorTimeout, setEditorTimeout] = React.useState(null)

  const ref: any = React.useRef()

  React.useEffect(() => {
    ref.current = {
      lastEditorChange,
      lastEditorSave,
      content,
      currentBeat,
      title,
    }
  }, [lastEditorChange, lastEditorSave, content, currentBeat, title])

  React.useEffect(() => {
    return () => {
      const current = ref.current
      if (current.lastEditorChange > current.lastEditorSave) {
        clearTimeout(editorTimeout)
        updateBeat({
          ...current.currentBeat,
          content: current.content,
          title: current.title,
        })
        setBeat(currentBeat)
      }
    }
  }, [])

  const onEditorChange = (newContent: string) => {
    setContent(newContent)
    const now = Date.now()
    const timeSinceLastChange = now - lastEditorChange
    const timeSinceLastSave = now - lastEditorSave

    if (
      timeSinceLastSave > maxSaveGap ||
      (timeSinceLastChange > throttleTime && timeSinceLastSave > debounceTime)
    ) {
      clearTimeout(editorTimeout)

      const newBeat = {
        ...currentBeat,
        title: ref.current.title, // use ref because the editor doesn't rely on state to make changes (contenteditable shenanigans!)
        content: newContent,
      }
      updateBeat(newBeat)
      setBeat(newBeat)

      setLastEditorSave(now)
    } else {
      clearTimeout(editorTimeout)
      setEditorTimeout(
        setTimeout(() => onEditorChange(newContent), throttleTime + 1),
      )
    }
    setLastEditorChange(now)
  }

  const onTitleChange = (newTitle: string) => {
    setTitle(newTitle)
    const newBeat = { ...currentBeat, content, title: newTitle }
    updateBeat(newBeat)
    setBeat(newBeat)
  }

  return (
    <BeatEditorSection displaystyle={'mini'}>
      <Title
        placeholder='Untitled'
        value={title}
        onChange={onTitleChange}
        displaystyle={'mini'}
      />
      <Editor
        handleTrash={handleTrash}
        initialValue={currentBeat.content}
        onChange={onEditorChange}
        placeholder='Start writing'
        showToolbar={false}
        displaystyle={'mini'}
      />
    </BeatEditorSection>
  )
}
