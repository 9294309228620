import styled from '@emotion/styled'

const statusBarHeight = 30
const navBarHeight = 48

export const PlotmapsSectionStyled = styled.section`
  display: grid;
  grid-template-columns: 1fr 80vw;
  grid-template-areas:
    'projectNav       projectNav'
    'selectors        plotmapEditor';
`

export const PlotmapSelectionStyled = styled.section`
  grid-area: 'selectors';
  background: #fafafa;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  width: 20vw;
  min-width: 275px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  height: calc(100vh - ${statusBarHeight}px - ${navBarHeight}px);
  overflow: scroll;
`
