import {
  faBooks,
  faRoute,
  faBolt,
  faAcorn,
  faSeedling,
  faUserAstronaut,
  faCodeBranch,
  faShoePrints,
  faBroom,
  faRocket,
  faGopuram,
} from 'Assets/fontawesome-pro-solid'
import { StageLinkInput } from './stagesTypes'

export const stageLinkInputs: StageLinkInput[] = [
  {
    color: 'white',
    background: '#29B6F6',
    title: 'Brainstorms',
    pathSegment: 'brainstorms',
    icon: faBolt,
    enabled: true,
  },
  {
    color: 'white',
    background: '#8D6E63',
    title: 'Premise',
    pathSegment: 'premise',
    icon: faAcorn,
    enabled: false,
  },
  {
    color: 'white',
    background: '#9CCC65',
    title: 'Synopsis',
    pathSegment: 'synopsis',
    icon: faSeedling,
    enabled: true,
  },
  {
    color: 'white',
    background: '#FF7043',
    title: 'Characters',
    pathSegment: 'characters',
    icon: faUserAstronaut,
    enabled: true,
  },
  {
    color: 'white',
    background: '#8479d8',
    title: 'Settings',
    pathSegment: 'settings',
    icon: faGopuram,
    enabled: true,
  },
  {
    color: 'white',
    background: '#02BEB2',
    title: 'Plot Maps',
    pathSegment: 'plotmaps',
    icon: faCodeBranch,
    enabled: true,
  },
  {
    color: 'white',
    background: '#FFCA28',
    title: 'Manuscript',
    pathSegment: 'manuscript',
    icon: faShoePrints,
    enabled: true,
  },
  {
    color: 'white',
    background: '#7E57C2',
    title: 'Revisions',
    pathSegment: 'revisions',
    icon: faBroom,
    enabled: false,
  },
  {
    color: 'white',
    background: '#EF5350',
    title: 'Publications',
    pathSegment: 'publications',
    icon: faRocket,
    enabled: false,
  },
]
