import * as React from 'react'
import { Errors, CopyBlock } from './types'
import { Link } from 'react-router-dom'

export const errors: Errors = {
  failedToFetchLogin: {
    english: `There was a problem connecting with the server. We're sorry for the inconvenience. Please try again in a moment.`,
  },
  USER_PASSWORD_CRITERIA: {
    english: `Your password needs to be 8-256 characters and can only contain letters, numbers, dashes, periods, and basic special characters.`,
  },
  USER_EMAIL_VALIDATION: {
    english: `The email you provided doesn't appear to be valid.`,
  },
  NOT_UNIQUE: {
    english: `That email is already associated with an account.`,
  },
  USER_NO_MATCH: {
    english: `That user and password combination doesn't match.`,
  },
  FAILED_TO_AUTHENTICATE: {
    english: `That user and password combination doesn't match.`,
  },
  USER_NOT_FOUND: {
    english: (
      <span>
        That email address isn't associated with an account. Do you want to{' '}
        <Link to='/signup'>sign up</Link> instead?
      </span>
    ),
  },
  PLEASE_CONFIRM_ACCOUNT: {
    english: `Please confirm your account`,
  },
  UNKNOWN: {
    english: `We're experiencing technical difficulties. We're sorry for the inconvenience. Please try again in a few moments.`,
  },
}

export const signup = {
  heading: {
    english: `Sign up to LitWitKit!`,
  },
  success: {
    english: `Success!`,
  },
  passwordConfirmationMismatch: {
    english: `The password and password confirmation values don't yet match.`,
  },
}

export const login: CopyBlock = {
  heading: {
    english: `Log in to LitWitKit`,
  },
  success: {
    english: `Success!`,
  },
  loginButton: {
    english: `Log in`,
  },
  emailPlaceholder: {
    english: `Enter your email address`,
  },
  passwordPlaceholder: {
    english: `Enter your password`,
  },
}

export const resetPassword: CopyBlock = {
  heading: {
    english: `Reset your password`,
  },
  passwordPlaceholder: {
    english: 'Enter your password (8 character minimum)',
  },
  passwordConfirmationPlaceholder: {
    english: 'Please confirm your password',
  },
  submitButton: {
    english: 'Reset Password',
  },
  success: {
    english: (
      <span>
        Your password has been reset! You can now{' '}
        <Link to='/login'>log in</Link> with your new password.
      </span>
    ),
  },
}

export const requestResetPasswordEmail: CopyBlock = {
  heading: {
    english: `Reset your password`,
  },
  emailPlaceholder: {
    english: 'Enter your email address',
  },
  submitButton: {
    english: 'Send Reset Email',
  },
}

export const confirmYourAccount: CopyBlock = {
  heading: {
    english: `Please confirm your email`,
  },
  explanation: {
    english: `Before you start using LitWitKit, you need to confirm 
    your email address. Please check your inbox for a 
    welcome/account confirmation email.`,
  },
  emailPlaceholder: {
    english: 'Enter your email address',
  },
  submitButton: {
    english: 'Re-send Confirmation Email',
  },
  login: {
    english: `I've confirmed my email. Let me log in!`
  }
}

export const resetPasswordEmailSent: CopyBlock = {
  heading: {
    english: `An Email Has Been Sent!`,
  },
  details: {
    english: `An email has been sent to the email address you provided! It has
    instructions on how to reset your password. Thanks for your
    patience!`,
  },
  loginButton: {
    english: 'Log in',
  },
}

export const emailConfirmation: CopyBlock = {
  heading: {
    english: `You're all set!`,
  },
  details: {
    english: `Your email address has been confirmed! You're all set to 
    get started with LitWitKit.`,
  },
  button: {
    english: 'Log in',
  },
}

export const errorMessageToCopyMap: Errors = {
  [`Failed to fetch`]: errors.failedToFetchLogin,
}
