import { createAction, createAsyncAction } from 'typesafe-actions'
import { Dispatch } from 'redux'

import { setToken } from '../../generic/Auth/actions'
import {
  getProjectRequest,
  updateProjectRequest,
} from '../../../integrations/litwitkit/projects'
import * as telegram from '../../secondary/Telegram/actions'
import { Project } from '../../../integrations/litwitkit/entities'

export const setProject = createAction(
  'SET_PROJECT',
  (resolve) => (project: Project) => {
    return resolve(project)
  },
)

export const resetProject = createAction('RESET_NOTES')

export const fetchProject = (id: string) => (
  dispatch: any,
  getState: Function,
) => {
  dispatch(
    telegram.signal({
      type: 'request',
      text: 'Fetching project',
      blocking: true,
    }),
  )
  const token = localStorage.getItem('token')

  return getProjectRequest({ id, token })
    .then((rawResponse) => {
      if (rawResponse.status !== 200) {
        throw new Error(`Response of ${rawResponse.status}`)
      }
      if (rawResponse.ok && rawResponse.headers.has('Token')) {
        dispatch(telegram.signal({ type: 'success' }))
        dispatch(setToken(rawResponse.headers.get('Token')))
      } else {
        dispatch(telegram.signal({ type: 'error' }))
      }
      return rawResponse.json()
    })
    .then((res) => {
      dispatch(telegram.signal({ type: 'success' }))
      dispatch(setProject(res))
      return res
    })
    .catch((e) => {
      dispatch(telegram.signal({ type: 'error', text: e.message }))
    })
}

export const updateProjectAction = (project: Project) => (
  dispatch: any,
  getState: Function,
) => {
  dispatch(telegram.signal({ type: 'request', text: '' }), 'request')
  const token = localStorage.getItem('token')
  return updateProjectRequest({ token, project })
    .then((raw) => {
      if (raw.status !== 200) {
        throw new Error(`Response of ${raw.status}`)
      }
      return raw.json()
    })
    .then((res) => {
      dispatch(telegram.signal({ type: 'success' }))
      dispatch(setProject(res))
    })
    .catch((e) => {
      dispatch(telegram.signal({ type: 'error', text: e.message }))
    })
}
