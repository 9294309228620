import { getType } from 'typesafe-actions'

import { WordcountReducerState } from './types'
import * as actions from './actions'

const initialState: WordcountReducerState = {
  wordcounts: {
    characters: 0,
    notes: 0,
    projects: 0,
    projectTotals: [],
    segments: 0,
    user: 0,
  },
}

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case getType(actions.setWordcounts):
      return { ...state, wordcounts: action.payload }

    default:
      return state
  }
}
