import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faDice } from 'Assets/fontawesome-pro-light'
import {
  faRandom,
  faPageBreak,
  faLayerGroup,
  faAlignLeft,
  faTrash,
} from 'Assets/fontawesome-pro-solid'
import {
  ButtonSection,
  ListBuffer,
  NewPlotmapButton,
  SectionTitle,
  SectionTitleIcon,
  SectionTitleText,
  PlotmapButtonIcon,
  PlotmapListSection,
  SelectPlotmapButton,
  UnsortedScenesSection,
} from './styles'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { PlotmapType, Plotmap } from '../../types'
import { updatePlotmap } from '../../resources/update'
import { newPlotmapHandler } from './PlotmapList.handlers'
import {
  ListDescription,
  ListHeader,
  ListTitle,
} from 'Constructs/secondary/ListGenerics/ListGenerics'
import Copy from 'Constructs/secondary/Copy/components/CopyComponent'
import * as text from './text'
import { createPlotmapHandler } from '../PlotmapsSection.handlers'
import { useParams } from 'react-router-dom'

export type Category = {
  title: string
  tag: string
  icon?: IconProp
}

export type PlotmapListProps = {
  plotmaps: Plotmap[]
  currentPlotmap: Plotmap
  setCurrentPlotmapId: (plotmapId: string) => void
  setPlotmaps: (plotmaps: Plotmap[]) => void
}

export type SelectPlotmapButtonProps = {
  currentplotmap: boolean
  isBeingDragged: boolean
  isBeingDraggedOver: boolean
}

type Params = {
  projectId: string
  plotmapId: string
}

export const PlotmapList = ({
  plotmaps,
  currentPlotmap,
  setCurrentPlotmapId,
  setPlotmaps,
}: PlotmapListProps) => {
  const [dragOverPlotmapId, setDragOverPlotmapId] = React.useState(null)
  const [draggingPlotmapId, setDraggingPlotmapId] = React.useState(null)

  const { projectId, plotmapId } = useParams<Params>()

  if (!currentPlotmap || !plotmaps) {
    return null
  }

  const getPlotmapFromId = (id: string) => plotmaps.find((p) => p.id === id)

  const handlePlotmapClick = (event: React.MouseEvent) => {
    const currentPlotmapId = (event.target as HTMLElement).dataset.plotmapId
    const currentPlotmap = plotmaps.find((p) => p.id === currentPlotmapId)
    setCurrentPlotmapId(currentPlotmap.id)
  }

  const handleDragStart = (event: React.DragEvent) => {
    setDraggingPlotmapId((event.target as HTMLElement).id)
  }

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault()
    const draggedOverId = (event.target as HTMLElement).dataset.segmentId
    const draggedOverSection = (event.target as HTMLElement).dataset.section
    if (draggedOverSection === 'unsorted') {
      return
    }

    setDragOverPlotmapId(draggedOverId)
  }

  const handleDrop = (
    event: React.DragEvent,
    section: 'outline' | 'unsorted',
  ) => {
    setDragOverPlotmapId(null)
    setDraggingPlotmapId(null)
    if (!dragOverPlotmapId || draggingPlotmapId === dragOverPlotmapId) {
      return
    }
    const droppedOverPlotmapId = (event.target as HTMLElement).dataset.segmentId
    applyDropToUnsortedSection(droppedOverPlotmapId)
  }

  const applyDropToUnsortedSection = (droppedOverPlotmapId: string) => {
    const draggingPlotmap = getPlotmapFromId(draggingPlotmapId)
    const filteredPlotmaps: Plotmap[] = plotmaps.filter(
      (id) => id !== draggingPlotmapId,
    )
    const droppedSegmentIndex =
      droppedOverPlotmapId === 'tailBufferSpace'
        ? filteredPlotmaps.length
        : Math.max(
            0,
            filteredPlotmaps.findIndex(
              (plotmap) => plotmap.id === droppedOverPlotmapId,
            ),
          )
    const plotmapBefore = filteredPlotmaps[droppedSegmentIndex]
    const plotmapAfter = filteredPlotmaps[droppedSegmentIndex + 1]

    let newPlacement
    if (plotmapAfter) {
      newPlacement =
        plotmapBefore.placement +
        plotmapAfter.placement -
        plotmapBefore.placement
    } else {
      newPlacement = plotmapBefore.placement + 100000
    }
    updatePlotmap({ ...currentPlotmap, placement: newPlacement })
  }

  const plotmapIcon = (plotmapType: PlotmapType): IconProp => {
    switch (plotmapType) {
      case 'threadlines':
        return faPageBreak
      default:
        return faAlignLeft
    }
  }

  const plotmapTitle = (plotmap: Plotmap): string => {
    if (plotmap.title) {
      return plotmap.title
    }
    return 'Untitled Plotmap'
  }

  const renderPlotmaps = (plotmaps: Plotmap[]) => {
    return plotmaps.map((plotmap) => (
      <SelectPlotmapButton
        currentplotmap={currentPlotmap.id === plotmap.id}
        data-plotmap-id={plotmap.id}
        data-section={`unsorted`}
        onClick={handlePlotmapClick}
        key={plotmap.id}
        isBeingDraggedOver={
          plotmap.id === dragOverPlotmapId && plotmap.id !== draggingPlotmapId
        }
        isBeingDragged={plotmap.id === draggingPlotmapId}
      >
        <PlotmapButtonIcon icon={plotmapIcon(plotmap.type)} />{' '}
        {plotmapTitle(plotmap)}
      </SelectPlotmapButton>
    ))
  }

  return (
    <PlotmapListSection>
      <ListHeader>
        <ListTitle>
          <Copy text={text.title} />
        </ListTitle>
      </ListHeader>
      <ListDescription>
        <Copy text={text.description} />
      </ListDescription>
      <UnsortedScenesSection
        onDragOver={handleDragOver}
        onDrop={(ev) => handleDrop(ev, 'unsorted')}
      >
        <SectionTitle data-segment-id='headBufferSpace' data-section='unsorted'>
          <SectionTitleIcon icon={faRandom} />
          <SectionTitleText
            data-segment-id='headBufferSpace'
            data-section='unsorted'
          >
            Plotmaps
          </SectionTitleText>
        </SectionTitle>
        {renderPlotmaps(plotmaps)}
        <ListBuffer data-segment-id='tailBufferSpace' data-section='unsorted' />
      </UnsortedScenesSection>
      <ButtonSection>
        <NewPlotmapButton
          onClick={() =>
            createPlotmapHandler(
              'threadlines',
              projectId,
              plotmaps,
              setCurrentPlotmapId,
              setPlotmaps,
            )
          }
        >
          <FontAwesomeIcon icon={faPlusCircle} /> New plotmap
        </NewPlotmapButton>
      </ButtonSection>
    </PlotmapListSection>
  )
}
