import * as React from 'react'
import { SynopsisSection } from './styles'
import { ChecklistEditor } from '../../../secondary/ChecklistEditor'
import { ProjectNav } from '../../../secondary/ProjectNav/components/ProjectNavComponent'
import { Synopsis, Checklist } from '../types'
import { useParams } from 'react-router-dom'
import { getByProjectId } from '../resources/getByProjectId'
import { updateById } from '../resources/updateById'
import StatusBar from 'Constructs/secondary/StatusBar/components/StatusBar'
import { BasicEditor } from '../../../secondary/BasicEditor'
import * as text from './Synopsis.text'

type Params = {
  projectId: string 
}

export const SynopsisScreen = () => {
  const { projectId } = useParams<Params>()

  const [synopsis, setSynopsis] = React.useState(null as Synopsis)

  const projectTitle = localStorage.getItem(`project-${projectId}-title`)

  React.useEffect(() => {
    getByProjectId(projectId, setSynopsis)
  }, [projectId])

  return (
    <SynopsisSection>
      <ProjectNav
        currentInterface='Synopsis'
        projectId={projectId}
        title={projectTitle}
      />
      {synopsis && (
        <React.Fragment>
          <ChecklistEditor
            updateChecklist={(newChecklist: Checklist) => {
              const newSynopsis = { ...synopsis, checklist: newChecklist }
              setSynopsis(newSynopsis)
              updateById(newSynopsis)
            }}
            checklist={synopsis.checklist}
            title={text.checklistEditor.title}
            description={text.checklistEditor.description}
          />

          <BasicEditor
            id={synopsis.id}
            key={`synopsis-editor-${synopsis.id}`}
            initialContent={synopsis.content}
            initialTitle={`Synopsis`}
            setContent={(content: string) =>
              setSynopsis({ ...synopsis, content })
            }
            update={(newTitle: string, newContent: string) =>
              updateById({ ...synopsis, content: newContent })
            }
            projectId={projectId}
            isTitleEditable={false}
          />
        </React.Fragment>
      )}
      <StatusBar help={text.help} />
    </SynopsisSection>
  )
}

export default SynopsisScreen
