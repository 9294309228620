import * as React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type Props = {
  values: any[]
  defaultValue?: string
  icon: IconProp
}

const MetaDataSection = styled.div`
  margin-bottom: 10px;
`

const MetaIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  color: #aaa;
`

const MetaValue = styled.span`
  color: #676767;
`

export default ({ values, defaultValue, icon }: Props) => {
  return (
    Boolean(defaultValue || values.length) && (
      <MetaDataSection>
        <MetaIcon icon={icon} />
        <MetaValue>{values.join(', ') || defaultValue}</MetaValue>
      </MetaDataSection>
    )
  )
}
