import * as requests from './requests/post'
import { createResource } from '../../../../integrations/litwitkit/shared'
import { CreatePlotmapRequest, CreateBeatRequest } from './requests/post'

export const createPlotmap = async (
  plotmapRequest: CreatePlotmapRequest,
  updateState: Function,
): Promise<void> => {
  const storageKeyPrefix = `plotmap`

  const request = (token: string) => {
    return requests.createPlotmap(token, plotmapRequest)
  }

  const transformData = (rawPlotmap: any) => {
    return rawPlotmap
  }

  const [status, resource] = await createResource(
    storageKeyPrefix,
    request,
    transformData,
  )

  updateState(resource)
}

export const createBeat = async (
  beatPlacement: CreateBeatRequest,
  plotmapId: string,
  updateState: Function,
): Promise<void> => {
  const storageKeyPrefix = `beat`

  const request = (token: string) => {
    return requests.createBeat(token, plotmapId, beatPlacement)
  }

  const transformData = (rawBeat: any) => {
    return rawBeat
  }

  const [status, resource] = await createResource(
    storageKeyPrefix,
    request,
    transformData,
  )

  updateState(resource)
}
