import * as requests from './requests/create'
import { createResource } from '../../../../integrations/litwitkit/shared'
import { CreateCharacterRequest } from './requests/create'

export const create = async (
  newCharacter: CreateCharacterRequest,
  updateState: Function,
): Promise<void> => {
  const storageKeyPrefix = `character`

  const request = (token: string) => {
    return requests.create(token, newCharacter)
  }

  const transformData = (rawCharacter: any) => {
    return rawCharacter
  }

  const [status, resource] = await createResource(
    storageKeyPrefix,
    request,
    transformData,
  )

  updateState(resource)
}
