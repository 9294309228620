import { transformLwkResponse } from '../../../../../integrations/litwitkit/shared'

declare var LWK_API_URL: 'string'

type ConversationType = 'feedback' | 'direct-message' | 'comment'

export type ConversationPostBody = {
  content: string
  title: string
  conversationType: ConversationType
}

export const conversationPostRequest = async (
  token: string,
  conversationPostBody: ConversationPostBody,
): Promise<boolean> => {
  const rawResponse = await fetch(
    `${LWK_API_URL}/conversations/create`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Token: token,
      },
      body: JSON.stringify(conversationPostBody),
    },
  )
  const { response } = await transformLwkResponse(rawResponse)
  return response
}
