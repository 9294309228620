import {
  ResetPasswordRequest,
  SendPasswordResetEmailRequest,
} from './codec.types'

declare var LWK_API_URL: 'string'

export const resetPasswordRequest = ({
  token,
  userId,
  password,
}: ResetPasswordRequest) => {
  return fetch(`${LWK_API_URL}/users/id/${userId}/reset-password`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    method: 'PUT',
    body: JSON.stringify({ password }),
  })
}

export const sendPasswordResetEmailRequest = ({
  email,
}: SendPasswordResetEmailRequest) => {
  return fetch(`${LWK_API_URL}/identity/send-reset-password-email`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ email }),
  })
}
