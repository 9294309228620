import * as React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ProjectTile } from '../projectsStyles'
import { faPlusCircle } from 'Assets/fontawesome-pro-regular'

type Props = {
  handleNewProject: () => void
}

const NewProjectButton = styled.button`
  background: hsl(170, 66%, 59%);
  border-radius: 4px;
  height: 150px;
  place-items: center start;
  text-align: left;
  padding: 29px 31px;
  &:hover {
    background: hsl(170, 66%, 54%);
  }
`

const NewProjectIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  font-size: 28px;
  color: hsl(0, 0%, 90%);
`

const NewProjectText = styled.span`
  font-size: 31px;
  font-weight: bold;
  color: hsl(0, 0%, 98%);
`

export default ({ handleNewProject }: Props) => {
  return (
    <NewProjectButton onClick={handleNewProject}>
      <div>
        <NewProjectIcon icon={faPlusCircle} />
        <NewProjectText>New Project</NewProjectText>
      </div>
    </NewProjectButton>
  )
}
