import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import Copy from '../../../secondary/Copy/components/CopyComponent'
import * as text from '../text'
import * as telegram from '../../../secondary/Telegram/actions'
import {
  RequestResetPasswordEmailComponentProps,
  RequestResetPasswordEmailComponentState,
} from '../types'
import { sendResetPasswordEmail, clearTelegramQueue } from '../actions'
import * as selectors from '../selectors'
import {
  FormScreen,
  FormContainerSection,
  FormTitle,
  Form,
  FormInput,
  FormSubmit,
  FormLink,
  BreadcrumbIcon,
  BreadcrumbText,
  FormCheckbox,
  FormLabel,
  TelegramStation,
} from './styles'
import { faArrowLeft } from 'Assets/fontawesome-pro-light'

class RequestResetPasswordEmail extends React.Component<
  RequestResetPasswordEmailComponentProps,
  RequestResetPasswordEmailComponentState
> {
  state = {
    email: '',
    validationMet: false,
    sent: false,
  }

  componentWillUnmount = () => {
    this.props.clearTelegramQueue()
  }

  validate = () => {
    if (this.state.email.length < 6) {
      return this.setState({ validationMet: false })
    }
    return this.setState({ validationMet: true })
  }

  handleEmailInput = async (e: any) => {
    await this.setState({ email: e.target.value })
    this.validate()
  }

  handleSubmit = async (e: any) => {
    e.preventDefault()
    if (!this.state.validationMet) {
      return
    }

    const { email } = this.state

    const sendResult = await this.props.sendResetPasswordEmail({ email })
    if (sendResult) {
      this.props.history.push('/reset-password-email-sent')
    }
  }

  render = () =>
    this.props.isLoggedIn ? (
      <Redirect push to='/app/projects' />
    ) : (
      <FormScreen>
        <FormContainerSection>
          <BreadcrumbText>
            <BreadcrumbIcon icon={faArrowLeft} />
            <FormLink to={`/`}>Return to the intro screen</FormLink>, or{' '}
            <FormLink to={`/login`}>log in</FormLink> instead
          </BreadcrumbText>
          <FormTitle>
            <Copy text={text.requestResetPasswordEmail.heading} />
          </FormTitle>
          <Form>
            <FormInput
              type='email'
              value={this.state.email}
              onChange={this.handleEmailInput}
              placeholder={
                text.requestResetPasswordEmail.emailPlaceholder[
                  this.props.language
                ] as string
              }
              autoComplete='none'
            />
            <FormSubmit
              type='submit'
              value={
                text.requestResetPasswordEmail.submitButton[
                  this.props.language
                ] as string
              }
              disabled={!this.state.validationMet}
              onClick={this.handleSubmit}
            />
          </Form>
          {
            <TelegramStation nonempty={Boolean(this.props.telegrams.length)}>
              {this.props.telegrams.map((t) => (
                <span key={t.timestamp.toString()}>
                  {t.copy ? <Copy text={t.copy} /> : t.text}
                </span>
              ))}
            </TelegramStation>
          }
        </FormContainerSection>
      </FormScreen>
    )
}

const mapStateToProps = (state: any) => ({
  token: selectors.token(state),
  isLoggedIn: selectors.isLoggedIn(state),
  telegrams: selectors.telegrams(state),
  language: selectors.language(state),
})

const mapDispatchToProps = {
  sendResetPasswordEmail,
  clearTelegramQueue,
  telegramSignal: telegram.signal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestResetPasswordEmail)
