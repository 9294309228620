import { createAction, createAsyncAction } from 'typesafe-actions'
import { Dispatch } from 'redux'

import { premiseChecklistItems } from './premiseConstants'
import * as telegram from '../../secondary/Telegram/actions'

import {
  fetchFirstNoteByProjectByTagRequest,
  updateNoteRequest,
  createNoteRequest,
} from '../../../integrations/litwitkit/notes'
import { fetchProject } from '../Stages/stagesActions'
import {
  createChecklistRequest,
  fetchFirstChecklistByProjectByTagRequest,
  updateChecklistRequest,
} from '../../../integrations/litwitkit/checklists'
import { setToken } from '../../generic/Auth/actions'
import { Note, Checklist } from '../../../integrations/litwitkit/entities'
import { RootState } from '../../../rootReducer'

export const resetPremise = createAction('RESET_PREMISE')

export const setPremiseNote = createAction(
  'SET_PREMISE_NOTE',
  (resolve) => (premiseNote: Note) => {
    return resolve(premiseNote)
  },
)

export const setPremiseChecklist = createAction(
  'SET_PREMISE_CHECKLIST',
  (resolve) => (checklist: Checklist) => {
    return resolve(checklist)
  },
)

export const createPremiseNote = (projectId: string) => (
  dispatch: any,
  getState: () => RootState,
) => {
  dispatch(
    telegram.signal({ type: 'request', text: '', blocking: true }),
    'request',
  )
  const token = localStorage.getItem('token')
  return createNoteRequest({
    token,
    note: { title: 'The Premise', projects: [projectId], tags: ['premise'] },
  })
    .then((rawResponse) => {
      if (rawResponse.status !== 200) {
        throw new Error(`Response of ${rawResponse.status}`)
      }
      return rawResponse.json()
    })
    .then((res) => {
      dispatch(telegram.signal({ type: 'success' }))
      dispatch(setToken(res.token))
      dispatch(setPremiseNote(res.note))
    })
    .catch((e) => {
      dispatch(telegram.signal({ type: 'error', text: e.message }))
    })
}

export const createPremiseChecklist = (projectId: string) => (
  dispatch: any,
  getState: () => RootState,
) => {
  dispatch(
    telegram.signal({ type: 'request', text: '', blocking: true }),
    'request',
  )
  const token = localStorage.getItem('token')
  return createChecklistRequest({
    token,
    checklist: {
      title: 'The Premise',
      project: projectId,
      tags: ['premise'],
      items: premiseChecklistItems,
    },
  })
    .then((rawResponse) => {
      if (rawResponse.status !== 200) {
        throw new Error(`Response of ${rawResponse.status}`)
      }
      return rawResponse.json()
    })
    .then((res) => {
      dispatch(telegram.signal({ type: 'success' }))
      dispatch(setToken(res.token))
      dispatch(setPremiseChecklist(res.checklist))
    })
    .catch((e) => {
      dispatch(telegram.signal({ type: 'error', text: e.message }))
    })
}

export const fetchPremiseNote = (projectId: string) => (
  dispatch: any,
  getState: () => RootState,
) => {
  dispatch(
    telegram.signal({ type: 'request', text: '', blocking: true }),
    'request',
  )
  const token = localStorage.getItem('token')
  if (!getState().stages.project) {
    dispatch(fetchProject(projectId))
  }

  return fetchFirstNoteByProjectByTagRequest({
    projectId,
    token,
    tag: 'premise',
  })
    .then((rawResponse) => {
      if (rawResponse.status !== 200) {
        throw new Error(`Response of ${rawResponse.status}`)
      }
      return rawResponse.json()
    })
    .then((res) => {
      dispatch(telegram.signal({ type: 'success' }))
      dispatch(setToken(res.token))
      res.note
        ? dispatch(setPremiseNote(res.note))
        : dispatch(createPremiseNote(projectId) as any)
    })
    .catch((e) => {
      dispatch(telegram.signal({ type: 'error', text: e.message }))
    })
}

export const fetchPremiseChecklist = (projectId: string) => (
  dispatch: any,
  getState: () => RootState,
) => {
  dispatch(
    telegram.signal({ type: 'request', text: '', blocking: true }),
    'request',
  )
  const token = localStorage.getItem('token')
  return fetchFirstChecklistByProjectByTagRequest({
    projectId,
    token,
    tag: 'premise',
  })
    .then((rawResponse) => {
      if (rawResponse.status !== 200) {
        throw new Error(`Response of ${rawResponse.status}`)
      }
      return rawResponse.json()
    })
    .then((res) => {
      dispatch(telegram.signal({ type: 'success' }))
      dispatch(setToken(res.token))
      res.checklist
        ? dispatch(setPremiseChecklist(res.checklist))
        : dispatch(createPremiseChecklist(projectId) as any)
    })
    .catch((e) => {
      dispatch(telegram.signal({ type: 'error', text: e.message }))
    })
}

export const updateNoteAction = (note: Note) => (
  dispatch: any,
  getState: Function,
) => {
  dispatch(telegram.signal({ type: 'request', text: '' }), 'request')
  const token = localStorage.getItem('token')
  return updateNoteRequest({ token, note })
    .then((rawResponse) => {
      if (rawResponse.status !== 200) {
        throw new Error(`Response of ${rawResponse.status}`)
      }
      return rawResponse.json()
    })
    .then((res) => {
      dispatch(telegram.signal({ type: 'success' }))
      dispatch(setPremiseNote(res.note))
    })
    .catch((e) => {
      dispatch(telegram.signal({ type: 'error', text: e.message }))
    })
}

export const updateChecklistAction = (checklist: Checklist) => (
  dispatch: any,
  getState: Function,
) => {
  dispatch(telegram.signal({ type: 'request', text: '' }), 'request')
  const token = localStorage.getItem('token')
  return updateChecklistRequest({ token, checklist })
    .then((rawResponse) => {
      if (rawResponse.status !== 200) {
        throw new Error(`Response of ${rawResponse.status}`)
      }
      return rawResponse.json()
    })
    .then((res) => {
      dispatch(telegram.signal({ type: 'success' }))
      dispatch(setPremiseChecklist(res.checklist))
    })
    .catch((e) => {
      dispatch(telegram.signal({ type: 'error', text: e.message }))
    })
}
