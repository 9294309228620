import * as React from 'react'
import { BeatComponent } from './Beat'
import { Plotmap, Beat, BeatPlacement } from '../../types'
import {
  PlotmapEditorSection,
  Title,
  BreadcrumbLink,
  BreadcrumbIcon,
  BreadcrumbText,
} from './plotmapEditor.styles'
import {
  mouseDownHandler,
  mouseMoveHandler,
  mouseUpHandler,
} from './plotmapEditor.handlers.mouse'
import { titleChangeHandler } from './Threadlines.handlers.keyboard'
import { Threadlines } from './Threadlines'
import { useParams } from 'react-router-dom'
import { faArrowLeft } from 'Assets/fontawesome-pro-solid'

type PlotmapEditorProps = {
  plotmap: Plotmap
  beats: Beat[]
  beatPlacements: BeatPlacement[]
  setBeats: (beats: Beat[]) => void
  setBeatPlacements: (beatPlacements: BeatPlacement[]) => void
  setListTitle: (title: string) => void
}

export type DraggingBeatPlacement = BeatPlacement & {
  newX: number | null
  newY: number | null
  newZ: number | null
}

type Params = {
  projectId: string 
  plotmapId: string
}

export const PlotmapEditor = ({
  plotmap,
  beats,
  beatPlacements,
  setBeats,
  setBeatPlacements,
  setListTitle,
}: PlotmapEditorProps) => {
  const { projectId } = useParams<Params>()

  const [title, setTitle] = React.useState(plotmap.title)
  const [currentBeatId, setCurrentBeatId] = React.useState(null as string)
  const [draggingBeat, setDraggingBeat] = React.useState(
    null as DraggingBeatPlacement,
  )

  if (!plotmap) {
    return null
  }

  const handleTrash = async (beat: Beat) => {
    // await trashBeatAction(beat)
  }

  return (
    <PlotmapEditorSection
      onMouseDown={mouseDownHandler(
        beatPlacements,
        setDraggingBeat,
        setCurrentBeatId,
      )}
      onMouseUp={mouseUpHandler(
        draggingBeat,
        plotmap.id,
        beats,
        beatPlacements,
        setDraggingBeat,
        setBeats,
        setBeatPlacements,
        setCurrentBeatId,
      )}
      onMouseMove={mouseMoveHandler(draggingBeat, setDraggingBeat)}
    >
      {projectId && (
        <BreadcrumbLink to={`/app/project/${projectId}`}>
          <BreadcrumbIcon icon={faArrowLeft} />
          <BreadcrumbText>Back to Project Stages</BreadcrumbText>
        </BreadcrumbLink>
      )}
      <Title
        placeholder='Untitled'
        value={title}
        onChange={titleChangeHandler(plotmap, setTitle, setListTitle)}
      />
      <Threadlines
        beats={beats}
        beatPlacements={beatPlacements}
        currentBeatId={currentBeatId}
        setCurrentBeatId={setCurrentBeatId}
        setBeatPlacements={setBeatPlacements}
        setBeats={setBeats}
        plotmapId={plotmap.id}
        draggingBeat={draggingBeat}
      />
    </PlotmapEditorSection>
  )
}
