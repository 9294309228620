import * as requests from './requests/create'
import { createResource } from '../../../../integrations/litwitkit/shared'
import { CreateSettingRequest } from './requests/create'

export const create = async (
  newSetting: CreateSettingRequest,
  updateState: Function,
): Promise<void> => {
  const storageKeyPrefix = `setting`

  const request = (token: string) => {
    return requests.create(token, newSetting)
  }

  const transformData = (rawSetting: any) => {
    return rawSetting
  }

  const [status, resource] = await createResource(
    storageKeyPrefix,
    request,
    transformData,
  )

  updateState(resource)
}
