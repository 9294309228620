import * as React from 'react'
import styled from '@emotion/styled'
import StripeBadge from './StripeBadge'
import {
  faDrawCircle,
  faCodeBranch,
  faUserAstronaut,
  faHistory,
} from 'Assets/fontawesome-pro-regular'
import * as copy from './text'

const StripeSection = styled.section`
  min-height: 320px;
  width: 100%;
  background: #333;
  display: grid;
  place-items: center;
`

const StripeBadgeContainer = styled.section`
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  place-items: center;
  width: 80vw;
  min-width: 480px;
`

export default () => (
  <StripeSection>
    <StripeBadgeContainer>
      <StripeBadge
        icon={faDrawCircle}
        background='#29B6F6'
        copy={copy.stripe.plotMapping}
      />
      <StripeBadge
        icon={faCodeBranch}
        background='#9CCC65'
        copy={copy.stripe.progressTracking}
      />
      <StripeBadge
        icon={faUserAstronaut}
        background='#FF7043'
        copy={copy.stripe.characterDev}
      />
      <StripeBadge
        icon={faHistory}
        background='#7E57C2'
        copy={copy.stripe.revisionHistory}
      />
    </StripeBadgeContainer>
  </StripeSection>
)
