import { createAction, createAsyncAction } from 'typesafe-actions'

import {
  fetchAllProjectsForUserRequest,
  createProjectRequest,
  updateProjectRequest,
} from '../../../integrations/litwitkit/projects'
import { setToken } from '../../generic/Auth/actions'
import { setWordcounts } from '../../secondary/Wordcounts/actions'
import * as telegram from '../../secondary/Telegram/actions'
import { Project } from '../../../integrations/litwitkit/entities'
import { RootState } from '../../../rootReducer'

export const setProjects = createAction(
  'SET_PROJECTS',
  (resolve) => (projects: any) => {
    return resolve(projects)
  },
)

export const fetchAllProjectsForUserAction = () => (
  dispatch: any,
  getState: () => RootState,
) => {
  dispatch(
    telegram.signal({
      type: 'request',
      text: 'Fetching all projects',
      blocking: true,
    }),
  )
  const token = localStorage.getItem('token')
  return fetchAllProjectsForUserRequest({ token })
    .then((rawResponse) => {
      if (rawResponse.status !== 200) {
        throw new Error(`Response of ${rawResponse.status}`)
      }
      if (rawResponse.headers.has('Token')) {
        dispatch(setToken(rawResponse.headers.get('Token')))
      }
      return rawResponse.json()
    })
    .then((res) => {
      dispatch(telegram.signal({ type: 'success' }))
      dispatch(setWordcounts(res.wordcounts))
      dispatch(setProjects(res.projects || []))
    })
    .catch((e) => {
      dispatch(telegram.signal({ type: 'error', text: e.message }))
    })
}

export const createProjectAction = (title: string) => (
  dispatch: any,
  getState: () => RootState,
) => {
  dispatch(
    telegram.signal({
      type: 'request',
      text: 'Creating project',
      blocking: true,
    }),
  )
  const token = localStorage.getItem('token')
  return createProjectRequest({ token, project: { title } })
    .then((rawResponse) => {
      if (rawResponse.status !== 200) {
        throw new Error(`Response of ${rawResponse.status}`)
      }
      if (rawResponse.headers.has('Token')) {
        dispatch(setToken(rawResponse.headers.get('Token')))
      }
      return rawResponse.json()
    })
    .then(async (project) => {
      await dispatch(telegram.signal({ type: 'success' }))
      const projects = getState().projects.projects || []
      const newProjects = [...projects, project]
      await dispatch(setProjects(newProjects))
      return project.id
    })
    .catch((e) => {
      dispatch(telegram.signal({ type: 'error', text: e.message }))
    })
}

export const trashProjectAction = (project: Project) => (
  dispatch: any,
  getState: () => RootState,
) => {
  dispatch(
    telegram.signal({
      type: 'request',
      text: 'Moving project to wastebasket',
      blocking: true,
    }),
  )
  const token = localStorage.getItem('token')
  return updateProjectRequest({
    token,
    project: { ...project, ...{ active: false } },
  })
    .then((rawResponse) => {
      if (rawResponse.status !== 200) {
        throw new Error(`Response of ${rawResponse.status}`)
      }
      return rawResponse.json()
    })
    .then(async (res) => {
      await dispatch(telegram.signal({ type: 'success' }))
      const projects = getState().projects.projects
      await dispatch(setToken(res.token))
      await dispatch(
        setProjects({
          projects: projects.filter((p: Project) => p.id !== project.id),
        }),
      )
      return true
    })
    .catch((e) => {
      dispatch(telegram.signal({ type: 'error', text: e.message }))
    })
}
