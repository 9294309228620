import * as telegram from '../../constructs/secondary/Telegram'

type LwkResponse = {
  response: any
  newToken: string | null
}

type SyncStatus = 'offline' | 'synced' | 'error'
type CreateResponse = [SyncStatus, any]

export const transformLwkResponse = async (
  rawResponse: Response,
): Promise<LwkResponse> => {
  if (rawResponse.status >= 400) {
    throw new Error(`Response of ${rawResponse.status}`)
  }
  const newToken = rawResponse.headers.has('Token')
    ? rawResponse.headers.get('Token')
    : null

  // set new token
  localStorage.setItem('token', newToken)

  // respond with both
  let response
  switch (rawResponse.status) {
    case 200:
    case 201:
      response = await rawResponse.json()
  }
  return { response, newToken }
}

export const getResource = async (
  storageKey: string,
  request: (token: string) => Promise<any>,
  transformData?: (rawResource: any) => any,
) => {
  const storageStatusKey = `${storageKey}-status`

  // check whether we're in offline mode
  const offlineMode = localStorage.getItem('offlineMode')
  if (offlineMode === 'true') {
    const storedResource = localStorage.getItem(storageKey)
    let resource = null
    try {
      resource = JSON.parse(storedResource)
    } catch (e) {
      console.error(e)
    }
    return resource
  }

  // get the token
  const token = localStorage.getItem('token')

  // handle no token
  if (!token) {
    throw 'missing token'
  }

  // set that the resource is not in sync
  localStorage.setItem(storageStatusKey, 'fetching')

  // fetch the resource
  const rawResource = await request(token)

  // transform the resource
  const resource = transformData ? transformData(rawResource) : rawResource

  // log
  // console.log('got resource', resource)

  // update local storage
  localStorage.setItem(storageKey, JSON.stringify(resource))
  localStorage.setItem(storageStatusKey, 'synced')

  return resource
}

export const updateResource = async (
  storageKey: string,
  newResource: any,
  request: (token: string) => Promise<any>,
  transformData?: (rawResource: any) => any,
): Promise<SyncStatus> => {
  telegram.signal({ type: 'request', blocking: false })
  const storageStatusKey = `${storageKey}-status`

  // check whether we're in offline mode
  const offlineMode = localStorage.getItem('offlineMode')
  if (offlineMode === 'true') {
    localStorage.setItem(storageKey, JSON.stringify(newResource))
    localStorage.setItem(storageStatusKey, 'fetching')
    return 'offline'
  }

  // get the token
  const token = localStorage.getItem('token')

  // handle no token
  if (!token) {
    throw 'missing token'
  }

  // set that the resource is not in sync
  localStorage.setItem(storageStatusKey, 'setting')

  // fetch the resource
  let rawResponse
  try {
    rawResponse = await request(token)
  } catch (e) {
    telegram.signal({
      type: 'error',
      text: e && e.message ? e.message : 'Something went wrong',
      momentary: false,
    })
    return 'error'
  }

  // transform the resource
  const response = transformData ? transformData(rawResponse) : rawResponse

  // log
  // console.log('saved resource', response)

  // update local storage
  localStorage.setItem(storageKey, JSON.stringify(newResource))
  localStorage.setItem(storageStatusKey, 'synced')

  telegram.signal({ type: 'success' })
  return 'synced'
}

export const deleteResource = async (
  storageKey: string,
  request: (token: string) => Promise<any>,
): Promise<SyncStatus> => {
  const storageStatusKey = `${storageKey}-status`

  // check whether we're in offline mode
  const offlineMode = localStorage.getItem('offlineMode')
  if (offlineMode === 'true') {
    localStorage.setItem(storageKey, null)
    localStorage.setItem(storageStatusKey, 'deleting')
    return 'offline'
  }

  // get the token
  const token = localStorage.getItem('token')

  // handle no token
  if (!token) {
    throw 'missing token'
  }

  // set that the resource is not in sync
  localStorage.setItem(storageStatusKey, 'setting')

  // fetch the resource
  await request(token)

  // update local storage
  localStorage.setItem(storageKey, null)
  localStorage.setItem(storageStatusKey, 'deleted')

  return 'synced'
}

export const createResource = async (
  storageKeyPrefix: string,
  request: (token: string) => Promise<any>,
  transformData?: (rawResource: any) => any,
): Promise<CreateResponse> => {
  // check whether we're in offline mode
  const offlineMode = localStorage.getItem('offlineMode')
  if (offlineMode === 'true') {
    // TO DO: allow the resource to be set offline, perhaps with a provisional ID
    // and a new queue item to make the request in the future
    return ['offline', null]
  }

  // get the token
  const token = localStorage.getItem('token')

  // handle no token
  if (!token) {
    throw 'missing token'
  }

  // fetch the resource
  const rawResponse = await request(token)

  // transform the resource
  const newResource = transformData ? transformData(rawResponse) : rawResponse

  // log
  // console.log('saved resource', newResource)

  const storageKey = `${storageKeyPrefix}-${newResource.id}`
  const storageStatusKey = `${storageKey}-status`

  // update local storage
  localStorage.setItem(storageKey, JSON.stringify(newResource))
  localStorage.setItem(storageStatusKey, 'synced')

  return ['synced', newResource]
}
