import * as requests from './requests/delete'
import { deleteResource } from '../../../../integrations/litwitkit/shared'

export const deleteBeatFromPlotmap = async (
  plotmapId: string,
  beatId: string,
): Promise<void> => {
  const storageKey = `beat-${beatId}`

  const request = (token: string) =>
    requests.deleteBeatFromPlotmap(token, plotmapId, beatId)

  const resource = await deleteResource(storageKey, request)
}
