import { getType } from 'typesafe-actions'

import { ProjectReducerState } from './stagesTypes'
import * as actions from './stagesActions'

const initialState: ProjectReducerState = {
  project: null,
  isLoading: false,
}

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case getType(actions.setProject):
      return { ...state, project: action.payload }
    case getType(actions.resetProject):
      return initialState
    default:
      return state
  }
}
