import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import Copy from '../../../secondary/Copy/components/CopyComponent'
import * as text from '../text'
import * as telegram from '../../../secondary/Telegram/actions'
import {
  ResetPasswordEmailSentComponentProps,
  ResetPasswordEmailSentComponentState,
} from '../types'
import { clearTelegramQueue } from '../actions'
import * as selectors from '../selectors'
import {
  ActionLink,
  FormScreen,
  FormContainerSection,
  FormTitle,
  Form,
  FormLink,
  BreadcrumbIcon,
  BreadcrumbText,
  TelegramStation,
} from './styles'
import { faArrowLeft } from 'Assets/fontawesome-pro-light'

class ResetPasswordEmail extends React.Component<
  ResetPasswordEmailSentComponentProps,
  ResetPasswordEmailSentComponentState
> {
  componentWillUnmount = () => {
    this.props.clearTelegramQueue()
  }

  render = () =>
    this.props.isLoggedIn ? (
      <Redirect push to='/app/projects' />
    ) : (
      <FormScreen>
        <FormContainerSection>
          <BreadcrumbText>
            <BreadcrumbIcon icon={faArrowLeft} />
            <FormLink to={`/`}>Return to the intro screen</FormLink>, or{' '}
            <FormLink to={`/signup`}>sign up</FormLink> instead
          </BreadcrumbText>
          <FormTitle>
            <Copy text={text.resetPasswordEmailSent.heading} />
          </FormTitle>
          <p>
            <Copy text={text.resetPasswordEmailSent.details} />
          </p>

          {
            <TelegramStation nonempty={Boolean(this.props.telegrams.length)}>
              {this.props.telegrams.map((t) => (
                <span key={t.timestamp.toString()}>
                  {t.copy ? <Copy text={t.copy} /> : t.text}
                </span>
              ))}
            </TelegramStation>
          }
        </FormContainerSection>
      </FormScreen>
    )
}

const mapStateToProps = (state: any) => ({
  token: selectors.token(state),
  isLoggedIn: selectors.isLoggedIn(state),
  telegrams: selectors.telegrams(state),
  language: selectors.language(state),
})

const mapDispatchToProps = {
  clearTelegramQueue,
  telegramSignal: telegram.signal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordEmail)
