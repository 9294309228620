import * as requests from './requests/put'
import { updateResource } from '../../../../integrations/litwitkit/shared'
import { Plotmap, Beat, BeatPlacement } from '../types'

export const updatePlotmap = async (plotmap: Plotmap): Promise<void> => {
  const storageKey = `plotmap-${plotmap.id}`

  const request = (token: string) =>
    requests.updatePlotmapById(token, plotmap.id, {
      archived: plotmap.archived,
      description: plotmap.description,
      placement: plotmap.placement,
      title: plotmap.title,
    })

  const transformData = (rawPlotmap: Plotmap) => {
    return rawPlotmap
  }

  const resource = await updateResource(
    storageKey,
    plotmap,
    request,
    transformData,
  )
}

export const updateBeat = async (beat: Beat): Promise<void> => {
  const storageKey = `beat-${beat.id}`

  const request = (token: string) =>
    requests.updateBeatById(token, beat.id, {
      archived: beat.archived,
      content: beat.content,
      title: beat.title,
    })

  const transformData = (rawBeat: Beat) => {
    return rawBeat
  }

  const resource = await updateResource(
    storageKey,
    beat,
    request,
    transformData,
  )
}

export const updateBeatPlacement = async (
  beatPlacement: BeatPlacement,
): Promise<void> => {
  const storageKey = `beatPlacement-${beatPlacement.plotmapId}-${beatPlacement.beatId}`

  const request = (token: string) =>
    requests.updateBeatPlacement(token, beatPlacement)

  const transformData = (res: any) => {
    return res
  }

  const resource = await updateResource(
    storageKey,
    beatPlacement,
    request,
    transformData,
  )
}
