import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as snippets from '../../../../secondary/StyleKit/styleSnippets'
import { SelectSegmentButtonProps, HeaderMenuSectionProps } from './types'

const basePadding = 15
const innerPadding = 45
const statusBarHeight = 30
const navBarHeight = 48

export const SegmentListSection = styled.section`
  label: SegmentListSection;
  ${snippets.fadeIn}
  background: #fafafa;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  width: 20vw;
  min-width: 275px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  grid-area: segmentList;
  min-height: calc(100vh - ${statusBarHeight}px - ${navBarHeight}px);
  z-index: 10;
`

export const UnstagedScenesSection = styled.section`
  label: UnstagedScenesSection;
`

export const StagedSection = styled.section`
  label: StagedSection;
`

export const SectionTitle = styled.h1`
  font-size: 18px;
  font-weight: normal;
  padding-left: ${basePadding}px;
`

export const SectionTitleIcon = styled(FontAwesomeIcon)`
  color: #c4c4c4;
  margin-right: 10px;
`

export const SectionTitleText = styled.span``

export const SegmentSection = styled.section`
  label: SegmentSection;
`

export const ButtonSection = styled.section`
  label: ButtonSection;
`

export const SegmentButtonIcon = styled(FontAwesomeIcon)`
  min-width: 25px;
  margin-right: 5px;
`

export const SelectSegmentButton = styled.button`
  width: 100%;
  box-sizing: border-box;
  border-left: 5px solid transparent;
  padding: 10px 0 10px ${innerPadding}px;
  ${(props: SelectSegmentButtonProps) =>
    props.isBeingDraggedOver &&
    css`
      padding-top: 32px;
    `}
  ${(props: SelectSegmentButtonProps) =>
    props.currentsegment === true &&
    css`
      background: rgba(196, 196, 196, 0.2);
      font-weight: bold;
    `}
  ${(props: SelectSegmentButtonProps) =>
    props.isBeingDragged &&
    css`
      /* position: absolute; */
    `}
  ${(props: SelectSegmentButtonProps) => {
    return css`
      color: ${props.archived ? 'rgba(0,0,0,0.4);' : 'rgba(0,0,0,0.6);'};
      ${props.archived &&
      css`
        text-decoration: line-through;
      `}
    `
  }}
  &:focus {
    border-left: 5px solid #ccc;
  }
`

export const NewSegmentButton = styled.button`
  padding: 10px 0 10px ${innerPadding}px;
  color: #bbb;
`

export const ListBuffer = styled.div`
  height: 50px;
`

export const HeaderMenuSection = styled.section`
  opacity: 0;
  z-index: -10;
  transition: all 0.2s ease-in;
  ${(props: HeaderMenuSectionProps) =>
    props.visible &&
    css`
      opacity: 1;
      z-index: 10;
    `}
`
