import * as requests from './requests/put'
import { updateResource } from '../../../../integrations/litwitkit/shared'
import { Outline, Segment, SegmentPlacement } from '../types'

export const updateOutline = async (
  outline: Outline,
  updateState: Function,
): Promise<void> => {
  const storageKey = `outline-${outline.id}`

  const request = (token: string) =>
    requests.updateOutlineById(token, outline.id, {
      archived: outline.archived,
      description: outline.description,
      placement: outline.placement,
      stagedSegmentIds: outline.stagedSegmentIds,
      title: outline.title,
    })

  const transformData = (rawOutline: Outline) => {
    return rawOutline
  }

  const resource = await updateResource(
    storageKey,
    outline,
    request,
    transformData,
  )

  updateState(outline)
}

export const updateSegment = async (segment: Segment): Promise<void> => {
  const storageKey = `segment-${segment.id}`

  const request = (token: string) =>
    requests.updateSegmentById(token, segment.id, {
      archived: segment.archived,
      content: segment.content,
      title: segment.title,
    })

  const transformData = (rawSegment: Segment) => {
    return rawSegment
  }

  const resource = await updateResource(
    storageKey,
    segment,
    request,
    transformData,
  )
}
