import * as requests from '../requests/getByProjectId'
import { getResource } from '../../../../integrations/litwitkit/shared'
import { setStatus } from 'Constructs/secondary/Telegram'

export const getByProjectId = async (
  projectId: string,
  setSynopsis: React.Dispatch<React.SetStateAction<any>>,
): Promise<void> => {
  setStatus('waitingForeground')
  const storageKey = `project-${projectId}-synopsis`
  const request = (token: string) => requests.getByProjectId(token, projectId)
  const transformData = (rawSynopsis: any) => {
    const checklist = JSON.parse(rawSynopsis.checklist)
    return { ...rawSynopsis, checklist }
  }

  const resource = await getResource(storageKey, request, transformData)
  setSynopsis(resource)
  setStatus('idle')
}
