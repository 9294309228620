import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navItems, userNavItems } from '../projectNavConstants'
import { ProjectNavProps, isGroup, NavTile, NavGroup } from '../projectNavTypes'
import * as React from 'react'
import * as styles from '../projectNavStyles'
import { NavTitle, NavBarTileLink } from '../projectNavStyles'
import { kebabCase } from 'lodash'

export const ProjectNav = ({
  currentInterface,
  project,
  projectId,
  title,
  showTitle = true,
}: ProjectNavProps) => {
  const [hoverGroupIndex, setHoverGroupIndex] = React.useState(null)

  const shouldRenderNavGroup = (navGroup: NavGroup) =>
    Boolean(navGroup.find((vc) => vc.shouldShow(currentInterface)))

  const numberOfVisibleTiles = navItems.reduce(
    (sum: number, navTile: NavTile) =>
      sum +
      ((
        isGroup(navTile)
          ? shouldRenderNavGroup(navTile)
          : navTile.shouldShow(currentInterface)
      )
        ? 1
        : 0),
    0,
  )

  const handleGroupHover = (i: number) => (ev: React.SyntheticEvent) => {
    setHoverGroupIndex(ev.type === 'mouseenter' ? i : null)
  }

  const renderTile = (navTile: NavTile, i: number) => {
    const isCurrentInterface = navTile.navInterface === currentInterface
    return (
      navTile.shouldShow(currentInterface) && (
        <NavBarTileLink
          key={`nav-link-${
            navTile.navInterface || kebabCase(navTile.description)
          }-${i}`}
          iscurrentinterface={isCurrentInterface}
          isdisabled={isCurrentInterface || navTile.isDisabled}
          to={`${navTile.path({
            projectId: project ? project.id : projectId,
          })}`}
        >
          <FontAwesomeIcon icon={navTile.icon} style={styles.navBarTileIcon} />
          <span style={styles.navBarTileTitle}>{navTile.navInterface}</span>
        </NavBarTileLink>
      )
    )
  }

  const renderGroup = (navGroup: NavGroup, i: number) =>
    shouldRenderNavGroup(navGroup) && (
      <section
        key={`navGroup-${i}`}
        style={{
          ...styles.navBarGroup,
          ...{ overflow: i === hoverGroupIndex ? `visible` : `hidden` },
        }}
        onMouseEnter={handleGroupHover(i)}
        onMouseLeave={handleGroupHover(i)}
      >
        {[
          navGroup.find((n) => n.navInterface === currentInterface),
          ...navGroup,
        ]
          .filter(Boolean)
          .map((navTile: NavTile, i: number) => renderTile(navTile, i))}
      </section>
    )

  return (
    <section style={styles.navBar(numberOfVisibleTiles)}>
      {navItems.map((navItem, i) =>
        isGroup(navItem) ? renderGroup(navItem, i) : renderTile(navItem, i),
      )}
      {showTitle ? (
        <NavTitle>{project ? project.title : title}</NavTitle>
      ) : (
        <div />
      )}
      <div />
      {renderGroup(userNavItems, 0)}
    </section>
  )
}
