import * as ct from './codec.types'
import * as et from './entities'

declare var LWK_API_URL: 'string'

export const fetchNotesRequest = ({ token, noteIds, tag }: ct.NotesRequest) => {
  const notesQuery = `?ids=${noteIds.join(',')}`
  const tagQuery = tag ? `&tag=${tag}` : ''
  return fetch(`${LWK_API_URL}/notes/ids${notesQuery}${tagQuery}`, {
    headers: {
      Token: token,
    },
  })
}

export const fetchNotesByProjectRequest = ({
  token,
  projectId,
}: ct.ProjectIdParams) => {
  return fetch(`${LWK_API_URL}/notes/by/project/${projectId}/`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
  })
}

export const fetchNotesByProjectByTagRequest = ({
  token,
  projectId,
  tag,
}: ct.FetchByProjectByTagParams) => {
  return fetch(`${LWK_API_URL}/notes/by/project/${projectId}/by/tag/${tag}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
  })
}

export const fetchFirstNoteByProjectByTagRequest = ({
  token,
  projectId,
  tag,
}: ct.FetchByProjectByTagParams) => {
  return fetch(
    `${LWK_API_URL}/notes/first/by/project/${projectId}/by/tag/${tag}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Token: token,
      },
    },
  )
}

export const createNoteRequest = ({ token, note }: ct.NewNoteParams) => {
  return fetch(`${LWK_API_URL}/notes/create`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    method: 'POST',
    body: JSON.stringify(note),
  })
}

export const updateNoteRequest = ({ token, note }: ct.UpdateNoteParams) => {
  return fetch(`${LWK_API_URL}/notes/${note.id}/update`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    method: 'PUT',
    body: JSON.stringify(note),
  })
}
