import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect, Link, useHistory } from 'react-router-dom'
import Copy from '../../../secondary/Copy/components/CopyComponent'
import * as text from '../text'
import * as telegram from '../../../secondary/Telegram/actions'
import {
  ResetPasswordComponentProps,
  ResetPasswordComponentState,
} from '../types'
import { resetPassword, clearTelegramQueue } from '../actions'
import * as selectors from '../selectors'
import {
  FormScreen,
  FormContainerSection,
  FormTitle,
  Form,
  FormInput,
  FormSubmit,
  FormLink,
  BreadcrumbIcon,
  BreadcrumbText,
  FormCheckbox,
  FormLabel,
  TelegramStation,
} from './styles'
import { faArrowLeft } from 'Assets/fontawesome-pro-light'

class ResetPassword extends React.Component<
  ResetPasswordComponentProps,
  ResetPasswordComponentState
> {
  token: string
  userId: string

  state = {
    password: '',
    passwordConfirmation: '',
    validationMet: false,
    passwordHasBeenReset: false,
  }

  componentWillUnmount = () => {
    this.props.clearTelegramQueue()
  }

  componentDidMount = () => {
    try {
      const queryString: string = this.props.location.search || ''
      const queryParams = Object.fromEntries(
        queryString
          .slice(1)
          .split(/&/)
          .map((q) => {
            const match = q.match(/(\w*)=(.*)/)
            return match[1] && match[2] ? [match[1], match[2]] : null
          }),
      )
      this.token = queryParams.t
      this.userId = queryParams.uid
    } catch (e) {
      const history = useHistory()
      history.push(`/app`)
    }
  }

  validate = () => {
    if (
      this.state.password.length < 8 ||
      this.state.password !== this.state.passwordConfirmation
    ) {
      return this.setState({ validationMet: false })
    }
    return this.setState({ validationMet: true })
  }

  handlePasswordInput = async (e: any) => {
    await this.setState({ password: e.target.value })
    this.validate()
  }

  handlePasswordConfirmationInput = async (e: any) => {
    const passwordConfirmation = e.target.value
    await this.setState({ passwordConfirmation })
    if (
      passwordConfirmation.length >= this.state.password.length &&
      passwordConfirmation !== this.state.password
    ) {
      this.props.telegramSignal({
        type: 'info',
        text: '',
        copy: text.signup.passwordConfirmationMismatch,
        blocking: false,
        persistence: 'bumpable',
        momentary: false,
      })
    } else {
      this.props.clearTelegramQueue()
    }
    this.validate()
  }

  handleSubmit = async (e: any) => {
    e.preventDefault()
    if (!this.state.validationMet) {
      return
    }

    const { password } = this.state
    const { userId, token } = this

    if (!password || !userId || !token) {
      return
    }

    const resetPasswordResult = await this.props.resetPassword({
      userId,
      token,
      password,
    })
    if (resetPasswordResult) {
      await this.setState({ passwordHasBeenReset: true })
    }
  }

  render = () =>
    this.props.isLoggedIn ? (
      <Redirect push to='/app/projects' />
    ) : (
      <FormScreen>
        <FormContainerSection>
          <BreadcrumbText>
            <BreadcrumbIcon icon={faArrowLeft} />
            <FormLink to={`/`}>Return to the intro screen</FormLink>, or{' '}
            <FormLink to={`/login`}>log in</FormLink> instead
          </BreadcrumbText>
          <FormTitle>
            <Copy text={text.resetPassword.heading} />
          </FormTitle>
          <Form>
            <FormInput
              type='password'
              value={this.state.password}
              onChange={this.handlePasswordInput}
              disabled={this.state.passwordHasBeenReset}
              placeholder={
                text.resetPassword.passwordPlaceholder[
                  this.props.language
                ] as string
              }
              autoComplete='none'
            />
            <FormInput
              type='password'
              value={this.state.passwordConfirmation}
              onChange={this.handlePasswordConfirmationInput}
              disabled={this.state.passwordHasBeenReset}
              placeholder={
                text.resetPassword.passwordConfirmationPlaceholder[
                  this.props.language
                ] as string
              }
              autoComplete='none'
            />
            <FormSubmit
              type='submit'
              value={
                text.resetPassword.submitButton[this.props.language] as string
              }
              disabled={
                !this.state.validationMet || this.state.passwordHasBeenReset
              }
              onClick={this.handleSubmit}
            />
          </Form>
          {
            <TelegramStation nonempty={Boolean(this.props.telegrams.length)}>
              {this.props.telegrams.map((t) => (
                <span key={t.timestamp.toString()}>
                  {t.copy ? <Copy text={t.copy} /> : t.text}
                </span>
              ))}
            </TelegramStation>
          }
        </FormContainerSection>
      </FormScreen>
    )
}

const mapStateToProps = (state: any) => ({
  token: selectors.token(state),
  isLoggedIn: selectors.isLoggedIn(state),
  telegrams: selectors.telegrams(state),
  language: selectors.language(state),
})

const mapDispatchToProps = {
  resetPassword,
  clearTelegramQueue,
  telegramSignal: telegram.signal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
