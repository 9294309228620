import { transformLwkResponse } from '../../../../../integrations/litwitkit/shared'

declare var LWK_API_URL: 'string'

export const getByProjectId = async (token: string, projectId: string) => {
  const rawResponse = await fetch(
    `${LWK_API_URL}/characters/project-id/${projectId}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Token: token,
      },
    },
  )
  const { response } = await transformLwkResponse(rawResponse)
  return response
}
