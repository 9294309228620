import * as React from 'react'
import terms from './Terms'
import privacy from './Privacy'
import styled from '@emotion/styled'

const copy: any = { terms, privacy }

type LegalProps = {
  document: 'terms' | 'privacy'
}

const LegalScreen = styled.section`
  background: #eee;
  display: grid;
  place-content: center;
  margin: 0;
  padding: 1px;
`

const LegalDoc = styled.section`
  margin: 50px 30px;
  padding: 20px 60px;
  width: 70vw;
  min-width: 320px;
  max-width: 800px;
  background: white;
`

export default ({ document }: LegalProps) => {
  return (
    <LegalScreen>
      <LegalDoc>{copy[`${document}`]}</LegalDoc>
    </LegalScreen>
  )
}
