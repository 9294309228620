import styled from '@emotion/styled'
import { css } from '@emotion/react'

const supplementalBarHeight = 39

export const ToolbarSection = styled.section`
  label: ToolbarSection;
  background: hsl(0, 0%, 97%);
  width: 100%;
  padding: 0.5rem 25px 0.4rem;
  border-top: 2px solid hsl(0, 0%, 95%);
  border-bottom: 2px solid hsl(0, 0%, 95%);
  box-sizing: inherit;
  display: grid;
  grid-template-areas: 'markButtons blockButtons actionButtons';
  grid-template-columns: min-content;
`

export const MarkButtons = styled.section`
  label: MarkButtons;
  grid-area: markButtons;
  width: 251px;
`

export const BlockButtons = styled.section`
  label: BlockButtons;
  grid-area: blockButtons;
  border-left: 1px solid #ddd;
  padding-left: 10px;
`

export const ActionButtons = styled.section`
  display: grid;
  grid-area: actionButtons;
  grid-template-columns: repeat(2, 46px);
  justify-content: right;
  label: actionButtons;
`

