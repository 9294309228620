import { transformLwkResponse } from '../../../../../integrations/litwitkit/shared'
import { Outline, Segment, SegmentPlacement } from '../../types'

declare var LWK_API_URL: 'string'

export type UpdateOutlineRequest = {
  archived: boolean
  description: string
  placement: number
  stagedSegmentIds: string[] | null
  title: string
}

export type UpdateSegmentRequest = {
  archived: boolean
  content: string
  title: string
}

export const updateOutlineById = async (
  token: string,
  id: string,
  outlineUpdate: UpdateOutlineRequest,
): Promise<Outline> => {
  const rawResponse = await fetch(
    `${LWK_API_URL}/manuscripts/outlines/id/${id}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Token: token,
      },
      body: JSON.stringify(outlineUpdate),
    },
  )

  const { response } = await transformLwkResponse(rawResponse)
  return response as Outline
}

export const updateSegmentById = async (
  token: string,
  id: string,
  segmentUpdate: UpdateSegmentRequest,
): Promise<Segment> => {
  const rawResponse = await fetch(
    `${LWK_API_URL}/manuscripts/segments/id/${id}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Token: token,
      },
      body: JSON.stringify(segmentUpdate),
    },
  )

  const { response } = await transformLwkResponse(rawResponse)
  return response as Segment
}
