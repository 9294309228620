export const title = {
  english: 'Settings',
}

export const description = {
  english: 'There is a time and a place for everything.',
}

export const showArchived = {
  english: 'Show archived',
}
