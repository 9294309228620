import * as requests from './requests/get'
import { getResource } from '../../../../integrations/litwitkit/shared'
import { PlotmapsWithBeats, Plotmap, Beat, BeatPlacement } from '../types'

export const getByProjectId = async (
  projectId: string,
  setPlotmaps: React.Dispatch<React.SetStateAction<Plotmap[]>>,
  setCurrentPlotmapId: React.Dispatch<React.SetStateAction<string>>,
  setBeats: React.Dispatch<React.SetStateAction<Beat[]>>,
  setBeatPlacements: React.Dispatch<React.SetStateAction<BeatPlacement[]>>,
  currentPlotmapId: string | null,
): Promise<void> => {
  const storageKey = `project-${projectId}-plotmaps`
  const request = (token: string) => requests.getByProjectId(token, projectId)

  const resource: PlotmapsWithBeats = await getResource(storageKey, request)

  setPlotmaps(resource.plotmaps)
  setBeats(resource.beats)
  setBeatPlacements(resource.beatPlacements)

  if (!currentPlotmapId) {
    const firstPlotmap = resource.plotmaps[0]
    setCurrentPlotmapId(firstPlotmap ? firstPlotmap.id : null)
  }
}
