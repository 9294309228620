import { transformLwkResponse } from '../../../../../integrations/litwitkit/shared'

declare var LWK_API_URL: 'string'

export const resendConfirmationEmail = async (
  token: string,
): Promise<boolean> => {
  const rawResponse = await fetch(
    `${LWK_API_URL}/users/send-confirmation-email`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Token: token,
      },
    },
  )
  try {
    const { response } = await transformLwkResponse(rawResponse)
    return true
  } catch (e) {
    return false
  }
}
