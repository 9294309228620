import * as React from 'react'
import { getByProjectId } from '../resources/getByProjectId'
// import { SettingEditor } from './SettingEditor/SettingEditor'
import { BasicEditor } from 'Constructs/secondary/BasicEditor'
import { SettingList } from './SettingList/SettingList'
import { useParams, useHistory } from 'react-router-dom'
import { categories } from '../constants'
import { ProjectNav } from '../../../secondary/ProjectNav/components/ProjectNavComponent'
import { SettingSection } from './Settings.styles'
import { Setting } from '../types'
import { NoItems, NoItemsIcon } from '../../../secondary/StyleKit/styleSnippets'
import { faGhost } from 'Assets/fontawesome-pro-light'
import Copy from '../../../secondary/Copy/components/CopyComponent'
import * as text from './text'
import StatusBar from 'Constructs/secondary/StatusBar/components/StatusBar'
import { listHeadingMouseMoveHandler } from '../../../secondary/ListGenerics/ListGenerics'
import { deleteById } from '../resources/deleteById'
import { InlineTextButton } from '../../../secondary/EditorGenerics/EditorGenerics'
import { handlers } from './Settings.handlers'

type Params = {
  projectId: string
  settingId: string
}

const Settings = () => {
  const { projectId, settingId } = useParams<Params>()
  const history = useHistory()

  const projectTitle = localStorage.getItem(`project-${projectId}-title`)

  const [currentSettingId, setCurrentSettingId] = React.useState(settingId)
  const [settings, setSettings] = React.useState([] as Setting[])
  const [loaded, setLoaded] = React.useState(false)

  if (!currentSettingId && settings.length > 0) {
    const firstActiveSetting = settings
      .sort((a, b) => (a.category === '' ? -1 : 1))
      .find((ch) => !ch.archived)
    if (firstActiveSetting) {
      setCurrentSettingId(firstActiveSetting.id)
    }
  }

  let setting =
    currentSettingId && settings
      ? settings.find((c) => c.id == currentSettingId)
      : null

  React.useEffect(() => {
    setCurrentSettingId(settingId)
  }, [settingId])

  React.useEffect(() => {
    getByProjectId(
      projectId,
      (settings) => {
        setSettings(settings)
        setLoaded(true)
      },
      (e) => history.push(`/app`),
    )
  }, [projectId])

  const [showHeaderMenu, setShowHeaderMenu] = React.useState(false)
  const [showHeaderMenuTimeout, setShowHeaderMenuTimeout] = React.useState(null)
  React.useEffect(() => {
    // clean up timeout
    return () => {
      if (showHeaderMenuTimeout) {
        clearTimeout(showHeaderMenuTimeout)
        setShowHeaderMenuTimeout(null)
      }
    }
  }, [showHeaderMenuTimeout])

  const {
    handleTrash,
    handleNew,
    handleSetCurrent,
    handleUpdate,
    handleDelete,
  } = handlers(
    setting,
    settings,
    setSettings,
    setCurrentSettingId,
    projectId,
    history,
  )

  return (
    <SettingSection
      onMouseMove={listHeadingMouseMoveHandler(
        setShowHeaderMenu,
        setShowHeaderMenuTimeout,
        showHeaderMenuTimeout,
      )}
      onClick={(e) => {
        if (showHeaderMenu) {
          setShowHeaderMenu(false)
          setShowHeaderMenuTimeout(null)
        }
      }}
    >
      <ProjectNav
        currentInterface='Settings'
        projectId={projectId}
        title={projectTitle}
      />
      {settings && (
        <React.Fragment>
          <SettingList
            categories={categories}
            settings={settings}
            currentSetting={setting}
            showHeaderMenu={showHeaderMenu}
            setShowHeaderMenu={setShowHeaderMenu}
            handleNewSetting={handleNew}
            setCurrentSetting={handleSetCurrent}
            updateSettingAction={handleUpdate}
            updateSettingListAction={setSettings}
          />
          {setting && (
            <BasicEditor
              id={setting.id}
              key={`setting-editor-${setting.id}`}
              initialContent={setting.description}
              initialTitle={setting.title}
              setContent={() => {}}
              setTitle={() => {}}
              readOnly={setting.archived}
              header={
                setting.archived && (
                  <span>
                    <Copy text={text.currentlyArchived} />{' '}
                    <InlineTextButton onClick={handleDelete}>
                      <Copy text={text.deletePermanently} />
                    </InlineTextButton>
                  </span>
                )
              }
              update={(newTitle, newContent) =>
                handleUpdate({
                  ...setting,
                  title: newTitle,
                  description: newContent,
                })
              }
              projectId={projectId}
              handleTrash={handleTrash}
              language={'english'}
            />
          )}
          {!setting && loaded && (
            <NoItems>
              <section>
                <NoItemsIcon icon={faGhost} />
                <p>
                  <Copy text={text.noItems} />
                </p>
              </section>
            </NoItems>
          )}
        </React.Fragment>
      )}
      <StatusBar help={text.help} />
    </SettingSection>
  )
}

export default Settings
