import * as React from 'react'
import { CopyProps } from '../types'
import { connect } from 'react-redux'
import * as actions from '../actions'
import * as selectors from '../selectors'
import { RootState } from '../../../../rootReducer'

const Copy = ({ text, language, className, isStyled = false }: CopyProps) => {
  const raw = text[language]
  return isStyled ? (
    <span className={className}>{raw}</span>
  ) : (
    <React.Fragment>{raw}</React.Fragment>
  )
}

const mapStateToProps = (state: RootState) => ({
  language: selectors.language(state),
})

export default connect(mapStateToProps, { ...actions })(Copy)
