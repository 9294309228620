import { transformLwkResponse } from '../../../../integrations/litwitkit/shared'
import { Synopsis } from '../types'

declare var LWK_API_URL: 'string'

export type UpdateSynopsisRequest = {
  archived: boolean
  checklist: string
  content: string
}

export const updateById = async (
  token: string,
  id: string,
  synopsisUpdate: UpdateSynopsisRequest,
): Promise<Synopsis> => {
  const rawResponse = await fetch(`${LWK_API_URL}/synopses/id/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    body: JSON.stringify(synopsisUpdate),
  })

  const { response } = await transformLwkResponse(rawResponse)
  return response as Synopsis
}
