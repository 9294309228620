import styled from '@emotion/styled'
import { css } from '@emotion/react'

const supplementalBarHeight = 39
const editorHeaderHeight = 185 + supplementalBarHeight
const projectNavHeight = 78

type contentSectionProps = {
  displaystyle: string
  readOnly: boolean
}

export const ContentSection = styled.section`
  label: ContentSection;
  ${(props: contentSectionProps) => css`
    width: 80vw;
    height: calc(100vh - 224px - 78px);
    box-sizing: inherit;
    font-size: 19px;
    position: relative;
    line-height: 30px;
    transition: all 0.2s ease;
    overflow: scroll;
    max-width: 100%;
    &:hover {
      cursor: ${props.readOnly ? `auto` : `text`};
    }
    &:focus {
      outline: none;
    }
    ${props.displaystyle === 'mini'
      ? css`
          height: 300px;
          padding: 1rem 5rem 2rem 45px;
          border-top: 1px solid #eee;
        `
      : css`
          height: calc(100vh - ${editorHeaderHeight}px - ${projectNavHeight}px);
          padding: 2rem 5rem 2rem 45px;
        `};
  `}
`

type richEditorProps = {
  displaystyle: string
}

export const EditorSection = styled.section`
  label: EditorSection;
  ${(props: richEditorProps) => css`
    box-sizing: border-box;
    min-width: 320px;
    ${props.displaystyle === 'mini'
      ? css`
          margin-top: 10px;
          width: 100%;
        `
      : css`
          margin-top: 20px;
          width: 80vw;
        `};
  `};
`

export const SupplementalBar = styled.section`
  ${(props: { enabled: boolean }) =>
    css`
      min-height: ${props.enabled ? '39px' : '0px'};
      border-bottom: ${props.enabled ? '1px solid #ddd' : 'none'};
      padding: 8px 10px 3px;
      display: grid;
      grid-template-columns: 1fr 75px;
    `}
`
