import { getType } from 'typesafe-actions'

import { ProjectsReducerState } from './projectsTypes'
import * as actions from './projectsActions'

const initialState: ProjectsReducerState = {
  projects: [],
  isLoading: false,
}

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case getType(actions.setProjects):
      return { ...state, projects: action.payload }
    default:
      return state
  }
}
