export const title = {
  english: 'Manuscript',
}

export const description = {
  english: 'This is your body of work.',
}

export const showArchived = {
  english: 'Show archived',
}
