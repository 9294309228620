import * as React from 'react'

export default () => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.9734 19.7539L11.2512 26.6738L9.86719 18.9137L16.9848 11.4995L17.9734 19.7539Z'
      fill='#018786'
    />
    <path
      d='M26.6103 0.749516L1.09543 15.4988C0.88119 15.6226 0.900568 15.938 1.12836 16.0347L22.4698 25.0929C22.6475 25.1684 22.8491 25.0587 22.8825 24.8686L27.0559 1.06104C27.0999 0.810106 26.8308 0.622016 26.6103 0.749516Z'
      fill='#03DAC6'
    />
    <path
      d='M9.25075 19.5636L10.9543 26.4993C11.0097 26.7251 11.3407 26.6905 11.3484 26.4582L11.5457 20.4615C11.547 20.4193 11.5618 20.3785 11.5878 20.3452L26.1784 1.61401L9.29999 19.3782C9.25306 19.4276 9.2345 19.4975 9.25075 19.5636Z'
      fill='#02BEB2'
    />
  </svg>
)
