import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditableTitle from '../../../../secondary/EditableTitle'
import * as snippets from '../../../../secondary/StyleKit/styleSnippets'
import { css } from '@emotion/react'

const leftGutter = 40
const topMargin = 20

export const PlotmapEditorSection = styled.section`
  label: PlotmapEditorSection;
  ${snippets.fadeIn}
  height: calc(100vh - 78px);
  overflow: scroll;
`

export const BreadcrumbLink = styled(Link)`
  label: BreadcrumbLink;
  grid-area: BreadcrumbLink;
  padding-left: ${leftGutter}px;
  margin: ${topMargin}px 0;
  display: block;
  text-decoration: none;
  color: #aaa;
`

export const BreadcrumbIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`

export const BreadcrumbText = styled.span``

export const Title = styled(EditableTitle)`
  label: Title;
  grid-area: Title;
  margin-left: ${leftGutter}px;
  font-size: 36px;
  font-weight: bold;
`

type LineSectionProps = {
  currentHotkey: string
}

export const LinesSection = styled.section`
  label: LinesSection;
  grid-area: LinesSection;
  ${(props: LineSectionProps) => css`
    width: calc(100% - 140px);
    margin: 100px 100px 80px 40px;
    cursor: ${props.currentHotkey === 'mod' ? 'pointer' : 'inherit'};
  `}
`

type LineDivProps = {
  y: number
}

export const LineDiv = styled.div`
  label: LineDiv;
  grid-area: LineDiv;
  ${(props: LineDivProps) => css`
    background: #81949d;
    border-radius: 5px;
    height: 4px;
    width: 100%;
    position: relative;
    margin-bottom: 35px;
  `}
`

export const AddLineButton = styled.button`
  label: AddLineButton;
  color: #bbb;
  &:hover {
    color: #888;
  }
`

export const AddLineIcon = styled(FontAwesomeIcon)`
  label: AddLineIcon;
  margin-right: 5px;
`

export const Legend = styled.section`
  label: Legend;
  grid-area: Legend;
  opacity: 0.4;
  font-size: 14px;
  display: grid;
  margin-left: 40px;
`

export const LegendItem = styled.div`
  label: LegendItem;
  margin: 4px 0;
`

export const LegendAction = styled.span`
  label: LegendAction;
  font-weight: bold;
  margin-right: 2px;
`

export const LegendDescription = styled.span`
  label: LegendDescription;
`

export const Key = styled.span`
  label: Key;
  border-radius: 5px;
  padding: 1px 6px;
  background: hsl(198, 28%, 43%);
  font-size: 13px;
  color: hsl(0, 0%, 100%);
  margin: 0 3px;
`
