import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { css } from '@emotion/react'

const linkColor = `hsl(180,51%,44%)`
const linkColorFocus = `hsl(180, 50%, 32%)`

export const BreadcrumbText = styled.div`
  margin: 0 0 20px;
  display: inline;
  text-decoration: none;
  color: #aaa;
`

export const FormLink = styled(Link)`
  color: ${linkColor};
  text-decoration: none;
  &:hover {
    color: ${linkColorFocus};
    cursor: pointer;
  }
`

export const BreadcrumbIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`

export const Postscript = styled.p`
  padding: 17px 0;
  color: #aaa;
`

export const FormScreen = styled.section`
  display: grid;
  place-content: center;
  height: 100vh;
  background: #efefef;
  *:focus {
    outline: 1px dashed hsla(170, 50%, 70%);
  }
  hr {
    border-top: hsla(170, 50%, 50%);
    margin-top: 20px;
  }
  p {
    color: hsl(180,6%,40%);
    font-size: 17px;
    line-height: 1.5em;
    padding-bottom: 5px;
  }
`
export const FormContainerSection = styled.section`
  background: white;
  padding: 50px 60px 60px;
  margin-bottom: 10vh;
  border-radius: 3px;
  width: 480px;
`

export const Form = styled.form`
  font-size: 17px;
`

export const FormCenterIcon = styled(FontAwesomeIcon)`
  font-size: 80px;
  display: grid;
  margin: auto;
  color: hsl(180, 74%, 84%);
  margin-bottom: 40px;
`

export const FormTitle = styled.h1`
  margin-bottom: 40px;
  color: hsl(180, 90%, 40%);
`

export const FormInput = styled.input`
  display: block;
  margin-bottom: 20px;
  width: 100%;
  padding: 13px;
  box-sizing: border-box;
  border: 1px solid #eee;
  border-radius: 3px;
  box-shadow: 0 0 0px 100px #fafafa inset;
`

export const FormCheckbox = styled.input`
  margin-right: 8px;
`

export const FormLabel = styled.label`
  color: #999;
  a {
    opacity: 0.5;
  }
`

type FormSubmitProps = {
  disabled: boolean
}

export const FormSubmit = styled.input`
  border: 1px solid hsl(180, 100%, 90%);
  border-radius: 3px;
  background: hsl(180, 70%, 60%);
  padding: 10px;
  width: 100%;
  margin-top: 15px;
  font-weight: bold;
  font-size: 20px;
  color: hsla(180, 100%, 99%, 0.9);
  transition: all 0.17s ease;
  &[disabled] {
    color: #aaa;
    background: #fafafa;
    opacity: 0.66;
    border: 1px solid hsl(180, 10%, 90%);
  }
  &:hover:not([disabled]) {
    cursor: pointer;
    color: #fff;
    background: hsl(180, 80%, 70%);
  }
`

export const ActionLink = styled(Link)`
  background: hsl(180, 70%, 60%);
  border-radius: 3px;
  border: 1px solid hsl(180, 100%, 90%);
  color: hsla(180, 100%, 99%, 0.9);
  display: block;
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  transition: all 0.17s ease;
  width: 100%;
  &[disabled] {
    color: #aaa;
    background: #fafafa;
    opacity: 0.66;
    border: 1px solid hsl(180, 10%, 90%);
  }
  &:hover:not([disabled]) {
    cursor: pointer;
    color: #fff;
    background: hsl(180, 80%, 70%);
  }
`

type TelegramStationProps = {
  nonempty: boolean
}
export const TelegramStation = styled.section`
  background: hsl(0, 0%, 96%);
  border-radius: 4px;
  color: #777;
  display: grid;
  margin: 15px 0 0;
  min-height: 66px;
  padding: 15px;
  place-items: center;
  transition: all 0.2s ease;
  ${(props: TelegramStationProps) =>
    css`
      opacity: ${props.nonempty ? 0.9 : 0};
    `}
`
