import { keyboardShortcutLegend } from 'Constructs/secondary/Editor/Editor.constants'

export const help = {
  screenName: 'Project Stages',
  overview: [
    {
      title: { english: 'Step-by-Step Stages' },
      content: {
        english: `This is the Stages view of your project, where you can manage 
        and track its progress from start to finish.`,
      },
    },
    {
      title: { english: 'Each Stage is a Tool' },
      content: {
        english: `Every stage has a different interface designed to help you explore 
      ideas and express your creative vision in an organized, focused manner. 
      The stages are organized in a sequence that’s designed to guide you through the 
      creative process. Each stage builds on the ones prior.`,
      },
    },
    {
      title: { english: 'You’re in Control' },
      content: {
        english: `You don’t have to follow the stages in the order they’re listed. 
        You can jump between stages or skip them altogether.`,
      },
    },
  ],
  keys: [] as any,
}
