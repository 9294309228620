import * as React from 'react'
import { connect } from 'react-redux'
import { Link, useParams, useHistory, Redirect } from 'react-router-dom'

import { ManuscriptSection, OutlineSegmentSelection } from './styles'
import { DividerEditor } from './DividerEditor/DividerEditor'
import { SegmentEditor } from './SegmentEditor/SegmentEditor'
import { SegmentList } from './SegmentList/SegmentList'
import { ProjectNav } from '../../../secondary/ProjectNav/components/ProjectNavComponent'
import { Manuscript, Outline, Segment, SegmentPlacement, SegmentType } from '../types'
import { getByProjectId } from '../resources/read'
import { handlers } from './Manuscript.handlers'
import StatusBar from 'Constructs/secondary/StatusBar/components/StatusBar'
import { listHeadingMouseMoveHandler } from '../../../secondary/ListGenerics/ListGenerics'
import Copy from '../../../secondary/Copy/components/CopyComponent'
import { InlineTextButton } from '../../../secondary/EditorGenerics/EditorGenerics'
import * as text from './text'
import { updateOutline } from '../resources/update'
import { NoItems, NoItemsIcon } from '../../../secondary/StyleKit/styleSnippets'
import { faGhost } from 'Assets/fontawesome-pro-light'

type Params = {
  projectId: string
  outlineId: string
  segmentId: string
}

const Manuscript = () => {
  const { projectId, outlineId, segmentId } = useParams<Params>()
  const history = useHistory()

  const projectTitle = localStorage.getItem(`project-${projectId}-title`)

  const [outlines, setOutlines] = React.useState([] as Outline[])
  const [segments, setSegments] = React.useState([] as Segment[])
  const [loaded, setLoaded] = React.useState(false)

  const [showArchived, setShowArchived] = React.useState(false)

  React.useEffect(() => {
    getByProjectId(
      projectId,
      (manuscript: Manuscript) => {
        setOutlines(manuscript.outlines)
        setSegments(manuscript.segments)
        setLoaded(true)
      },
      (e) => history.push(`/app`),
    )
  }, [projectId])

  const [showHeaderMenu, setShowHeaderMenu] = React.useState(false)
  const [showHeaderMenuTimeout, setShowHeaderMenuTimeout] = React.useState(null)
  React.useEffect(() => {
    // clean up timeout
    return () => {
      if (showHeaderMenuTimeout) {
        clearTimeout(showHeaderMenuTimeout)
        setShowHeaderMenuTimeout(null)
      }
    }
  }, [showHeaderMenuTimeout])

  if (!outlines.length) {
    return null
  }

  if (!outlineId && segments.length) {
    const firstOutline = outlines[0]
    const firstSegmentId = firstOutline.stagedSegmentIds.find((sid) => {
      const segment = segments.find((seg) => seg.id === sid)
      if (showArchived) {
        return true
      } else {
        return !segment.archived
      }
    })

    if (!firstSegmentId) {
      return (
        <Redirect
          to={`/app/project/${projectId}/manuscript/${firstOutline.id}`}
        />
      )
    }

    return (
      <Redirect
        to={`/app/project/${projectId}/manuscript/${firstOutline.id}/${firstSegmentId}`}
      />
    )
  }

  const currentOutline = outlines.find((c) => c.id == outlineId)
  const currentSegment = segments.find((c) => c.id == segmentId)

  if (currentSegment && currentSegment.archived && !showArchived) {
    setShowArchived(true)
  }

  const { handleCreateSegment, handleTrash } = handlers(
    currentSegment,
    currentOutline,
    projectId,
    setSegments,
    setOutlines,
    segments,
    outlines,
    history,
  )

  const header = currentSegment && currentSegment.archived && (
    <span>
      <Copy text={text.currentlyArchived} />{' '}
      <InlineTextButton
        onClick={async () => {
          const newCurrentOutline = {
            ...currentOutline,
            stagedSegmentIds: currentOutline.stagedSegmentIds.filter(
              (sid) => sid !== currentSegment.id,
            ),
          }
          const newCurrentSegmentId =
            newCurrentOutline.stagedSegmentIds[0] || ''
          updateOutline(newCurrentOutline, (outline: Outline) => {
            setOutlines(
              outlines.map((out) => (out.id === outline.id ? outline : out)),
            )
          })
          history.push(
            `/app/project/${projectId}/manuscript/${currentOutline.id}/${newCurrentSegmentId}`,
          )
        }}
      >
        <Copy text={text.deletePermanently} />
      </InlineTextButton>
    </span>
  )

  return (
    <ManuscriptSection
      onMouseMove={listHeadingMouseMoveHandler(
        setShowHeaderMenu,
        setShowHeaderMenuTimeout,
        showHeaderMenuTimeout,
      )}
      onClick={(e) => {
        if (showHeaderMenu) {
          setShowHeaderMenu(false)
          setShowHeaderMenuTimeout(null)
        }
      }}
    >
      <ProjectNav
        currentInterface='Manuscript'
        projectId={projectId}
        title={projectTitle}
      />
      {!outlineId || !segments ? null : (
        <React.Fragment>
          <OutlineSegmentSelection>
            {/* <OutlineBrowser
            outlines={this.props.outlines}
            setCurrentOutline={this.props.setCurrentOutline}
            handleNewOutline={this.handleNewOutline}
            updateOutlineAction={this.props.updateOutlineAction}
          /> */}
            <SegmentList
              currentSegmentId={segmentId}
              outline={currentOutline}
              setOutline={(outline: Outline) =>
                setOutlines(
                  outlines.map((out) =>
                    out.id === outline.id ? outline : out,
                  ),
                )
              }
              showArchived={showArchived}
              setShowArchived={setShowArchived}
              segments={segments}
              createSegment={handleCreateSegment}
              setCurrentSegmentId={(id: string) => {
                history.push(
                  `/app/project/${projectId}/manuscript/${outlineId}/${id}`,
                )
              }}
              setSegments={setSegments}
              setShowHeaderMenu={setShowHeaderMenu}
              showHeaderMenu={showHeaderMenu}
            />
          </OutlineSegmentSelection>
          {!currentSegment ? null : currentSegment.type === 'divider' ? (
            <DividerEditor
              currentSegment={currentSegment}
              handleTrash={handleTrash}
              key={`segment-editor-${currentSegment.id}`}
              setSegments={setSegments}
              segments={segments}
            />
          ) : (
            <SegmentEditor
              currentSegment={currentSegment}
              handleTrash={handleTrash}
              header={header}
              readOnly={currentSegment.archived}
              key={`segment-editor-${currentSegment.id}`}
              setSegments={setSegments}
              segments={segments}
            />
          )}
          {!currentSegment && loaded && (
            <NoItems>
              <section>
                <NoItemsIcon icon={faGhost} />
                <p>
                  <Copy text={text.noItems} />
                </p>
              </section>
            </NoItems>
          )}
        </React.Fragment>
      )}
      <StatusBar />
    </ManuscriptSection>
  )
}

export default Manuscript
