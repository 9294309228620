import * as React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { BeatPlacement, Beat } from '../../types'

type BeatComponentProps = {
  beat: Beat
  beatPlacement: BeatPlacement
  isCurrentBeat: boolean
}

type BeatStyleProps = {
  x: number
  y: number
  z: number
  iscurrentbeat: boolean
}

const BeatDiv = styled.div`
  label: BeatDiv;
  ${(props: BeatStyleProps) => css`
    background: ${props.iscurrentbeat ? `#02beb2` : `#9bdfda`};
    border-radius: 50%;
    border: 4px solid white;
    height: 18px;
    left: calc(${props.x / 100}% - 9px);
    position: absolute;
    top: -7px;
    width: 18px;
    &:hover {
      cursor: pointer;
    }
  `}
`

export const BeatComponent = ({
  beat,
  beatPlacement,
  isCurrentBeat,
}: BeatComponentProps) => {
  return (
    <BeatDiv
      x={beatPlacement.placementX}
      y={beatPlacement.placementY}
      z={beatPlacement.placementZ}
      title={beat.title}
      data-beat-type={beat.beatType}
      data-beat-id={beat.id}
      iscurrentbeat={isCurrentBeat}
    ></BeatDiv>
  )
}
