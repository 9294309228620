import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { ProjectNav } from '../../../secondary/ProjectNav/components/ProjectNavComponent'
import {
  fetchProject,
  updateProjectAction,
  resetProject,
} from '../stagesActions'
import TextFieldSetting from './TextFieldSetting'
import TextListSetting from './TextListSetting'
import NameListSetting from './NameListSetting'
import * as selectors from '../stagesSelectors'
import { get } from 'lodash'
import { faArrowLeft, faPlusCircle } from 'Assets/fontawesome-pro-light'
import { ProjectConfigComponentProps } from '../stagesTypes'
import { withRouter } from 'react-router'
import {
  ProjectConfigSection,
  Heading,
  SettingsLink,
  SettingsButton,
  ProjectConfigGrid,
  GoalsForm,
  Setting,
  SettingLabel,
  SettingTextInput,
  SettingsForm,
  ActionsForm,
  DeleteSetting,
  DeleteButton,
  ConfirmationTextInput,
  FinePrint,
} from '../stagesStyles'
import { Title } from '../../Brainstorms/components/BrainstormEditor/styles'

const ProjectConfig = ({
  fetchProject,
  history,
  match,
  project,
  updateProjectAction,
}: ProjectConfigComponentProps) => {
  const [title, setTitle] = React.useState(get(project, 'title'))

  const [deleteConfirmationText, setDeleteConfirmationText] = React.useState('')

  let initialCompletionDate
  if (project && project.targetCompletionDate) {
    const rawDate = project.targetCompletionDate
    initialCompletionDate = new Date(rawDate)
  }
  const [completionDate, setCompletionDate] = React.useState(
    initialCompletionDate as Date,
  )

  React.useEffect(() => {
    if (!project) {
      fetchProject(match.params.projectId)
    }
    return () => {
      resetProject()
    }
  })

  const onTitleChange = (title: string) => {
    setTitle(title)
    updateProjectAction({ ...project, title })
  }

  const handleCompletionDateInput = (inputDate: string) => {
    const validator = /^20\d\d-(0[1-9]|1[02])-(0[1-9]|[1-2][0-9]|3[0-1]$)/
    if (!validator.test(inputDate)) {
      return
    }
    const targetCompletionDate = new Date(inputDate)
    setCompletionDate(targetCompletionDate)
    updateProjectAction({
      ...project,
      targetCompletionDate,
    })
  }

  const handleDeleteButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault()
    if (deleteConfirmationText !== project.title) {
      return
    }
    updateProjectAction({
      ...project,
      archived: true,
    })
    history.push(`/app/projects/`)
  }

  return (
    project && (
      <ProjectConfigSection>
        <ProjectNav
          currentInterface='Config'
          project={project}
          showTitle={false}
        />
        <Heading>
          <Title
            placeholder='Untitled'
            value={title}
            onChange={onTitleChange}
          />
          <SettingsLink to={`/app/project/${project.id}`}>
            <SettingsButton
              icon={faArrowLeft}
              title={`Back to project stages`}
            />
          </SettingsLink>
        </Heading>
        <ProjectConfigGrid>
          <GoalsForm>
            <h2>Goals</h2>
            <Setting>
              <SettingLabel>Target Word Count</SettingLabel>
              <SettingTextInput
                type='number'
                placeholder={`Your word count goal for this story's manuscript`}
                value={project.targetWordCount || null}
                onChange={(e) =>
                  updateProjectAction({
                    ...project,
                    targetWordCount: Number(e.target.value),
                  })
                }
              ></SettingTextInput>
            </Setting>
            <Setting>
              <SettingLabel>Target Completion Date</SettingLabel>
              <SettingTextInput
                type='date'
                // placeholder={`Your target completion date for this work's final draft`}
                placeholder={`MM/DD/YYYY`}
                value={
                  !completionDate
                    ? ''
                    : completionDate.toISOString().split('T')[0]
                }
                onChange={(e) => handleCompletionDateInput(e.target.value)}
              ></SettingTextInput>
            </Setting>
          </GoalsForm>
          <SettingsForm>
            <h2>Settings</h2>
            <TextFieldSetting
              field={`subtitle`}
              label={`Subtitle`}
              placeholder={`This work's subtitle, if any`}
            />
            <TextFieldSetting
              field={`seriesName`}
              label={`Series Name`}
              placeholder={`The name of the series this work is a part of, if any`}
            />
            <NameListSetting
              field={`authors`}
              label={`Authors`}
              buttonText={`Add an Author`}
              description={`A list of the authors that have contributed to this work`}
              placeholder={`Enter an author's full name`}
            />
            <NameListSetting
              field={`editors`}
              label={`Editors`}
              buttonText={`Add an Editor`}
              description={`A list of the editors that have contributed to this work`}
              placeholder={`Enter an editor's full name`}
            />
            <TextFieldSetting
              field={`edition`}
              label={`Edition`}
              placeholder={`A number or text description of the edition, e.g. Third Edition`}
            />
            <Setting>
              <SettingLabel>Volume Number</SettingLabel>
              <SettingTextInput
                type='number'
                placeholder={`The volume number`}
                value={get(project, 'volumeNumber') || ''}
                onChange={(e) =>
                  updateProjectAction({
                    ...project,
                    ...{ volumeNumber: Number(e.target.value) },
                  })
                }
              ></SettingTextInput>
            </Setting>
            <TextFieldSetting
              field={`isbn`}
              label={`ISBN`}
              placeholder={`The International Standard Book Number, if any`}
            />
            <TextListSetting
              buttonText={`Add a keyword`}
              field={`keywords`}
              label={`Keywords`}
              description={`A list of searchable keywords`}
              placeholder={`Enter a keyword`}
            />
            {/* <TextListSetting
              buttonText={`Add a language`}
              field={`languages`}
              label={`Languages`}
              description={`A list of the languages this work is written in`}
              placeholder={`Enter a language`}
            /> */}
          </SettingsForm>
          <ActionsForm>
            <h2>Actions</h2>
            <DeleteSetting>
              <DeleteButton
                engaged={
                  deleteConfirmationText === (project.title || 'Untitled')
                }
                onClick={(e) => handleDeleteButtonClick(e)}
              >
                Delete Work
              </DeleteButton>
              <ConfirmationTextInput
                type='text'
                placeholder={`Type this work's exact title here to unlock the delete button`}
                value={deleteConfirmationText}
                engaged={
                  deleteConfirmationText === (project.title || 'Untitled')
                }
                onChange={(e) => setDeleteConfirmationText(e.target.value)}
              ></ConfirmationTextInput>
              <FinePrint>Note: This action cannot be undone!</FinePrint>
            </DeleteSetting>
          </ActionsForm>
        </ProjectConfigGrid>
      </ProjectConfigSection>
    )
  )
}

const mapStateToProps = (state: any) => {
  return {
    project: selectors.project(state),
    key: get(selectors.project(state), 'id'),
  }
}

const mapDispatchToProps = { fetchProject, updateProjectAction, resetProject }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ProjectConfig))
