import * as React from 'react'
import { connect } from 'react-redux'
import {
  faSync,
  faCheck,
  faExclamationTriangle,
} from 'Assets/fontawesome-pro-regular'
import {
  StatusIcon,
  TelegramStatus,
  SuccessText,
  ErrorText,
} from '../telegramStyles'

import { Signal } from '../types'
import { useLocalState, jsonTransformer } from 'Utilities/useLocalState'

const Telegram = () => {
  const [signalQueue] = useLocalState(
    'telegram-signal-queue',
    [] as Signal[],
    jsonTransformer,
  )
  const [transmissionStatus] = useLocalState(
    'telegram-transmission-status',
    'idle',
  )

  const errorGram = signalQueue.find((s: Signal) => s.type === 'error')
  if (Boolean(errorGram)) {
    window.onbeforeunload = function () {
      return 'There may be unsaved changes. Are you sure you want to exit?'
    }
  } else {
    window.onbeforeunload = null
  }
  const successGram = signalQueue.find((s: Signal) => s.type === 'success')
  return (
    <TelegramStatus>
      {/* {signalQueue.map(s => (
          <div key={s.timestamp.valueOf()}>
            {s.type} {s.text}
          </div>
        ))} */}
      {Boolean(successGram) && (
        <React.Fragment>
          <StatusIcon icon={faCheck} />
          <SuccessText>{successGram.text}</SuccessText>
        </React.Fragment>
      )}
      {Boolean(errorGram) && (
        <React.Fragment>
          <StatusIcon icon={faExclamationTriangle} />
          <ErrorText>{errorGram.text}</ErrorText>
        </React.Fragment>
      )}
      {transmissionStatus === 'waitingBackground' && (
        <StatusIcon icon={faSync} />
      )}
    </TelegramStatus>
  )
}

// const mapStateToProps = (state: any) => ({
//   signalQueue: state.telegram.signalQueue,
//   transmissionStatus: state.telegram.transmissionStatus,
//   log: state.telegram.log,
// })

// export default connect(mapStateToProps)(Telegram)

export default Telegram
