import * as React from 'react'

export const currentlyArchived = {
  english: 'This manuscript section is currently archived.',
}

export const deletePermanently = {
  english: 'Delete permanently.',
}

export const noItems = {
  english: `You don't have any manuscript sections yet. Create your first one!`,
}
