import * as React from 'react'
import { getByProjectId } from '../resources/getByProjectId'
import { CharacterEditor } from './CharacterEditor/CharacterEditor'
import { CharacterList } from './CharacterList/CharacterList'
import { useParams, useHistory } from 'react-router-dom'
import { categories } from '../constants'
import { ProjectNav } from '../../../secondary/ProjectNav/components/ProjectNavComponent'
import { CharacterSection } from './charactersStyles'
import { Character } from '../types'
import { NoItems, NoItemsIcon } from '../../../secondary/StyleKit/styleSnippets'
import { faGhost } from 'Assets/fontawesome-pro-light'
import Copy from '../../../secondary/Copy/components/CopyComponent'
import * as text from './text'
import StatusBar from 'Constructs/secondary/StatusBar/components/StatusBar'
import { listHeadingMouseMoveHandler } from '../../../secondary/ListGenerics/ListGenerics'
import { deleteById } from '../resources/deleteById'
import { InlineTextButton } from '../../../secondary/EditorGenerics/EditorGenerics'
import { handlers } from './Characters.handlers'

type Params = {
  projectId: string
  characterId: string
}

const Characters = () => {
  const { projectId, characterId } = useParams<Params>()
  const history = useHistory()

  const projectTitle = localStorage.getItem(`project-${projectId}-title`)

  const [currentCharacterId, setCurrentCharacterId] = React.useState(
    characterId,
  )
  const [characters, setCharacters] = React.useState([] as Character[])
  const [loaded, setLoaded] = React.useState(false)

  if (!currentCharacterId && characters.length > 0) {
    const firstActiveCharacter = characters
      .sort((a, b) => (a.primaryRole === '' ? -1 : 1))
      .find((ch) => !ch.archived)
    if (firstActiveCharacter) {
      setCurrentCharacterId(firstActiveCharacter.id)
    }
  }

  let character =
    currentCharacterId && characters
      ? characters.find((c) => c.id == currentCharacterId)
      : null

  React.useEffect(() => {
    setCurrentCharacterId(characterId)
  }, [characterId])

  React.useEffect(() => {
    getByProjectId(
      projectId,
      (characters) => {
        setCharacters(characters)
        setLoaded(true)
      },
      (e) => history.push(`/app`),
    )
  }, [projectId])

  const [showHeaderMenu, setShowHeaderMenu] = React.useState(false)
  const [showHeaderMenuTimeout, setShowHeaderMenuTimeout] = React.useState(null)
  React.useEffect(() => {
    // clean up timeout
    return () => {
      if (showHeaderMenuTimeout) {
        clearTimeout(showHeaderMenuTimeout)
        setShowHeaderMenuTimeout(null)
      }
    }
  }, [showHeaderMenuTimeout])

  const {
    handleTrash,
    handleNew,
    handleSetCurrent,
    handleUpdate,
    handleDelete,
  } = handlers(
    character,
    characters,
    setCharacters,
    setCurrentCharacterId,
    projectId,
    history,
  )

  return (
    <CharacterSection
      onMouseMove={listHeadingMouseMoveHandler(
        setShowHeaderMenu,
        setShowHeaderMenuTimeout,
        showHeaderMenuTimeout,
      )}
      onClick={(e) => {
        if (showHeaderMenu) {
          setShowHeaderMenu(false)
          setShowHeaderMenuTimeout(null)
        }
      }}
    >
      <ProjectNav
        currentInterface='Characters'
        projectId={projectId}
        title={projectTitle}
      />
      {characters && (
        <React.Fragment>
          <CharacterList
            categories={categories}
            characters={characters}
            currentCharacter={character}
            showHeaderMenu={showHeaderMenu}
            setShowHeaderMenu={setShowHeaderMenu}
            handleNewCharacter={handleNew}
            setCurrentCharacter={handleSetCurrent}
            updateCharacterAction={handleUpdate}
            updateCharacterListAction={setCharacters}
          />
          {character && (
            <CharacterEditor
              currentCharacter={character}
              key={`character-editor-${character.id}`}
              projectId={projectId}
              readOnly={character.archived}
              handleTrash={handleTrash}
              header={
                character.archived && (
                  <span>
                    <Copy text={text.currentlyArchived} />{' '}
                    <InlineTextButton onClick={handleDelete}>
                      <Copy text={text.deletePermanently} />
                    </InlineTextButton>
                  </span>
                )
              }
              updateCharacterAction={handleUpdate}
            />
          )}
          {!character && loaded && (
            <NoItems>
              <section>
                <NoItemsIcon icon={faGhost} />
                <p>
                  <Copy text={text.noItems} />
                </p>
              </section>
            </NoItems>
          )}
        </React.Fragment>
      )}
      <StatusBar help={text.help} />
    </CharacterSection>
  )
}

export default Characters
