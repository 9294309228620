import * as React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import * as telegram from '../../secondary/Telegram/actions'
import * as selectors from './IntroSelectors'
import PublicNav from './PublicNav'
import Header from './Header'
import Stripe from './Stripe'
import Footer from './Footer'
import { Language } from '../../secondary/Copy/types'
import { Signal } from '../../secondary/Telegram/types'

export const IntroScreen = styled.section`
  background: #222;
  min-height: 100vh;
`

type Props = {
  isLoggedIn: boolean
  language: Language
  telegrams: Signal[]
}

const Intro = ({ isLoggedIn, language, telegrams }: Props) => {
  return (
    <IntroScreen>
      <PublicNav isLoggedIn={isLoggedIn} />
      <Header />
      <Stripe />
      <Footer />
    </IntroScreen>
  )
}

const mapStateToProps = (state: any) => ({
  isLoggedIn: selectors.isLoggedIn(state),
  language: selectors.language(state),
  telegrams: selectors.telegrams(state),
})

const mapDispatchToProps = {
  telegramSignal: telegram.signal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Intro)
