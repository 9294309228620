import * as React from 'react'
import { connect } from 'react-redux'

import { ProjectsComponentProps } from '../projectsTypes'
import {
  fetchAllProjectsForUserAction,
  createProjectAction,
  trashProjectAction,
} from '../projectsActions'
import { selectProjects } from '../projectsSelectors'
import { ProjectNav } from '../../../secondary/ProjectNav/components/ProjectNavComponent'
import {
  ProjectsSection,
  ProjectTile,
  ProjectsContainer,
  ProjectLink,
  DeleteProjectButton,
} from '../projectsStyles'
import ProjectTitle from './ProjectTitle'
import ProjectProgress from './ProjectProgress'
import ProjectMetaData from './ProjectMetaData'
import ProjectActions from './ProjectActions'
import NewProject from './NewProject'
import { withRouter } from 'react-router'
import { Project } from '../../../../integrations/litwitkit/entities'

class Projects extends React.Component<ProjectsComponentProps> {
  componentDidMount = () => {
    this.props.fetchAllProjectsForUserAction()
  }

  handleNewProject = async () => {
    const newProjectId = await this.props.createProjectAction('New Project')
    this.props.history.push(`/app/project/${newProjectId}`)
  }

  handleTrash = async (project: Project) => {
    await this.props.trashProjectAction(project)
  }

  renderProjects = () =>
    this.props.projects.map((project) => (
      <ProjectLink to={`/app/project/${project.id}`} key={project.id}>
        <ProjectTile key={project.id}>
          <ProjectTitle>{project.title}</ProjectTitle>
          {/* <ProjectProgress projectId={project.id} goals={project.goals} /> */}
          <ProjectMetaData project={project} />
        </ProjectTile>
      </ProjectLink>
    ))

  render() {
    return (
      <ProjectsSection>
        <ProjectNav currentInterface='Projects' />
        <ProjectsContainer>
          <NewProject handleNewProject={this.handleNewProject} />
          {this.props.projects && this.renderProjects()}
        </ProjectsContainer>
      </ProjectsSection>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    projects: selectProjects(state),
  }
}

const mapDispatchToProps = {
  fetchAllProjectsForUserAction,
  createProjectAction,
  trashProjectAction,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Projects))
