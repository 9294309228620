import styled from '@emotion/styled'

const statusBarHeight = 30
const navBarHeight = 48

export const ManuscriptSection = styled.section`
  label: ManuscriptSection;
  display: grid;
  grid-template-areas:
    'projectNav       projectNav'
    'selectors        editor';
`

export const OutlineSegmentSelection = styled.section`
  label: OutlineSegmentSelection;
  grid-area: 'selectors';
  background: #fafafa;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  width: 20vw;
  min-width: 275px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  height: calc(100vh - ${statusBarHeight}px - ${navBarHeight}px);
  overflow: scroll;
`
