import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditableTitle from '../../../../secondary/EditableTitle'
import { Editor } from 'Constructs/secondary/Editor/EditorBase'
import * as snippets from '../../../../secondary/StyleKit/styleSnippets'

const leftGutter = 40
const topMargin = 20

export const DividerEditorSection = styled.section`
  ${snippets.fadeIn}
`

export const BreadcrumbLink = styled(Link)`
  padding-left: ${leftGutter}px;
  margin: ${topMargin}px 0;
  display: block;
  text-decoration: none;
  color: #aaa;
`

export const BreadcrumbIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`

export const BreadcrumbText = styled.span``

export const Title = styled(EditableTitle)`
  margin-left: ${leftGutter}px;
  font-size: 36px;
  font-weight: bold;
`

export const SceneTitle = styled(EditableTitle)`
  display: block;
  margin-top: 20px;
  margin-left: ${leftGutter}px;
  font-size: 24px;
  font-weight: bold;
  color: #666;
`

export const SceneEditor = styled(Editor)`
  width: 70vw;
  margin: 10px 8vw 0 42px;
  border: 1px solid hsl(0, 0%, 90%);
  border-radius: 4px;
  height: 300px;
  [data-slate-editor] {
    height: 210px;
  }
`

export const FieldHeader = styled.div`
  margin: 20px 0 0 42px;
  font-size: 16px;
  font-weight: bold;
  color: #999;
`
