import { Brainstorm } from '../../types'

declare var LWK_API_URL: 'string'

export const deleteById = (token: string, brainstorm: Brainstorm) => {
  return fetch(`${LWK_API_URL}/brainstorms/id/${brainstorm.id}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    method: 'DELETE',
  })
}
