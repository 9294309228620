import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import EditableTitle from '../../secondary/EditableTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as snippets from '../../secondary/StyleKit/styleSnippets'
import { css } from '@emotion/react'

type StageLinkIconProps = {
  background: string
  color: string
}

export const StageLink = styled(Link)`
  box-sizing: border-box;
  display: grid;
  height: 200px;
  margin: 2px;
  padding: 5px;
  place-items: center;
  text-decoration: none;
  width: 100%;
`

type StagesSectionProps = {
  linkCount: string
}

export const ProjectNavPlaceholder = styled.section`
  ${snippets.navBar}
`

export const StagesSection = styled.section`
  ${snippets.fadeIn}
  display: grid;
  grid-template-columns: repeat(
    ${(props: StagesSectionProps) => props.linkCount},
    1fr
  );
  height: calc(100vh - 200px);
  margin-left: 5vw;
  margin-right: 5vw;
  place-items: center;
`

export const ProjectConfigSection = styled.section``

export const StageLinkIcon = styled.div`
  background: ${(props: StageLinkIconProps) => props.background};
  border-radius: 50%;
  color: ${(props: StageLinkIconProps) => props.color};
  display: grid;
  font-size: 80px;
  height: 150px;
  place-content: center;
  width: 150px;
`

export const StageLinkTitle = styled.h2`
  color: #727f86;
  font-size: 17px;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
`

export const Heading = styled.section`
  display: grid;
  grid-template-columns: auto 50px;
  margin: 40px 40px 0;
`

export const SettingsLink = styled(Link)`
  place-self: center;
`

export const SettingsButton = styled(FontAwesomeIcon)`
  color: #888;
  cursor: pointer;
  font-size: 33px;
  &:hover {
    color: #555;
  }
`

export const Title = styled(EditableTitle)`
  font-size: 36px;
  font-weight: bold;
  height: 50px;
`

export const SettingsForm = styled.form`
  background: hsl(0, 0%, 97%);
  border-radius: 4px;
  border: 1px solid #eee;
  grid-area: settings-form;
  overflow: hidden;
  height: fit-content;
  h2 {
    background: hsl(0, 0%, 35%);
    color: hsl(0, 0%, 100%);
    font-weight: normal;
    margin: 0;
    padding: 10px 20px;
  }
`

export const GoalsForm = styled(SettingsForm)`
  grid-area: goals-form;
`

export const ActionsForm = styled(SettingsForm)`
  grid-area: actions-form;
`

export const Setting = styled.section`
  padding: 20px;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`

export const DeleteSetting = styled(Setting)`
  display: grid;
  grid-gap: 0 10px;
  grid-template-columns: 1fr 2fr;
`

export const SettingLabel = styled.label`
  color: #555;
  display: block;
  font-weight: bold;
  padding: 0 0 5px 0;
`

export const SettingTextInput = styled.input`
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 8px;
  width: 100%;
`

export const SettingTextListInput = styled(SettingTextInput)`
  margin-bottom: 11px;
`

export const ConfirmationTextInput = styled(SettingTextInput)`
  border-width: 2px;
  ${(props: { engaged: boolean }) => css`
    border-color: ${props.engaged ? 'red' : '#ccc'};
  `}
`

export const ProjectConfigGrid = styled.section`
  ${snippets.fadeIn}
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'settings-form goals-form'
    'settings-form actions-form';
  grid-template-rows: 270px auto;
  grid-gap: 20px;
  margin: 20px 45px 60px;
`

export const FinePrint = styled.p`
  color: #aaa;
  font-style: italic;
`

export const DeleteButton = styled.button`
  border-radius: 4px;
  font-size: 18px;
  padding: 8px 14px;
  text-align: center;
  ${(props: { engaged: boolean }) => css`
    color: ${props.engaged ? 'white' : '#ddd'};
    background: ${props.engaged ? 'rgb(190, 60, 20)' : '#999'};
  `}
`

export const AddButton = styled.button`
  background: hsl(0, 0%, 88%);
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 84%);
  color: hsl(0, 0%, 36%);
  font-size: 18px;
  padding: 8px 14px;
  text-align: center;
  margin-top: 7px;
  &:hover {
    color: hsl(0, 0%, 22%);
    background: hsl(0, 0%, 99%);
    border: 1px solid hsl(0, 0%, 88%);
  }
`

export const AddButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 7px;
`
