import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  FormLink,
  BreadcrumbIcon,
  BreadcrumbText,
  FormContainerSection,
  Form,
  FormInput,
  FormSubmit,
  FormScreen,
  FormTitle,
  Postscript,
  TelegramStation,
  FormCenterIcon,
} from './styles'
import { LoginComponentProps, LoginComponentState } from '../types'
import { login, clearTelegramQueue } from '../actions'
import Copy from '../../../secondary/Copy/components/CopyComponent'
import * as selectors from '../selectors'
import { faArrowLeft } from 'Assets/fontawesome-pro-regular'
import validator from 'validator'
import * as t from '../text'
import { faDoorOpen } from 'Assets/fontawesome-pro-solid'

class Login extends React.Component<LoginComponentProps, LoginComponentState> {
  state = {
    email: '',
    password: '',
    validationMet: false,
  }

  componentWillUnmount = () => {
    this.props.clearTelegramQueue()
  }

  validate = () => {
    const { email, password } = this.state
    if (
      validator.isEmail(email) &&
      password.length >= 8 &&
      password.length <= 256
    ) {
      return this.setState({ validationMet: true })
    }
    return this.setState({ validationMet: false })
  }

  handleEmailInput = async (e: any) => {
    await this.setState({ email: e.target.value })
    this.validate()
  }

  handlePasswordInput = async (e: any) => {
    await this.setState({ password: e.target.value })
    this.validate()
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    if (!this.state.validationMet) {
      return
    }

    const { email, password } = this.state
    this.props.login({ email, password })
  }

  render = () => {
    if (this.props.isLoggedIn) {
      return <Redirect push to='/app/projects' />
    }
    if (this.props.isUserUnconfirmed) {
      return <Redirect push to='/account-confirmation-pending' />
    }
    return (
      <FormScreen>
        <FormContainerSection>
          <BreadcrumbText>
            <BreadcrumbIcon icon={faArrowLeft} />
            <FormLink to={`/`}>Return to the intro screen</FormLink>,{` `}
            or <FormLink to={`/signup`}>sign up</FormLink> instead
          </BreadcrumbText>
          <FormTitle>Log in to LitWitKit.</FormTitle>
          <FormCenterIcon icon={faDoorOpen} />
          <Form>
            <FormInput
              type='email'
              name='email'
              value={this.state.email}
              onChange={this.handleEmailInput}
              placeholder={
                t.login.emailPlaceholder[this.props.language] as string
              }
              autoComplete='email'
            />
            <FormInput
              type='password'
              name='password'
              value={this.state.password}
              onChange={this.handlePasswordInput}
              placeholder={
                t.login.passwordPlaceholder[this.props.language] as string
              }
              autoComplete='current-password'
            />
            <FormSubmit
              type='submit'
              onClick={this.handleSubmit}
              disabled={!this.state.validationMet}
              value={t.login.loginButton[this.props.language] as string}
            />
          </Form>
          <Postscript>
            Need to{' '}
            <FormLink to='/request-reset-password-email'>
              reset your password
            </FormLink>
            ?
          </Postscript>
            <TelegramStation nonempty={Boolean(this.props.telegrams.length)}>
              {this.props.telegrams.map((tele) => (
                <span key={tele.timestamp.toString()}>
                  {tele.copy ? <Copy text={tele.copy} /> : tele.text}
                </span>
              ))}
            </TelegramStation>
        </FormContainerSection>
      </FormScreen>
    )
  }
}

const mapStateToProps = (state: any) => ({
  token: selectors.token(state),
  isLoggedIn: selectors.isLoggedIn(state),
  telegrams: selectors.telegrams(state),
  language: selectors.language(state),
  isUserUnconfirmed: selectors.isUserUnconfirmed(state),
})

const mapDispatchToProps = { login, clearTelegramQueue }

export default connect(mapStateToProps, mapDispatchToProps)(Login)
