import * as React from 'react'
import styled from '@emotion/styled'
import * as copy from './text'
import Copy from '../../secondary/Copy/components/CopyComponent'
import * as snippets from '../../secondary/StyleKit/styleSnippets'

const HeaderSection = styled.section`
  background: #03dac6 url('/assets/booksonteal.png');
  padding: 20px;
  min-height: 400px;
  height: calc(100vh - 400px);
  max-height: 1200px;
  display: grid;
  place-items: center;
`

const HeaderContent = styled.section`
  text-align: center;
  display: grid;
  place-items: center;
  ${snippets.fadeIn}
`

const Headline = styled.h1`
  color: white;
  font-size: 64px;
`

const Definition = styled.p`
  color: white;
  font-size: 36px;
  min-width: 480px;
  width: 50vw;
`

export default () => (
  <HeaderSection>
    <HeaderContent>
      <Headline>
        <Copy text={copy.header.headline} />
      </Headline>
      <Definition>
        <Copy text={copy.header.definition} />
      </Definition>
    </HeaderContent>
  </HeaderSection>
)
