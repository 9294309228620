import * as React from 'react'
// import {
//   faCheckSquare,
//   faSquare,
//   faCaretUp,
// } from 'Assets/fontawesome-pro-light'
import {
  faCheckSquare,
  faSquare,
  faCaretUp,
} from 'Assets/fontawesome-pro-light'
import { faCaretUp as faCaretUpFill } from 'Assets/fontawesome-pro-solid'
import * as text from './text'
import Copy from '../../../../secondary/Copy/components/CopyComponent'
import {
  ListHeadingMenu,
  ListHeadingMenuItem,
  ChecklistItem,
  ChecklistCheckbox,
  ChecklistCheckboxVisible,
  ChecklistText,
  MenuCaretUpBorder,
  MenuCaretUpFill,
} from '../../../../secondary/ListGenerics/ListGenerics'
import { HeaderMenuSection } from './styles'

type Props = {
  handleShowArchived: () => void
  showArchived: boolean
  visible: boolean
}

export const HeaderMenu = ({
  handleShowArchived,
  showArchived,
  visible,
}: Props) => {
  return (
    <HeaderMenuSection visible={visible}>
      <MenuCaretUpBorder icon={faCaretUp} />
      <ListHeadingMenu id='listHeadingMenu'>
        <MenuCaretUpFill icon={faCaretUpFill} />
        <ListHeadingMenuItem onClick={handleShowArchived}>
          <ChecklistItem>
            <ChecklistCheckbox
              type='checkbox'
              disabled={false}
              checked={showArchived}
              onChange={handleShowArchived}
            />
            <ChecklistCheckboxVisible
              icon={showArchived ? faCheckSquare : faSquare}
            />
            <ChecklistText>
              <Copy text={text.showArchived} />
            </ChecklistText>
          </ChecklistItem>
        </ListHeadingMenuItem>
      </ListHeadingMenu>
    </HeaderMenuSection>
  )
}
