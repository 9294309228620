import * as requests from './requests/deleteById'
import { Brainstorm } from '../types'
import { deleteResource } from '../../../../integrations/litwitkit/shared'

export const deleteById = async (brainstorm: Brainstorm): Promise<void> => {
  const storageKey = `brainstorm-${brainstorm.id}`

  const request = (token: string) => {
    return requests.deleteById(token, brainstorm)
  }

  const transformData = (rawBrainstorm: any) => {
    return rawBrainstorm
  }

  const [status, resource] = await deleteResource(storageKey, request)
}
