import * as requests from './requests/updateById'
import { updateResource } from '../../../../integrations/litwitkit/shared'
import { Character } from '../types'

export const updateById = async (
  character: Character,
  projectId: string,
): Promise<void> => {
  const storageKey = `character-${character.id}`

  const request = (token: string) => {
    const {
      archived,
      biography,
      description,
      name,
      primaryRole,
      title,
    } = character

    return requests.updateById(token, character.id, {
      archived,
      biography,
      description,
      name,
      primaryRole,
      projectId,
      title,
    })
  }

  const transformData = (rawCharacter: Character) => {
    return rawCharacter
  }

  const resource = await updateResource(
    storageKey,
    character,
    request,
    transformData,
  )
}
