import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SelectSettingButtonProps } from './SettingList'
import * as snippets from '../../../../secondary/StyleKit/styleSnippets'
import { HeaderMenuSectionProps } from './types'

const basePadding = 15
const innerPadding = 45
const statusBarHeight = 30
const navBarHeight = 48

export const SettingListSection = styled.section`
  ${snippets.fadeIn}
  background: #fafafa;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  width: 20vw;
  min-width: 275px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  grid-area: settingList;
  z-index: 10;
  height: calc(100vh - ${statusBarHeight}px - ${navBarHeight}px);
  overflow: scroll;
`

export const CategorySection = styled.section``

export const CategoryTitle = styled.h1`
  font-size: 18px;
  font-weight: normal;
  padding-left: ${basePadding}px;
`

export const CategoryTitleIcon = styled(FontAwesomeIcon)`
  color: #c4c4c4;
  margin-right: 10px;
`

export const CategoryTitleText = styled.span``

export const SettingSection = styled.section``

export const SelectSettingButton = styled.button`
  width: 100%;
  box-sizing: border-box;
  padding: 10px 0 10px ${innerPadding}px;
  ${(props: SelectSettingButtonProps) =>
    props.isBeingDraggedOver &&
    css`
      padding-top: 32px;
    `}
  ${(props: SelectSettingButtonProps) =>
    props.currentsetting === 'true' &&
    css`
      background: rgba(196, 196, 196, 0.2);
      font-weight: bold;
    `}
    ${(props: SelectSettingButtonProps) => {
      return css`
        color: ${props.archived ? 'rgba(0,0,0,0.4);' : 'rgba(0,0,0,0.6);'};
        ${props.archived &&
        css`
          text-decoration: line-through;
        `}
      `
    }}
  &:focus {
    box-shadow: 0px 0px 3px 3px inset rgba(196, 196, 196, 0.2);
  }
`

export const NewSettingButton = styled.button`
  padding: 10px 0 10px ${innerPadding}px;
  color: #bbb;
`

export const HeaderMenuSection = styled.section`
  opacity: 0;
  z-index: -10;
  transition: all 0.2s ease-in;
  ${(props: HeaderMenuSectionProps) =>
    props.visible &&
    css`
      opacity: 1;
      z-index: 10;
    `}
`
