import { keyboardShortcutLegend } from "Constructs/secondary/Editor/Editor.constants"

export const currentlyArchived = {
  english: 'This setting is currently archived.',
}

export const deletePermanently = {
  english: 'Delete permanently.',
}

export const noItems = {
  english: `You don't have any settings yet. Create your first one!`,
}

export const help = {
  screenName: 'Settings',
  overview: [
    {
      content: {
        english: `A setting is more than a place; it's a nexus
        of place and time. London is a place. London in 1893 is a 
        setting.
        `,
      },
      title: { english: `The Context in Which Your Story Sits` },
    },
    {
      content: {
        english: `Organize your settings by dragging and dropping them between 
        the provided, pre-defined categories.`,
      },
      title: { english: 'Stay Organized' },
    },
    {
      content: {
        english: `You can archive your settings or delete them permanently,`,
      },
      title: { english: `Kill Your Darlings` },
    },
  ],
  keys: [keyboardShortcutLegend],
}