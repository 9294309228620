import * as React from 'react'
import styled from '@emotion/styled'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import * as CSS from 'csstype'
import { CopyText } from '../../secondary/Copy/types'
import Copy from '../../secondary/Copy/components/CopyComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css } from '@emotion/react'

type StripeBadgeProps = {
  icon: IconProp
  background: CSS.Property.Color
  copy: CopyText
}

type BadgeIconProps = {
  background: any
}

const BadgeSection = styled.section`
  display: grid;
  height: 250px;
  padding: 20px;
  place-items: center;
  grid-template-rows: 150px 50px;
`

const BadgeIconContainer = styled.div`
  ${(props: BadgeIconProps) => css`
    width: 120px;
    height: 120px;
    display: grid;
    place-items: center;
    box-sizing: border-box;
    border-radius: 40%;
    background: ${props.background};
  `}
`

const BadgeIcon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 70px;
`

const BadgeCopy = styled(Copy)`
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
`

export default ({ icon, background, copy }: StripeBadgeProps) => (
  <BadgeSection>
    <BadgeIconContainer background={background}>
      <BadgeIcon icon={icon} />
    </BadgeIconContainer>
    <BadgeCopy text={copy} isStyled={true} />
  </BadgeSection>
)
