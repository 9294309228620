import {
  faThunderstorm,
  faGlobeAsia,
  faSwords,
  faMask,
  faChess,
  faDungeon,
  faBalanceScaleLeft,
  faAtomAlt,
  faMapSigns,
} from 'Assets/fontawesome-pro-solid'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { BrainstormType } from './types'

export type Category = {
  title: string
  tag: BrainstormType
  icon?: IconProp
}

export const categories: Category[] = [
  { title: 'General', tag: 'general', icon: faThunderstorm },
  { title: 'Places', tag: 'places', icon: faGlobeAsia },
  { title: 'Conflicts', tag: 'conflicts', icon: faSwords },
  { title: 'Mysteries', tag: 'mysteries', icon: faMask },
  { title: 'Relationships', tag: 'relationships', icon: faChess },
  { title: 'Obstacles', tag: 'obstacles', icon: faDungeon },
  { title: 'Imbalances', tag: 'imbalances', icon: faBalanceScaleLeft },
  { title: 'Themes', tag: 'themes', icon: faAtomAlt },
  { title: 'Turning Points', tag: 'turning-points', icon: faMapSigns },
]
