import { Character } from '../types'
import * as H from 'history'
import { updateById } from '../resources/updateById'
import { create } from '../resources/create'
import { deleteById } from '../resources/deleteById'

export const handlers = (
  character: Character,
  characters: Character[],
  setCharacters: (characters: Character[]) => void,
  setCurrentCharacterId: (characterId: string) => void,
  projectId: string,
  history: H.History<H.LocationState>,
) => {
  const handleTrash = async () => {
    const updated = { ...character, archived: true }

    await setCurrentCharacterId(null)
    await updateById(updated, projectId)
    await setCharacters(
      characters.map((ch) => (character.id === ch.id ? updated : ch)),
    )

    history.push(`/app/project/${projectId}/characters`)
  }

  const handleNew = (primaryRole: string) => {
    const updateState = (ch: Character) => {
      setCharacters([...characters, ch])
      setCurrentCharacterId(ch.id)
      history.push(`/app/project/${projectId}/characters/${ch.id}`)
    }
    create({ primaryRole, projectId }, updateState)
  }

  const handleSetCurrent = (ch: Character) => {
    if (ch) {
      setCurrentCharacterId(ch.id)
      history.push(`/app/project/${projectId}/characters/${ch.id}`)
    } else {
      setCurrentCharacterId(null)
    }
  }

  const handleUpdate = async (character: Character) => {
    await updateById(character, projectId)

    setCharacters(
      characters.map((ch) => (character.id === ch.id ? character : ch)),
    )
    setCurrentCharacterId(character.id)
  }

  const handleDelete = () => {
    deleteById(character)
    setCharacters(characters.filter((ch) => ch.id !== character.id))
    const firstCharacter = characters.sort((a, b) =>
      a.primaryRole === '' ? -1 : 1,
    )[0]
    setCurrentCharacterId(firstCharacter ? firstCharacter.id : null)
  }

  return {
    handleTrash,
    handleNew,
    handleSetCurrent,
    handleUpdate,
    handleDelete,
  }
}
