import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as text from './text'
import {
  faPlusCircle,
  faStickyNote,
  faTrashRestore,
  faBars,
  faEllipsisV,
  faEllipsisVAlt,
  faTrashAlt,
} from 'Assets/fontawesome-pro-light'
import {
  CategorySection,
  CategoryTitle,
  CategoryTitleIcon,
  CategoryTitleText,
  NewBrainstormButton,
  BrainstormListSection,
  BrainstormSection,
  SelectBrainstormButton,
  SelectBrainstormButtonIcon,
} from './styles'
import * as t from './types'
import {
  ListDescription,
  ListTitle,
  ListHeader,
  ListHeadingMenuTrigger,
} from '../../../../secondary/ListGenerics/ListGenerics'
import Copy from '../../../../secondary/Copy/components/CopyComponent'
import { Brainstorm, BrainstormType } from '../../types'
import { HeaderMenu } from './HeaderMenu'
import { Category } from '../../constants'

type Props = {
  brainstorms: Brainstorm[]
  categories?: Category[]
  currentBrainstorm?: Brainstorm
  handleNewBrainstorm: (brainstormType: BrainstormType) => void
  setCurrentBrainstorm: (brainstorm: Brainstorm) => void
  setShowHeaderMenu: (showHeaderMenu: boolean) => void
  showHeaderMenu: boolean
  updateBrainstormAction: (brainstorm: Brainstorm) => void
  updateBrainstormListAction: (brainstorms: Brainstorm[]) => void
}

export const BrainstormList = ({
  categories,
  currentBrainstorm,
  brainstorms,
  handleNewBrainstorm,
  setCurrentBrainstorm,
  setShowHeaderMenu,
  showHeaderMenu,
  updateBrainstormAction,
  updateBrainstormListAction,
}: Props) => {
  const [dragOverBrainstormId, setDragOverBrainstormId] = React.useState(null)
  const [draggingBrainstormId, setDraggingBrainstormId] = React.useState(null)
  const [showArchived, setShowArchived] = React.useState(false)

  React.useEffect(() => {
    if (currentBrainstorm && currentBrainstorm.archived) {
      setShowArchived(true)
    }
  }, [currentBrainstorm && currentBrainstorm.archived])

  // Apply filters to the list, e.g. whether or not we're showing
  // archived items
  const visibleBrainstorms = brainstorms.filter((brainstorm) => {
    if (showArchived) {
      return true
    } else {
      return !brainstorm.archived
    }
  })

  const handleBrainstormClick = (event: React.MouseEvent) => {
    const currentBrainstormId = (event.target as HTMLElement).dataset
      .brainstormId
    const currentBrainstorm = visibleBrainstorms.find(
      (n) => n.id === currentBrainstormId,
    )
    setCurrentBrainstorm(currentBrainstorm)
  }

  const getBrainstormFromId = (id: string) =>
    visibleBrainstorms.find((brainstorm) => brainstorm.id === id)

  const handleDragStart = (event: React.DragEvent) => {
    setDraggingBrainstormId((event.target as HTMLElement).id)
  }

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault()
    const draggedOverId = (event.target as HTMLElement).dataset.brainstormId
    setDragOverBrainstormId(draggedOverId)
  }

  const handleDrop = (event: React.DragEvent, newTag: string) => {
    setDragOverBrainstormId(null)
    setDraggingBrainstormId(null)
    if (
      !dragOverBrainstormId ||
      draggingBrainstormId === dragOverBrainstormId
    ) {
      return
    }
    const droppedOverBrainstormId = (event.target as HTMLElement).dataset
      .characterId
    const draggingBrainstorm = getBrainstormFromId(draggingBrainstormId)
    updateBrainstormAfterDrop(draggingBrainstorm, newTag)
    sortBrainstormsAfterDrop(draggingBrainstorm, droppedOverBrainstormId)
  }

  const updateBrainstormAfterDrop = (
    draggingBrainstorm: Brainstorm,
    newTag: string,
  ) => {
    const updatedBrainstorm = { ...draggingBrainstorm, tags: [newTag] }
    updateBrainstormAction(updatedBrainstorm)
  }

  const sortBrainstormsAfterDrop = (
    draggingBrainstorm: Brainstorm,
    droppedOverBrainstormId: string,
  ) => {
    const filteredBrainstorms = visibleBrainstorms.filter(
      (n) => n.id !== draggingBrainstormId,
    )
    const droppedBrainstormIndex =
      droppedOverBrainstormId === 'tailBufferSpace'
        ? filteredBrainstorms.length
        : Math.max(
            0,
            filteredBrainstorms.findIndex(
              (n) => n.id === droppedOverBrainstormId,
            ),
          )
    const newBrainstorms = [
      ...filteredBrainstorms.slice(0, droppedBrainstormIndex),
      draggingBrainstorm,
      ...filteredBrainstorms.slice(
        droppedBrainstormIndex,
        filteredBrainstorms.length,
      ),
    ]
    updateBrainstormListAction(newBrainstorms)
  }

  const handleShowArchived = () => {
    setShowArchived(!showArchived)
    if (currentBrainstorm && currentBrainstorm.archived) {
      const firstUnarchivedBrainstorm = brainstorms.find(
        (bs) => !bs.archived && bs.brainstormType === 'general',
      )
      setCurrentBrainstorm(firstUnarchivedBrainstorm)
    }
  }

  const renderCategory = (
    category: t.Category,
    visibleBrainstorms: Brainstorm[],
  ) => (
    <CategorySection
      key={`category-${category.tag}`}
      onDragOver={handleDragOver}
      onDrop={(ev) => handleDrop(ev, category.tag)}
    >
      <CategoryTitle>
        <CategoryTitleIcon icon={category.icon} />
        <CategoryTitleText
          data-brainstorm-id='headBufferSpace'
          data-category={category.tag}
        >
          {category.title}
        </CategoryTitleText>
      </CategoryTitle>
      {visibleBrainstorms.map((brainstorm) => (
        <BrainstormSection
          key={brainstorm.id}
          id={brainstorm.id}
          draggable
          onDragStart={handleDragStart}
        >
          <SelectBrainstormButton
            currentbrainstorm={
              currentBrainstorm && currentBrainstorm.id === brainstorm.id
                ? 'true'
                : 'false'
            }
            archived={brainstorm.archived}
            data-brainstorm-id={brainstorm.id}
            data-category={category.tag}
            onClick={handleBrainstormClick}
            isBeingDraggedOver={
              brainstorm.id === dragOverBrainstormId &&
              brainstorm.id !== draggingBrainstormId
            }
          >
            {brainstorm.title || <Copy text={text.placeholderTitle} />}
          </SelectBrainstormButton>
        </BrainstormSection>
      ))}
      <NewBrainstormButton
        data-brainstorm-id='tailBufferSpace'
        data-category={category.tag}
        onClick={() => handleNewBrainstorm(category.tag)}
      >
        <FontAwesomeIcon icon={faPlusCircle} /> Add brainstorm
      </NewBrainstormButton>
    </CategorySection>
  )

  const renderCategories = (
    categories: t.Category[],
    visibleBrainstorms: Brainstorm[],
  ) =>
    categories.map((category) => {
      const categoryBrainstorms = visibleBrainstorms.filter(
        (n) => n.brainstormType === category.tag,
      )
      return renderCategory(category, categoryBrainstorms)
    })

  return (
    <BrainstormListSection>
      <ListHeader>
        <ListTitle>
          <Copy text={text.title} />
          <ListHeadingMenuTrigger
            onClick={() => setShowHeaderMenu(!showHeaderMenu)}
          >
            <FontAwesomeIcon icon={faEllipsisVAlt} />
          </ListHeadingMenuTrigger>
        </ListTitle>
        <HeaderMenu
          handleShowArchived={handleShowArchived}
          showArchived={showArchived}
          visible={showHeaderMenu}
        />
      </ListHeader>
      <ListDescription>
        <Copy text={text.description} />
      </ListDescription>
      {renderCategories(categories, visibleBrainstorms)}
    </BrainstormListSection>
  )
}
