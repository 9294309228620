import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditableTitle from '../EditableTitle'
import * as snippets from '../StyleKit/styleSnippets'
import { css } from '@emotion/react'

export const Header = styled.section`
  label: Header;
  background: #f5f5f5;
  border-radius: 3px;
  border: 1px solid #eaeaea;
  color: #aaa;
  margin: 0 40px;
  padding: 14px 20px;
`

const linkColor = `hsl(234, 91%, 74%)`
const linkColorFocus = `hsl(234, 80%, 62%)`

export const InlineTextButton = styled.span`
  color: ${linkColor};
  &:hover {
    color: ${linkColorFocus};
    cursor: pointer;
  }
`
