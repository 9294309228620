import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faDice,
  faEllipsisVAlt,
} from 'Assets/fontawesome-pro-light'
import {
  faRandom,
  faPageBreak,
  faLayerGroup,
  faAlignLeft,
  faTrash,
} from 'Assets/fontawesome-pro-solid'
import {
  ButtonSection,
  ListBuffer,
  NewSegmentButton,
  StagedSection,
  SectionTitle,
  SectionTitleIcon,
  SectionTitleText,
  SegmentButtonIcon,
  SegmentListSection,
  SelectSegmentButton,
  UnstagedScenesSection,
} from './styles'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  Segment,
  SegmentPlacement,
  SegmentType,
  SegmentPlace,
  Outline,
} from '../../types'
import { dragDropHandlers } from './SegmentList.handlers.dragdrop'
import {
  ListHeader,
  ListTitle,
  ListHeadingMenuTrigger,
  ListDescription,
} from '../../../../secondary/ListGenerics/ListGenerics'
import { HeaderMenu } from '../../../Brainstorms/components/BrainstormsList/HeaderMenu'
import Copy from '../../../../secondary/Copy/components/CopyComponent'
import * as text from './text'

export type Props = {
  createSegment: (type: SegmentType) => void
  currentSegmentId: string
  outline: Outline
  segments: Segment[]
  setCurrentSegmentId: (id: string) => void
  setOutline: (outline: Outline) => void
  setSegments: (segments: Segment[]) => void
  setShowHeaderMenu: (showHeaderMenu: boolean) => void
  showArchived: boolean
  setShowArchived: (showArchived: boolean) => void
  showHeaderMenu: boolean
}

export const SegmentList = ({
  currentSegmentId,
  segments,
  outline,
  createSegment,
  setCurrentSegmentId,
  setSegments,
  showArchived,
  setShowArchived,
  setOutline,
  setShowHeaderMenu,
  showHeaderMenu,
}: Props) => {
  const [dragOverSegmentId, setDragOverSegmentId] = React.useState(
    null as string,
  )
  const [draggingSegmentId, setDraggingSegmentId] = React.useState(
    null as string,
  )

  let stagedSegments = [] as Segment[]
  if (outline.stagedSegmentIds) {
    stagedSegments = outline.stagedSegmentIds
      .map((sid) => segments.find((seg) => seg.id === sid))
      .filter(Boolean)
      .filter((seg) => {
        if (showArchived) {
          return true
        } else {
          return !seg.archived
        }
      })
  }

  const { handleDragOver, handleDragStart, handleDrop } = dragDropHandlers(
    setDraggingSegmentId,
    setDragOverSegmentId,
    draggingSegmentId,
    dragOverSegmentId,
    outline,
    setOutline,
  )

  const handleSegmentClick = (event: React.MouseEvent) => {
    const currentSegmentId = (event.target as HTMLElement).dataset.segmentId
    setCurrentSegmentId(currentSegmentId)
  }

  const handleShowArchived = () => {
    setShowArchived(!showArchived)
    if (currentSegmentId) {
      const currentSegment = segments.find((seg) => seg.id === currentSegmentId)
      if (currentSegment.archived) {
        const firstUnarchivedSegment = outline.stagedSegmentIds
          .map((sid) => segments.find((seg) => seg.id === sid))
          .find((seg) => !seg.archived)
        if (firstUnarchivedSegment) {
          setCurrentSegmentId(firstUnarchivedSegment.id)
        } else {
          setCurrentSegmentId(null)
        }
      }
    }
  }

  const segmentIcon = (type: SegmentType): IconProp => {
    switch (type) {
      case 'divider':
        return faPageBreak
      default:
        return faAlignLeft
    }
  }

  const segmentTitle = (segment: Segment): string => {
    if (segment.title) {
      return segment.title
    }
    switch (segment.type) {
      case 'divider':
        return 'Untitled Chapter'
      default:
        return 'Untitled Scene'
    }
  }

  const visibleSegmentTypes = ['content', 'divider']

  const renderSegments = (segmentsList: Segment[], section: SegmentPlace) => {
    return segmentsList.map(
      (segment) =>
        visibleSegmentTypes.includes(segment.type) && (
          <div
            key={segment.id}
            id={segment.id}
            draggable
            onDragStart={handleDragStart}
          >
            <SelectSegmentButton
              archived={segment.archived}
              currentsegment={currentSegmentId === segment.id}
              segmentType={segment.type}
              data-segment-id={segment.id}
              data-section={section}
              onClick={handleSegmentClick}
              isBeingDraggedOver={
                draggingSegmentId &&
                dragOverSegmentId &&
                segment.id === dragOverSegmentId &&
                segment.id !== draggingSegmentId
              }
              isBeingDragged={
                draggingSegmentId && segment.id === draggingSegmentId
              }
            >
              <SegmentButtonIcon icon={segmentIcon(segment.type)} />{' '}
              {segmentTitle(segment)}
            </SelectSegmentButton>
          </div>
        ),
    )
  }

  return (
    <SegmentListSection>
      <ListHeader>
        <ListTitle>
          <Copy text={text.title} />
          <ListHeadingMenuTrigger
            onClick={() => setShowHeaderMenu(!showHeaderMenu)}
          >
            <FontAwesomeIcon icon={faEllipsisVAlt} />
          </ListHeadingMenuTrigger>
        </ListTitle>
        <HeaderMenu
          handleShowArchived={handleShowArchived}
          showArchived={showArchived}
          visible={showHeaderMenu}
        />
      </ListHeader>
      <ListDescription>
        <Copy text={text.description} />
      </ListDescription>

      <StagedSection onDragOver={handleDragOver} onDrop={handleDrop('staged')}>
        <SectionTitle>
          <SectionTitleIcon icon={faLayerGroup} />
          <SectionTitleText
            data-segment-id='headBufferSpace'
            data-section='staged'
          >
            {outline.title || 'Outline'}
          </SectionTitleText>
        </SectionTitle>
        {renderSegments(stagedSegments, 'staged')}
        <ListBuffer data-segment-id='tailBufferSpace' data-section='staged' />
      </StagedSection>
      <ButtonSection>
        <NewSegmentButton onClick={(ev) => createSegment('content')}>
          <FontAwesomeIcon icon={faPlusCircle} /> New scene
        </NewSegmentButton>
        <NewSegmentButton onClick={(ev) => createSegment('divider')}>
          <FontAwesomeIcon icon={faPlusCircle} /> New chapter
        </NewSegmentButton>
      </ButtonSection>
    </SegmentListSection>
  )
}
