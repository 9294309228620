import { Beat, Plotmap, BeatPlacement } from '../../types'
import {
  updatePlotmap,
  updateBeat,
  updateBeatPlacement,
} from '../../resources/update'
import { DraggingBeatPlacement } from './PlotmapEditor'
import { createBeat } from '../../resources/create'
import { deleteBeatFromPlotmap } from '../../resources/delete'

const calculateXFromEvent = (e: React.SyntheticEvent) => {
  const activePlotmapElement: HTMLElement = document.querySelector(
    '[data-active-plotmap="true"]',
  )

  const currentPos = (e.nativeEvent as MouseEvent).clientX
  const width = activePlotmapElement.offsetWidth
  const leftOffset = activePlotmapElement.offsetLeft

  const percentage = Math.min(1, Math.max(0, (currentPos - leftOffset) / width))
  const newX = Math.round(percentage * 10000)

  return newX
}

const calculateYFromEvent = (e: React.SyntheticEvent) => {
  const activePlotmapElement: HTMLElement = document.querySelector(
    '[data-active-plotmap="true"]',
  )

  const target = e.target as HTMLElement

  let y
  if (target.hasAttribute('y')) {
    y = target.getAttribute('y')
  } else {
    const mouseY = (e.nativeEvent as MouseEvent).clientY
    const sortedByProximity = ([...target.children] as HTMLElement[])
      .filter((c) => c.hasAttribute('y'))
      .sort(
        (a, b) =>
          Math.abs(mouseY - a.offsetTop) - Math.abs(mouseY - b.offsetTop),
      )
    y = sortedByProximity[0].getAttribute('y')
  }

  return parseInt(y)
}

export const mouseMoveHandler = (
  draggingBeat: DraggingBeatPlacement,
  setDraggingBeat: React.Dispatch<React.SetStateAction<DraggingBeatPlacement>>,
) => (e: React.SyntheticEvent) => {
  if (draggingBeat) {
    const newX = calculateXFromEvent(e)
    const newY = calculateYFromEvent(e)
    setDraggingBeat({ ...draggingBeat, newX, newY })
  }
}

/**
 * If a user has clicked on a beat, set that beat as the current beat, which will
 * highlight it in the UI and make it available for editing in the beat editor.
 * Also, until the mouse up event, allow the beat to be dragged.
 * @param beatPlacements A list of beats specific to the currently selected plotmap
 * @param setDraggingBeat Activate a beat to be dragged along the plotmap
 * @param setCurrentBeat Select a beat for editing
 */
export const mouseDownHandler = (
  beatPlacements: BeatPlacement[],
  setDraggingBeat: React.Dispatch<React.SetStateAction<DraggingBeatPlacement>>,
  setCurrentBeatId: React.Dispatch<React.SetStateAction<string>>,
) => (e: React.SyntheticEvent) => {
  const target = event.target as HTMLElement
  if (!target.dataset.beatId) {
    return
  }
  const beatId = target.dataset.beatId
  const beatPlacement = beatPlacements.find(
    (beatPlacement) => beatPlacement.beatId === beatId,
  )
  setDraggingBeat({ ...beatPlacement, newX: null, newY: null, newZ: null })
  setCurrentBeatId(beatPlacement.beatId)
}

/**
 * If a user releases a dragging beat, update its placement both in the remote DB
 * and in the UI. If a user is simply clicking, defer to a click handler.
 * Click handlers:
 * * a click on a beat (selects the beat)
 * * a ctrl+shift+click on a beat (deletes the beat)
 * * a ctrl+click on the line (creates a new beat)
 * @param draggingBeat
 * @param plotmapId
 * @param beats
 * @param beatPlacements
 * @param setDraggingBeat
 * @param setBeats
 * @param setBeatPlacements
 * @param setCurrentBeatId
 */
export const mouseUpHandler = (
  draggingBeat: DraggingBeatPlacement,
  plotmapId: string,
  beats: Beat[],
  beatPlacements: BeatPlacement[],
  setDraggingBeat: React.Dispatch<React.SetStateAction<DraggingBeatPlacement>>,
  setBeats: React.Dispatch<React.SetStateAction<Beat[]>>,
  setBeatPlacements: React.Dispatch<React.SetStateAction<BeatPlacement[]>>,
  setCurrentBeatId: React.Dispatch<React.SetStateAction<any>>,
) => async (e: React.SyntheticEvent) => {
  const nev = e.nativeEvent as MouseEvent
  if (draggingBeat) {
    if (draggingBeat.newX || draggingBeat.newY) {
      const newBeatPlacement = {
        ...draggingBeat,
        placementX: draggingBeat.newX,
        placementY: draggingBeat.newY,
        placementZ: draggingBeat.newZ,
      }
      updateBeatPlacement(newBeatPlacement)
    } else {
      if ((nev.ctrlKey || nev.metaKey) && nev.shiftKey) {
        // if a beat was ctrl+shift clicked and not dragged, delete it
        deleteBeatFromPlotmap(plotmapId, draggingBeat.beatId)
        setCurrentBeatId(null)
        setBeatPlacements(
          beatPlacements.filter((bp) => bp.beatId !== draggingBeat.beatId),
        )
      } else {
        // if a beat was clicked but not dragged, select it
        setCurrentBeatId(draggingBeat.beatId)
      }
    }
    setDraggingBeat(null)
  } else {
    if (nev.ctrlKey || (nev.metaKey && !nev.shiftKey)) {
      // if the line was ctrl+clicked, create a beat there
      const x = calculateXFromEvent(e)
      const y = calculateYFromEvent(e)
      const newBeatPlacement = {
        placementX: x,
        placementY: y,
        placementZ: 0,
      }
      await createBeat(newBeatPlacement, plotmapId, (newBeat: Beat) => {
        // set beats
        setBeats([...beats, newBeat])
        // set beatplacements
        setBeatPlacements([
          ...beatPlacements,
          {
            beatId: newBeat.id,
            plotmapId: plotmapId,
            ...newBeatPlacement,
          },
        ])
        setCurrentBeatId(newBeat.id)
      })
    }
  }
}
