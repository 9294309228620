import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as snippets from '../../../../secondary/StyleKit/styleSnippets'
import { SelectPlotmapButtonProps } from './PlotmapList'

const basePadding = 15
const innerPadding = 45
const statusBarHeight = 30
const navBarHeight = 48

export const PlotmapListSection = styled.section`
  ${snippets.fadeIn}
  background: #fafafa;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  width: 20vw;
  min-width: 275px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  grid-area: plotmapList;
  height: calc(100vh - ${statusBarHeight}px - ${navBarHeight}px);
  z-index: 10;
`

export const UnsortedScenesSection = styled.section``

export const OutlineSection = styled.section``

export const SectionTitle = styled.h1`
  font-size: 18px;
  font-weight: normal;
  padding-left: ${basePadding}px;
`

export const SectionTitleIcon = styled(FontAwesomeIcon)`
  color: #c4c4c4;
  margin-right: 10px;
`

export const SectionTitleText = styled.span``

export const PlotmapSection = styled.section``

export const ButtonSection = styled.section``

export const PlotmapButtonIcon = styled(FontAwesomeIcon)`
  min-width: 25px;
  margin-right: 5px;
`

export const SelectPlotmapButton = styled.button`
  width: 100%;
  box-sizing: border-box;
  border-left: 5px solid transparent;
  padding: 10px 0 10px ${innerPadding}px;
  ${(props: SelectPlotmapButtonProps) =>
    props.isBeingDraggedOver &&
    css`
      padding-top: 32px;
    `}
  ${(props: SelectPlotmapButtonProps) =>
    props.currentplotmap === true &&
    css`
      background: rgba(196, 196, 196, 0.2);
      font-weight: bold;
    `}
  ${(props: SelectPlotmapButtonProps) =>
    props.isBeingDragged &&
    css`
      /* position: absolute; */
    `}
  &:focus {
    border-left: 5px solid #ccc;
  }
`

export const NewPlotmapButton = styled.button`
  padding: 10px 0 10px ${innerPadding}px;
  color: #bbb;
`

export const ListBuffer = styled.div`
  height: 50px;
`
