import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import styled from '@emotion/styled'

type Props = {
  icon: IconProp
  onClick: () => void
  isActive?: boolean
  title?: string
}

const ToolbarButtonStyled = styled.button`
  padding: 0.4rem 0.56rem;
  border: none;
  color: ${(props: { isactive: boolean }) =>
    props.isactive ? `hsl(0, 0%, 8%)` : `hsl(0, 0%, 60%)`};
  margin: 0 0.5rem;
  border-radius: 3px;
  font-size: 0.85rem;
`

export const ToolbarButton = ({
  icon,
  onClick,
  isActive = false,
  title,
}: Props) => {
  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault()
    onClick()
  }

  return (
    <ToolbarButtonStyled
      isactive={isActive}
      onMouseDown={onMouseDown}
      title={title}
    >
      <FontAwesomeIcon icon={icon} />
    </ToolbarButtonStyled>
  )
}
