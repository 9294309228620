import * as React from 'react'
import { faArrowLeft } from 'Assets/fontawesome-pro-solid'
import { Segment } from '../../types'
import { updateSegment } from '../../resources/update'
import { useParams } from 'react-router-dom'
import {
  BreadcrumbLink,
  BreadcrumbIcon,
  BreadcrumbText,
  Title,
} from 'Constructs/secondary/BasicEditor/styles'
import { DividerEditorSection, FieldHeader, SceneEditor } from './styles'

type Props = {
  currentSegment: Segment
  displaystyle?: string
  handleTrash: () => void
  readOnly?: boolean
  showToolbar?: boolean
  setSegments: (segments: Segment[]) => void
  segments: Segment[]
}

const debounceTime = 1000
const throttleTime = 1000
const maxSaveGap = 6000

type Params = {
  projectId: string
}

export const DividerEditor = ({
  currentSegment,
  displaystyle,
  handleTrash,
  readOnly = false,
  showToolbar,
  setSegments,
  segments,
}: Props) => {
  const { projectId } = useParams<Params>()

  const projectTitle = localStorage.getItem(`project-${projectId}-title`)

  const [title, setTitle] = React.useState(currentSegment.title)
  const [content, setContent] = React.useState(currentSegment.content)
  const [lastEditorChange, setLastEditorChange] = React.useState(Date.now())
  const [lastEditorSave, setLastEditorSave] = React.useState(Date.now())
  const [editorTimeout, setEditorTimeout] = React.useState(null)

  const cleanup: any = React.useRef()

  React.useEffect(() => {
    cleanup.current = {
      lastEditorChange,
      lastEditorSave,
      content,
      currentSegment,
      title,
    }
  }, [lastEditorChange, lastEditorSave, content, currentSegment, title])

  React.useEffect(() => {
    return () => {
      const current = cleanup.current
      if (current.lastEditorChange > current.lastEditorSave) {
        clearTimeout(editorTimeout)
        updateSegment({
          ...current.currentSegment,
          content: current.content,
          title: current.title,
        })
      }
    }
  }, [])

  const onEditorChange = (newContent: string) => {
    setContent(newContent)
    const now = Date.now()
    const timeSinceLastChange = now - lastEditorChange
    const timeSinceLastSave = now - lastEditorSave

    if (
      timeSinceLastSave > maxSaveGap ||
      (timeSinceLastChange > throttleTime && timeSinceLastSave > debounceTime)
    ) {
      clearTimeout(editorTimeout)
      updateSegment({ ...currentSegment, content: newContent, title })
      setLastEditorSave(now)
    } else {
      clearTimeout(editorTimeout)
      setEditorTimeout(
        setTimeout(() => onEditorChange(newContent), throttleTime + 1),
      )
    }
    setLastEditorChange(now)
  }

  const onTitleChange = (title: string) => {
    setTitle(title)
    const updatedSegment = { ...currentSegment, title }
    updateSegment(updatedSegment)
    setSegments(
      segments.map((seg) =>
        seg.id === currentSegment.id ? updatedSegment : seg,
      ),
    )
  }

  return (
    <DividerEditorSection>
      {projectId && (
        <BreadcrumbLink to={`/app/project/${projectId}`}>
          <BreadcrumbIcon icon={faArrowLeft} />
          <BreadcrumbText>Back to Project Stages</BreadcrumbText>
        </BreadcrumbLink>
      )}
      <Title
        placeholder='Untitled'
        value={title}
        disabled={readOnly}
        onChange={onTitleChange}
      />

      <FieldHeader>Chapter Notes:</FieldHeader>
      <SceneEditor
        onChange={onEditorChange}
        placeholder={readOnly ? '' : 'Start writing'}
        initialValue={currentSegment.content}
        handleTrash={handleTrash}
        readOnly={readOnly}
        showToolbar={!readOnly}
      />
    </DividerEditorSection>
  )
}
