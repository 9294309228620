import * as React from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import Copy from 'Constructs/secondary/Copy/components/CopyComponent'
import * as text from '../text'
import * as telegram from 'Constructs/secondary/Telegram/actions'
import {
  ActionLink,
  BreadcrumbIcon,
  BreadcrumbText,
  Form,
  FormCenterIcon,
  FormContainerSection,
  FormLink,
  FormScreen,
  FormSubmit,
  FormTitle,
  TelegramStation,
} from './styles'
import { faArrowLeft } from 'Assets/fontawesome-pro-light'
import { faEnvelopeOpenText } from 'Assets/fontawesome-pro-solid'
import { resendConfirmationEmail } from '../resources/requests/resendConfirmationEmail'
import { Signal } from 'Constructs/secondary/Telegram/types'

const AccounConfirmationPending = () => {
  const email = localStorage.getItem('current-user-email')
  const token = localStorage.getItem('token')

  const history = useHistory()

  const [telegrams, setTelegrams] = React.useState([] as Signal[])
  if (!email || !token) {
    return <Redirect push to='/login' />
  }
  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const sendResult = await resendConfirmationEmail(token)
    if (sendResult) {
      setTelegrams([
        telegram.createSignal({
          type: 'success',
          copy: text.resetPasswordEmailSent.details,
        }),
      ])
    } else {
      setTelegrams([
        telegram.createSignal({
          type: 'error',
          copy: text.errors['UNKNOWN'],
          momentary: true,
        }),
      ])
    }
  }

  // if (isLoggedIn) {
  //     return <Redirect push to='/app/projects' />
  // }
  return (
    <FormScreen>
      <FormContainerSection>
        <BreadcrumbText>
          <BreadcrumbIcon icon={faArrowLeft} />
          <FormLink to={`/`}>Return to the intro screen</FormLink>
        </BreadcrumbText>
        <FormTitle>
          <Copy text={text.confirmYourAccount.heading} />
        </FormTitle>
        <Form>
          <FormCenterIcon icon={faEnvelopeOpenText} />
          <p>
            <Copy text={text.confirmYourAccount.explanation} />
          </p>
          <FormSubmit
            type='submit'
            value={text.confirmYourAccount.submitButton['english'] as string}
            disabled={false}
            onClick={handleSubmit}
          />
          <hr />
          <ActionLink to='/login'>
            {text.confirmYourAccount.login['english'] as string}
          </ActionLink>
        </Form>
        {
          <TelegramStation nonempty={Boolean(telegrams.length)}>
            {telegrams.map((t) => (
              <span key={t.timestamp.toString()}>
                {t.copy ? <Copy text={t.copy} /> : t.text}
              </span>
            ))}
          </TelegramStation>
        }
      </FormContainerSection>
    </FormScreen>
  )
}

export default AccounConfirmationPending
