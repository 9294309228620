import * as React from 'react'
import { LocalStorageKey } from './localStorageSchema'

const fps = 12
const msFreq = 1000 / fps

type LocalStateTransformer = {
  app: (storageVal: string, zeroVal: any) => any
  storage: (appVal: any, zeroVal: any) => string
}

export const useLocalState = (
  key: LocalStorageKey,
  zeroVal: any,
  transformer: LocalStateTransformer = stringTransformer,
) => {
  const rawVal = localStorage.getItem(key) || zeroVal

  const [val, setVal] = React.useState(transformer.app(rawVal, zeroVal))

  React.useEffect(() => {
    localStorage.setItem(key, transformer.storage(val, zeroVal))
    const intervalID = setInterval(() => {
      const storedStringVal = localStorage.getItem(key) || zeroVal
      const stringVal = transformer.storage(val, zeroVal)
      if (storedStringVal !== stringVal) {
        const storedVal = transformer.app(localStorage.getItem(key), zeroVal)
        setVal(storedVal)
      }
    }, msFreq)
    return () => {
      clearInterval(intervalID)
    }
  }, [key, val])

  return [val, setVal]
}

export const jsonTransformer = {
  app: (storageVal: string, zeroVal: any): any => {
    if (storageVal === null || storageVal === undefined) {
      return zeroVal
    }
    try {
      return JSON.parse(storageVal)
    } catch (e) {
      return zeroVal
    }
  },
  storage: (appVal: any, zeroVal: any): string => {
    if (appVal === null || appVal === undefined) {
      return JSON.stringify(zeroVal)
    } else {
      return JSON.stringify(appVal)
    }
  },
}

export const stringTransformer = {
  app: (storageVal: string) => storageVal,
  storage: (appVal: any) => String(appVal),
}

export const booleanTransformer = {
  app: (storageVal: string) => storageVal === 'true',
  storage: (appVal: any) => String(appVal),
}
