import {
  faBooks,
  faRoute,
  faBolt,
  faAcorn,
  faSeedling,
  faUserAstronaut,
  faGopuram,
  faCodeBranch,
  faShoePrints,
  faBroom,
  faRocket,
  faUserCircle,
  faHiking,
  faTrashRestore,
} from 'Assets/fontawesome-pro-solid'
import { Nav, NavTile } from './projectNavTypes'

export const navItems: Nav = [
  {
    description: 'Go to all projects',
    icon: faBooks,
    navInterface: 'Projects',
    path: () => `/app/projects/`,
    shouldShow: () => true,
  },
  {
    description: 'Go to the list of workflow stages for this project',
    icon: faRoute,
    navInterface: 'Stages',
    path: (tokens) => `/app/project/${tokens.projectId}`,
    shouldShow: (currentNavInterface) => currentNavInterface !== 'Projects',
  },
  [
    {
      description: 'Go to the brain storming stage for this project',
      icon: faBolt,
      navInterface: 'Brainstorm',
      path: (tokens) => `/app/project/${tokens.projectId}/brainstorms`,
      shouldShow: (currentNavInterface) =>
        !['Projects', 'Stages', 'Config'].includes(currentNavInterface),
    },
    // {
    //   description: 'Edit the premise for this project',
    //   icon: faAcorn,
    //   navInterface: 'Premise',
    //   path: tokens => `/app/project/${tokens.projectId}/premise`,
    //   shouldShow: currentNavInterface =>
    //     !['Projects', 'Stages', 'Config'].includes(currentNavInterface),
    // },
    {
      description: 'Edit the synopsis for this project',
      icon: faSeedling,
      navInterface: 'Synopsis',
      path: (tokens) => `/app/project/${tokens.projectId}/synopsis`,
      shouldShow: (currentNavInterface) =>
        !['Projects', 'Stages', 'Config'].includes(currentNavInterface),
    },
    {
      description: 'Edit the characters for this project',
      icon: faUserAstronaut,
      navInterface: 'Characters',
      path: (tokens) => `/app/project/${tokens.projectId}/characters`,
      shouldShow: (currentNavInterface) =>
        !['Projects', 'Stages', 'Config'].includes(currentNavInterface),
    },
    {
      description: 'Edit the settings for this project',
      icon: faGopuram,
      navInterface: 'Settings',
      path: (tokens) => `/app/project/${tokens.projectId}/settings`,
      shouldShow: (currentNavInterface) =>
        !['Projects', 'Stages', 'Config'].includes(currentNavInterface),
    },
    {
      description: 'Edit the plot maps for this project',
      icon: faCodeBranch,
      navInterface: 'Plotmaps',
      path: (tokens) => `/app/project/${tokens.projectId}/plotmaps`,
      shouldShow: (currentNavInterface) =>
        !['Projects', 'Stages', 'Config'].includes(currentNavInterface),
    },
    {
      description: 'Edit the manuscript for this project',
      icon: faShoePrints,
      navInterface: 'Manuscript',
      path: (tokens) => `/app/project/${tokens.projectId}/manuscript`,
      shouldShow: (currentNavInterface) =>
        !['Projects', 'Stages', 'Config'].includes(currentNavInterface),
    },
    // {
    //   description: 'Dig into the wastebasket of this project',
    //   icon: faTrashRestore,
    //   navInterface: 'WasteBasket',
    //   path: (tokens) => `/app/project/${tokens.projectId}/wastebasket`,
    //   shouldShow: (currentNavInterface) =>
    //     !['Projects', 'Stages', 'Config'].includes(currentNavInterface),
    // },
  ],
]

export const userNavItems: NavTile[] = [
  {
    description: 'User',
    icon: faUserCircle,
    path: () => `#`,
    isDisabled: true,
    shouldShow: () => true,
  },
  {
    description: 'Go to the list of workflow stages for this project',
    icon: faHiking,
    path: () => `/logout/`,
    shouldShow: () => true,
  },
]
