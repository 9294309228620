import { transformLwkResponse } from '../../../../../integrations/litwitkit/shared'
import { Character } from '../../types'

declare var LWK_API_URL: 'string'

export type UpdateCharacterRequest = {
  archived: boolean
  biography: string
  description: string
  name: string
  primaryRole: string
  title: string
  projectId: string
}

export const updateById = async (
  token: string,
  id: string,
  characterUpdate: UpdateCharacterRequest,
): Promise<Character> => {
  const rawResponse = await fetch(`${LWK_API_URL}/characters/id/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    body: JSON.stringify(characterUpdate),
  })

  const { response } = await transformLwkResponse(rawResponse)
  return response as Character
}
