import * as React from 'react'
import { ToolbarButton } from './ToolbarButton'
import {
  ToolbarSection,
  MarkButtons,
  ActionButtons,
  BlockButtons,
} from './Toolbar.styles'
import {
  faBackspace,
  faBold,
  faHighlighter,
  faItalic,
  faStrikethrough,
  faTrash,
  faUnderline,
  faListOl,
  faListUl,
  faHorizontalRule,
} from 'Assets/fontawesome-pro-solid'

type ToolbarProps = {
  backspaceEnabled: boolean
  toggleBackspace?: () => void
  isActive: (type: string) => boolean
  toggle: (type: string, value?: string) => void
  wrap: () => void
  handleTrash?: () => void
}

export const Toolbar = ({
  backspaceEnabled,
  toggleBackspace,
  isActive,
  toggle,
  handleTrash,
}: ToolbarProps) => (
  <ToolbarSection>
    <MarkButtons>
      <ToolbarButton
        icon={faBold}
        onClick={() => toggle('bold')}
        isActive={isActive('bold')}
      />
      <ToolbarButton
        icon={faItalic}
        onClick={() => toggle('italic')}
        isActive={isActive('italic')}
      />
      <ToolbarButton
        icon={faUnderline}
        onClick={() => toggle('underline')}
        isActive={isActive('underline')}
      />
      <ToolbarButton
        icon={faStrikethrough}
        onClick={() => toggle('strikeThrough')}
        isActive={isActive('strikeThrough')}
      />
    </MarkButtons>
    <BlockButtons>
    <ToolbarButton
        icon={faListUl}
        onClick={() => toggle('insertUnorderedList')}
        isActive={false}
      />
      <ToolbarButton
        icon={faListOl}
        onClick={() => toggle('insertOrderedList')}
        isActive={false}
      />
      <ToolbarButton
        icon={faHorizontalRule}
        onClick={() => toggle('insertHorizontalRule')}
        isActive={false}
      />
      <ToolbarButton
        icon={faHighlighter}
        onClick={() => {
          const sel = window.getSelection()
          const parent = sel.anchorNode.parentElement
          if (parent && parent.tagName === 'HIGHLIGHT') {
            while (parent.firstChild) {
              parent.parentNode.insertBefore(parent.firstChild, parent)
            }
            parent.parentNode.removeChild(parent)
          } else {
            const rangeContent = sel.getRangeAt(0).cloneContents()
            const innerHTML = [...rangeContent.childNodes]
              .map(
                (node) => (node as HTMLElement).outerHTML || node.textContent,
              )
              .join('')
            document.execCommand(
              'insertHTML',
              false,
              `<highlight style="background: #eea">${innerHTML}</highlight>`,
            )
          }
        }}
        isActive={isActive('HIGHLIGHT')}
      />
    </BlockButtons>
    <ActionButtons>
      {toggleBackspace && (
        <ToolbarButton
          onClick={toggleBackspace}
          icon={faBackspace}
          isActive={backspaceEnabled}
          title={`Toggle the backspace/delete button`}
        />
      )}
      {handleTrash && (
        <ToolbarButton
          onClick={handleTrash}
          icon={faTrash}
          title={`Throw this in the wastebasket`}
        />
      )}
    </ActionButtons>
  </ToolbarSection>
)
