import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'
import { NavBarTileLinkProps } from './projectNavTypes'
import * as React from 'react'

const navBarBoxLength = `48px`

export const NavTitle = styled.h3`
  font-weight: normal;
  font-size: 18px;
  box-sizing: border-box;
  color: white;
  display: grid;
  line-height: ${navBarBoxLength};
  margin: 0;
  padding: 10px;
  place-content: center start;
  width: 100%;
`

export const navBar = (navBarVisibleTiles: number) => ({
  background: `#343434`,
  boxSizing: `border-box`,
  display: `grid`,
  gridGap: `5px`,
  gridTemplateColumns: `repeat(${navBarVisibleTiles}, ${navBarBoxLength}) 2fr 1fr ${navBarBoxLength}`,
  gridTemplateRows: navBarBoxLength,
  height: navBarBoxLength,
  padding: 0,
  placeItems: `center`,
  zIndex: 100,
  gridArea: `projectNav`,
})

export const navBarGroup = {
  height: navBarBoxLength,
  width: navBarBoxLength,
}

export const navBarTileIcon = {}

const FilteredNavBarTileLink = ({
  iscurrentinterface,
  isdisabled,
  ...props
}: any) => {
  return <Link {...props} />
}

export const NavBarTileLink = styled(FilteredNavBarTileLink)`
  background: ${(props: NavBarTileLinkProps) =>
    props.iscurrentinterface
      ? `rgba(98, 98, 98, 0.66)`
      : `rgba(98, 98, 98, 0.38)`};
  box-sizing: border-box;
  color: white;
  display: grid;
  height: 100%;
  padding: 5px;
  place-items: center;
  position: relative;
  width: 100%;
  &:last-child[href*='wastebasket'] {
    border-top: 2px solid hsl(0, 0%, 85%);
  }
  ${(props: NavBarTileLinkProps) =>
    props.isdisabled
      ? css`
          cursor: default;
        `
      : ''}
`

export const navBarTileTitle = {
  display: `none`,
}
