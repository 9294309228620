import { combineReducers } from 'redux'
import { StateType } from 'typesafe-actions'

import { reducer as auth } from './constructs/generic/Auth/reducer'
// import { reducer as brainstorms } from './constructs/primary/Brainstorms/reducer'
// import { reducer as characters } from './constructs/primary/Characters/charactersReducer'
import { reducer as copy } from './constructs/secondary/Copy/reducer'
// import { reducer as manuscript } from './constructs/primary/Manuscript/manuscriptReducer'
// import { reducer as plotmaps } from './constructs/primary/Plotmaps/plotmapsReducer'
import { reducer as premise } from './constructs/primary/Premise/premiseReducer'
import { reducer as stages } from './constructs/primary/Stages/stagesReducer'
import { reducer as projects } from './constructs/primary/Projects/projectsReducer'
// import { reducer as synopsis } from './constructs/primary/Synopsis/reducer'
import { reducer as telegram } from './constructs/secondary/Telegram/reducer'
// import { reducer as wasteBasket } from './constructs/primary/WasteBasket/wasteBasketReducer'
import { reducer as wordcounts } from './constructs/secondary/Wordcounts/reducer'

export const rootReducer = combineReducers({
  auth,
  // brainstorms,
  // characters,
  copy,
  // manuscript,
  // plotmaps,
  premise,
  stages,
  projects,
  // synopsis,
  telegram,
  // wasteBasket,
  wordcounts,
})

export type RootState = StateType<typeof rootReducer>
