import * as requests from './requests/create'
import { createResource } from '../../../../integrations/litwitkit/shared'
import { CreateBrainstormRequest } from './requests/create'

export const create = async (
  newBrainstorm: CreateBrainstormRequest,
  updateState: Function,
): Promise<void> => {
  const storageKeyPrefix = `brainstorm`

  const request = (token: string) => {
    return requests.create(token, newBrainstorm)
  }

  const transformData = (rawBrainstorm: any) => {
    return rawBrainstorm
  }

  const [status, resource] = await createResource(
    storageKeyPrefix,
    request,
    transformData,
  )

  updateState(resource)
}
