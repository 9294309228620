import * as React from 'react'
import {
  AddLineButton,
  AddLineIcon,
  LinesSection,
  LineDiv,
  Legend,
  LegendItem,
  LegendAction,
  Key,
  LegendDescription,
} from './plotmapEditor.styles'
import { BeatEditor } from '../BeatEditor/BeatEditor'
import { Beat, BeatPlacement } from '../../types'
import { BeatComponent } from './Beat'
import { DraggingBeatPlacement } from './PlotmapEditor'
import { keyUpHandler, keyDownHandler } from './Threadlines.handlers.keyboard'
import { createBeat } from '../../resources/create'
import { faPlusCircle } from 'Assets/fontawesome-pro-light'

type Line = {
  y: number
  beatPlacements: BeatPlacement[]
}

type Props = {
  beats: Beat[]
  beatPlacements: BeatPlacement[]
  currentBeatId: string
  setCurrentBeatId: (beatId: string) => void
  setBeats: (beats: Beat[]) => void
  setBeatPlacements: (beatPlacements: BeatPlacement[]) => void
  plotmapId: string
  draggingBeat: DraggingBeatPlacement
}

export const Threadlines = ({
  beats,
  beatPlacements,
  currentBeatId,
  setCurrentBeatId,
  setBeatPlacements,
  setBeats,
  plotmapId,
  draggingBeat,
}: Props) => {
  const [currentHotkey, setCurrentHotkey] = React.useState('')

  const currentBeatPlacements = beatPlacements.filter(
    (bp) => bp.plotmapId === plotmapId,
  )

  React.useEffect(() => {
    const keyUp = keyUpHandler(setCurrentHotkey)
    const keyDown = keyDownHandler(
      currentBeatId,
      currentBeatPlacements,
      setCurrentBeatId,
      setCurrentHotkey,
    )

    document.addEventListener('keydown', keyDown)
    document.addEventListener('keyup', keyUp)
    return () => {
      document.removeEventListener('keydown', keyDown)
      document.removeEventListener('keyup', keyUp)
    }
  })

  const currentBeat = beats.find((bt) => bt.id === currentBeatId)

  

  const linesSectionRef = React.useRef(null)

  const emptyLines = [
    {
      y: 0,
      beatPlacements: [],
    },
  ] as Line[]

  const lines = currentBeatPlacements
    .reduce((lines: Line[], beatPlacement: BeatPlacement): Line[] => {
      if (
        draggingBeat &&
        draggingBeat.beatId === beatPlacement.beatId &&
        (draggingBeat.newX || draggingBeat.newY)
      ) {
        beatPlacement.placementX = draggingBeat.newX
        beatPlacement.placementY = draggingBeat.newY
      }
      const existingLine = lines.find(
        (l: Line) => l.y === beatPlacement.placementY,
      )
      if (existingLine) {
        const updatedLines = [
          ...lines.filter((l: Line) => l.y !== beatPlacement.placementY),
          {
            ...existingLine,
            beatPlacements: [...existingLine.beatPlacements, beatPlacement],
          },
        ]
        return updatedLines
      } else {
        const addedLines = [
          ...lines,
          {
            y: beatPlacement.placementY,
            beatPlacements: [beatPlacement],
          },
        ]
        return addedLines
      }
    }, emptyLines)
    .sort((a, b) => a.y - b.y)

  return (
    <React.Fragment>
      <LinesSection
        ref={linesSectionRef}
        data-active-plotmap={true}
        currentHotkey={currentHotkey}
      >
        {lines.map((line) => (
          <LineDiv y={line.y} key={`${line.y}`}>
            {line.beatPlacements.map((bp) => (
              <BeatComponent
                key={bp.beatId}
                beat={beats.find((bt) => bt.id === bp.beatId)}
                beatPlacement={bp}
                isCurrentBeat={currentBeatId && currentBeatId === bp.beatId}
              />
            ))}
          </LineDiv>
        ))}
        <AddLineButton
          onClick={() => {
            const newY = lines[lines.length - 1].y + 1
            const newBeatPlacement = {
              placementX: 0,
              placementY: newY,
              placementZ: 0,
            }
            createBeat(newBeatPlacement, plotmapId, (newBeat: Beat) => {
              // set beats
              setBeats([...beats, newBeat])
              // set beatplacements
              setBeatPlacements([
                ...beatPlacements,
                {
                  beatId: newBeat.id,
                  plotmapId: plotmapId,
                  ...newBeatPlacement,
                },
              ])
              setCurrentBeatId(newBeat.id)
            })
          }}
        >
          <AddLineIcon icon={faPlusCircle} />
          <span>Add new thread line</span>
        </AddLineButton>
      </LinesSection>
      <Legend>
        <LegendItem>
          <LegendAction>
            <Key>Click</Key>
          </LegendAction>
          <LegendDescription>
            View and edit the beat's title and description
          </LegendDescription>
        </LegendItem>
        <LegendItem>
          <LegendAction>
            <Key>Click &amp; Drag</Key>
          </LegendAction>
          <LegendDescription>
            Slide a beat along a line, or from one line to another
          </LegendDescription>
        </LegendItem>
        <LegendItem>
          <LegendAction>
            <Key>Ctrl Click</Key> / <Key>&#8984; Click</Key>
          </LegendAction>
          <LegendDescription>Create a new beat</LegendDescription>
        </LegendItem>
        <LegendItem>
          <LegendAction>
            <Key>Ctrl Shift Click</Key> / <Key>&#8984; Shift Click</Key>
          </LegendAction>
          <LegendDescription>Delete a beat</LegendDescription>
        </LegendItem>
      </Legend>
      {currentBeatId && (
        <BeatEditor
          currentBeat={currentBeat}
          handleTrash={() => {}}
          setBeat={(beat: Beat) => {
            setBeats(beats.map((bt) => (bt.id === beat.id ? beat : bt)))
          }}
          key={`beat-editor-${currentBeatId}`}
        />
      )}
    </React.Fragment>
  )
}
