export const keyMap = [
  { type: 'bold', combo: 'mod+b', action: 'toggleMark' },
  { type: 'italic', combo: 'mod+i', action: 'toggleMark' },
  { type: 'underline', combo: 'mod+u', action: 'toggleMark' },
  { type: 'strikethrough', combo: 'mod+shift+x', action: 'toggleMark' },
]

export const keyboardShortcutLegend = {
  area: 'editor',
  label: { english: 'In the editor' },
  shortcuts: [
    { commands: ['Ctrl+b', `⌘b`], label: { english: 'Bold text' } },
    { commands: ['Ctrl+i', `⌘i`], label: { english: 'Italic text' } },
    {
      commands: ['Ctrl+u', `⌘u`],
      label: { english: 'Underline text' },
    },
    {
      commands: ['Ctrl+Shift+7', `⇧⌘7`],
      label: { english: 'Numbered list' },
    },
    {
      commands: ['Ctrl+Shift+8', `⇧⌘8`],
      label: { english: 'Bulleted list' },
    },
  ],
}
