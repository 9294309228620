import * as requests from '../requests/updateById'
import { updateResource } from '../../../../integrations/litwitkit/shared'
import { Synopsis } from '../types'

export const updateById = async (synopsis: Synopsis): Promise<void> => {
  const projectId = synopsis.projectId
  const storageKey = `project-${projectId}-synopsis`

  const request = (token: string) =>
    requests.updateById(token, synopsis.id, {
      archived: synopsis.archived,
      checklist: JSON.stringify(synopsis.checklist),
      content: synopsis.content,
    })

  const transformData = (rawSynopsis: any) => {
    const checklist = JSON.parse(rawSynopsis.checklist)
    return { ...rawSynopsis, checklist }
  }

  await updateResource(storageKey, synopsis, request, transformData)
}
