import * as requests from './requests/post'
import { createResource } from '../../../../integrations/litwitkit/shared'
import { CreateOutlineRequest, CreateSegmentRequest } from './requests/post'
import { SegmentType } from '../types'

export const createOutline = async (
  outlineRequest: CreateOutlineRequest,
  updateState: Function,
): Promise<void> => {
  const storageKeyPrefix = `outline`

  const request = (token: string) => {
    return requests.createOutline(token, outlineRequest)
  }

  const transformData = (rawOutline: any) => {
    return rawOutline
  }

  const [status, resource] = await createResource(
    storageKeyPrefix,
    request,
    transformData,
  )

  updateState(resource)
}

export const createSegment = async (
  projectId: string,
  type: SegmentType,
  updateState: Function,
): Promise<void> => {
  const storageKeyPrefix = `segment`

  const request = (token: string) => {
    return requests.createSegment(token, {
      projectId,
      type,
    })
  }

  const transformData = (rawSegment: any) => {
    return rawSegment
  }

  const [status, resource] = await createResource(
    storageKeyPrefix,
    request,
    transformData,
  )

  updateState(resource)
}
