import * as React from 'react'
import * as text from './text'
import {
  Title,
  BreadcrumbLink,
  BreadcrumbIcon,
  BreadcrumbText,
  BasicEditorSection,
} from './styles'
import { faArrowLeft } from 'Assets/fontawesome-pro-solid'
import { Editor } from 'Constructs/secondary/Editor/EditorBase'
import { Language, CopyText } from '../Copy/types'
import { Header } from '../EditorGenerics/EditorGenerics'

const debounceTime = 1000
const throttleTime = 1000
const maxSaveGap = 6000

type Props = {
  initialContent: string
  handleTrash?: () => void
  header?: JSX.Element
  id: string
  isTitleEditable?: boolean
  language?: Language
  projectId: string
  readOnly?: boolean
  setContent?: (content: string) => void
  setTitle?: (title: string) => void
  initialTitle: string
  update: (newTitle: string, newContent: string) => void
}

export const BasicEditor = ({
  initialContent,
  handleTrash,
  header,
  id,
  isTitleEditable = true,
  language,
  projectId,
  readOnly = false,
  initialTitle,
  update,
}: Props) => {
  const [lastEditorChange, setLastEditorChange] = React.useState(0)
  const [lastEditorSave, setLastEditorSave] = React.useState(0)
  const [editorTimeout, setEditorTimeout] = React.useState(null)

  const [title, setTitle] = React.useState(initialTitle)
  const [content, setContent] = React.useState(initialContent)

  const onEditorChange = (newContent: string) => {
    const timeSinceLastChange = Date.now() - lastEditorChange
    const timeSinceLastSave = Date.now() - lastEditorSave
    setContent(newContent)
    if (
      timeSinceLastSave > maxSaveGap ||
      (timeSinceLastChange > throttleTime && timeSinceLastSave > debounceTime)
    ) {
      clearTimeout(editorTimeout)
      update(title, newContent)
      setLastEditorSave(Date.now())
    } else {
      clearTimeout(editorTimeout)
      setEditorTimeout(
        setTimeout(() => onEditorChange(newContent), throttleTime + 1),
      )
    }
    setLastEditorChange(Date.now())
  }

  const onTitleChange = (newTitle: string) => {
    setTitle(newTitle)
    update(newTitle, content)
  }

  return (
    <BasicEditorSection>
      {projectId && (
        <BreadcrumbLink to={`/app/project/${projectId}`}>
          <BreadcrumbIcon icon={faArrowLeft} />
          <BreadcrumbText>Back to Project Stages</BreadcrumbText>
        </BreadcrumbLink>
      )}
      {header && <Header>{header}</Header>}
      <Title
        key={`basic-editor-title-${id}`}
        placeholder={text.placeholderTitle}
        value={title}
        disabled={!isTitleEditable || readOnly}
        onChange={onTitleChange}
        language={language}
      />

      <Editor
        onChange={onEditorChange}
        placeholder={text.placeholderBody['english']}
        readOnly={readOnly}
        initialValue={content}
        handleTrash={handleTrash}
        language={language}
        key={title}
      />
    </BasicEditorSection>
  )
}
