import { Signal } from '../../secondary/Telegram/types'
import { RootState } from '../../../rootReducer'

export const token = (state: any) => state.auth.token
export const isLoggedIn = (state: any) => state.auth.isLoggedIn
export const wasTokenVerified = (state: any) => state.auth.wasTokenVerified
export const isUserUnconfirmed = (state: any) => state.auth.isUserUnconfirmed

export const telegrams = (state: any) =>
  state.telegram.signalQueue.filter((s: Signal) => Boolean(s.copy))

  export const language = (state: RootState) => state.copy.language
