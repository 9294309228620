import * as React from 'react'

export default (
  <section>
    <h1>Privacy Policy</h1>
    This policy explains what information we collect when you use LitWitKit’s
    sites, services, mobile applications, products, and content ("Services"). It
    also has information about how we store, use, transfer, and delete that
    information. Our aim is not just to comply with privacy law. It’s to earn
    your trust.
    <h2>Information We Collect and How We Use It</h2>
    LitWitKit doesn’t make money from ads. So we don’t collect data in order to
    advertise to you. The tracking we do at LitWitKit is to make our product
    work as well as possible. In order to give you the best possible experience
    using LitWitKit, we collect information from your interactions with our
    network. Some of this information, you actively tell us (such as your email
    address, which we use to track your account or communicate with you). Other
    information, we collect based on actions you take while using LitWitKit,
    such as what pages you access and your interactions with our product
    features (like highlights, follows, and applause). This information includes
    records of those interactions, your Internet Protocol address, information
    about your device (such as device or browser type), and referral
    information. We use this information to: provide, test, improve, promote and
    personalize LitWitKit Services fight spam and other forms of abuse generate
    aggregate, non-identifying information about how people use LitWitKit
    Services When you create your LitWitKit account, and authenticate with a
    third-party service (like Twitter, Facebook or Google) we may collect,
    store, and periodically update information associated with that third-party
    account, such as your lists of friends or followers. We will never publish
    through your third-party account without your permission.
    <h2>Information Disclosure</h2>
    LitWitKit won’t transfer information about you to third parties for the
    purpose of providing or facilitating third-party advertising to you. We
    won’t sell information about you. We may share your account information with
    third parties in some circumstances, including: (1) with your consent; (2)
    to a service provider or partner who meets our data protection standards;
    (3) with academic or non-profit researchers, with aggregation,
    anonymization, or pseudonomization; (4) when we have a good faith belief it
    is required by law, such as pursuant to a subpoena or other legal process;
    (5) when we have a good faith belief that doing so will help prevent
    imminent harm to someone. If we are going to share your information in
    response to legal process, we’ll give you notice so you can challenge it
    (for example by seeking court intervention), unless we’re prohibited by law
    or believe doing so may endanger others. We will object to requests for
    information about users of our services that we believe are improper.
    <h2>Data Storage</h2>
    LitWitKit uses third-party vendors and hosting partners, such as Amazon, for
    hardware, software, networking, storage, and related technology we need to
    run LitWitKit. We maintain two types of logs: server logs and event logs. By
    using LitWitKit Services, you authorize LitWitKit to transfer, store, and
    use your information in the United States and any other country where we
    operate.
    <h2>Third-Party Embeds</h2>
    Some of the content that you see displayed on LitWitKit is not hosted by
    LitWitKit. These "embeds" are hosted by a third-party and embedded in
    LitWitKit. For example: YouTube or Vimeo videos, Imgur or Giphy gifs,
    SoundCloud audio files, Twitter tweets, GitHub code, or Scribd documents
    that appear within a LitWitKit post. These files send data to the hosted
    site just as if you were visiting that site directly (for example, when you
    load a LitWitKit post page with a YouTube video embedded in it, YouTube
    receives data about your activity). LitWitKit does not control what data
    third parties collect in cases like this, or what they will do with it. So,
    third-party embeds on LitWitKit are not covered by this privacy policy. They
    are covered by the privacy policy of the third-party service. Some embeds
    may ask you for personal information, such as your email address, through a
    form. We do our best to keep bad actors off of LitWitKit. However, if you
    choose to submit your information to a third party this way, we don’t know
    what they may do with it. As explained above, their actions are not covered
    by this Privacy Policy. So, please be careful when you see embedded forms on
    LitWitKit asking for your email address or any other personal information.
    Make sure you understand who you are submitting your information to and what
    they say they plan to do with it. We suggest that you do not submit personal
    information to any third-party through an embedded form. If you embed a form
    that allows submission of personal information by users, you must provide
    near the embedded form a prominent link to an applicable Privacy Policy that
    clearly states how to you intend to use any information collected. Failure
    to do so may lead LitWitKit to disable the post or take other action to
    limit or disable your account.
    <h2>Tracking and Cookies</h2>
    We use browser cookies and similar technologies to recognize you when you
    return to our Services. We use them in various ways, for example to log you
    in, remember your preferences (such as default language), evaluate email
    effectiveness, allow our paywall and meter to function, and personalize
    content and other information. LitWitKit doesn’t track you across the
    Internet. We track only your interactions within the LitWitKit network
    (which encompasses LitWitKit.com and custom domains hosted by LitWitKit).
    Some third-party services that we use to provide the LitWitKit Service, such
    as Google Analytics, may place their own cookies in your browser. This
    Privacy Policy covers use of cookies by LitWitKit only and not the use of
    cookies by third parties. LitWitKit complies with the "Do Not Track" ("DNT")
    standard recommended by the World Wide Web Consortium. For logged-out users
    browsing with DNT enabled, LitWitKit’s analytics will not receive data about
    you, but we will do some first-party tracking in order to customize content
    and provide data to third-party service providers that enable LitWitKit
    Services to work. When you use LitWitKit while logged-in to your account, we
    cannot comply with DNT. Here’s some more information about LitWitKit’s Do
    Not Track policy.
    <h2>Modifying or Deleting Your Personal Information</h2>
    If you have a LitWitKit account, you can access, modify or export your
    personal information, or delete your account here. To protect information
    from accidental or malicious destruction, we may maintain residual copies
    for a brief time period. But, if you delete your account, your information
    and content will be unrecoverable after that time. LitWitKit may preserve
    and maintain copies of your information when required to do so by law.
    <h2>Data Security</h2>
    We use encryption (HTTPS/TLS) to protect data transmitted to and from our
    site. However, no data transmission over the Internet is 100% secure, so we
    can’t guarantee security. You use the Service at your own risk, and you’re
    responsible for taking reasonable measures to secure your account.
    <h2>Business Transfers</h2>
    If we are involved in a merger, acquisition, bankruptcy, reorganization or
    sale of assets such that your information would be transferred or become
    subject to a different privacy policy, we’ll notify you in advance so you
    can opt out of any such new policy by deleting your account before transfer.
    <h2>Email from LitWitKit</h2>
    Sometimes we’ll send you emails about your account, service changes or new
    policies. You can’t opt out of this type of "transactional" email (unless
    you delete your account). But, you can opt out of non-administrative emails
    such as digests, newsletters, and activity notifications through your
    account’s "Settings" page. When you interact with an email sent from
    LitWitKit (such as opening an email or clicking on a particular link in an
    email), we may receive information about that interaction. We won’t email
    you to ask for your password or other account information. If you receive
    such an email, please send it to us so we can investigate.
    <h2>Changes to this Policy</h2>
    LitWitKit may periodically update this Policy. We’ll notify you about
    significant changes to it. The most current version of the policy will
    always be here and we will archive former versions of the policy here.
    <h2>Questions</h2>
    We welcome feedback about this policy at support@litwitkit.com.
    <h2>Data Protection Statement for European Union Users</h2>
    Description of Processing Activity: LitWitKit collects and stores personal
    information about its users to customize their writing/editing experience and enable
    personalized services. It shares minimal data with its
    service providers.
    <h2>Purposes of Processing</h2>
    In order to provide the services, LitWitKit collects
    and stores personal data about its users on the legal basis of consent given
    when you create an account and agree to the Privacy Policy. LitWitKit also
    pursues its legitimate interests by collecting minimal data of logged out
    users to provide the services, as outlined above. Where LitWitKit collects
    and stores personal data about non-users, it does so under performance of
    contract obligations with users who use the services to publish content on
    web sites hosted by LitWitKit. In such cases, users authoring such content
    containing personal data of third parties are responsible for that content.
    LitWitKit will consider related complaints in compliance with the General
    Data Protection Regulation’s rights of the data subject, as well as rights
    of expression and access to information.
    <h2>Public Nature of Personal Data</h2>
    Logged-in users may choose to interact publicly with the LitWitKit Services
    in the form of commenting on public content, following
    other user accounts, sharing links on connected social media accounts, or
    publishing original content. Where such personal data may reveal special category
    protected data, it is processed on the basis that it is manifestly made
    public by the user. Additional information on potential consequences of such
    processing can be found below. If you do not agree to this public usage, do
    not create an account or use these features of LitWitKit Services.
    Categories of Personal Data Collected for logged out users: History, IP
    address, Browser information, DNT status. For logged in users: Username, Display
    name, Bio, Avatar image, Email address (non-public), Session activity (security),
    Linked social media accounts (optional), IP address, Browser information,
    History (on LitWitKit network only), Network interactions,
    (recommends, follows, etc.) Posts, responses, or series published by user.
    Members: Billing information and history. Partner authors: Bank account for
    payments Business information, if applicable Categories of Recipients.
    LitWitKit shares minimal personal data with third-party processors in order
    to provide the Services. These processors offer at least the same level of
    data protection as that set out in this statement. This includes the
    following categories of recipients: Hosting, Storage, and Other
    Infrastructure Security Analytics Communication and Support Payment
    Processors Search engines will index user profiles, public interactions, and
    any user-generated content. Users may also share links to content on
    social media.
    <h2>Payment Processors</h2>
    LitWitKit provides Services in conjunction with several payment processors,
    including: Stripe, Paypal, Google Play, and Apple Pay, through which users
    may pay for LitWitKit memberships or receive payment based on participation
    in our Partner program. Those companies acting as payment processors may
    collect and store personal data related to your billing information and
    history in order to provide their services, and may collect and store
    personal data and business data to prevent fraud and other abuse. When you
    delete your LitWitKit account, LitWitKit deletes your personal data as
    outlined in this document. However, to delete your payment or billing
    information, you will need to do so with your payment provider, as LitWitKit
    only has minimal secure access to those records as needed to provide the
    services.
    <h2>Embedded Content</h2>
    LitWitKit posts may contain third-party embeds, which may in some cases
    collect and store personal data. The use of personal data by embedded
    content providers is not covered by this statement, but by the privacy
    policies of those sites or services.
    <h2>Existence of Automated Decision-making</h2>
    LitWitKit collects and stores personal data about its users to customize
    reading. This includes automated decision-making to promote content tailored
    to the preferences and interests indicated by the user, and to their
    browsing history and network interactions. LitWitKit also filters content
    for the purposes of fighting and preventing spam, fraud, and other forms of
    abuse.
    <h2>Potential Consequences of Processing</h2>
    By creating an account on LitWitKit, users may make certain personal data
    about themselves public and accessible to others on their profile and
    through network interactions. This may in some cases constitute special
    category protected data which is considered manifestly made public by the
    user. Due to the public nature of information posted to LitWitKit, it may be
    possible for third parties to derive identifying personal data from posts,
    whether by reading, inference, supplemental research, or automated
    extraction and analysis. Users are free to use their real name and
    information, or a pseudonym of their choosing, for their account. Users may
    also choose to use the service without posting data or engaging in network
    interactions. However, if you do not agree with and accept the risks of such
    usage, you may not use the services.
    <h2>Cross-border Transfers</h2>
    LitWitKit is hosted in the United States. By using LitWitKit Services, you
    authorize LitWitKit to transfer, store, and use your information in the
    United States and any other country where we operate. Where your data is
    disclosed to our processors, it is subject by contract to at least the same
    level of data protection as that set out in this statement.
    <h2>Retention</h2>
    LitWitKit retains personal data associated with your account for the
    lifetime of your account. If you would like to delete your personal
    information, you can delete your account at any time. Deleted account
    profile pages will yield an error 404 "file not found" page, immediately
    upon initiating deletion, and will become unrecoverable in our system after
    a period of fourteen days. It may take several additional days for your
    personal data to be de-indexed from search engines, depending on those
    search engines’ practices, over which LitWitKit may have limited or no
    control. To delete your payment or billing information, you will need to do
    so with your payment provider, as LitWitKit only has minimal secure access
    to those records as needed to provide the services.
  </section>
)
