import { transformLwkResponse } from '../../../../../integrations/litwitkit/shared'
import { Plotmap } from '../../types'

declare var LWK_API_URL: 'string'

export const deleteBeatFromPlotmap = async (
  token: string,
  plotmapId: string,
  beatId: string,
): Promise<Plotmap> => {
  const rawResponse = await fetch(
    `${LWK_API_URL}/plotmaps/id/${plotmapId}/beats/id/${beatId}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Token: token,
      },
    },
  )

  const { response } = await transformLwkResponse(rawResponse)
  return response
}
