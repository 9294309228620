import { updateSegment, updateOutline } from '../resources/update'
import { Segment, Outline, SegmentType } from '../types'
import * as H from 'history'
import { createSegment } from '../resources/create'

export const handlers = (
  currentSegment: Segment,
  currentOutline: Outline,
  projectId: string,
  setSegments: (segments: Segment[]) => void,
  setOutlines: (outlines: Outline[]) => void,
  segments: Segment[],
  outlines: Outline[],
  history: H.History<H.LocationState>,
) => {
  const handleTrash = () => {
    const newSegment = { ...currentSegment, archived: true }
    updateSegment(newSegment)
    setSegments(
      segments.map((seg) => (seg.id === currentSegment.id ? newSegment : seg)),
    )
    // const newOutline = {
    //   ...currentOutline,
    //   stagedSegmentIds: currentOutline.stagedSegmentIds.filter(
    //     (sid) => sid !== currentSegment.id,
    //   ),
    // }
    // updateOutline(newOutline, (outline: Outline) => {
    //   setOutlines(
    //     outlines.map((out) => (out.id === outline.id ? outline : out)),
    //   )
    // })
    const firstStagedSegmentId = currentOutline.stagedSegmentIds.find((sid) => {
      const seg = segments.find((seg) => seg.id === sid)
      return !seg.archived
    })
    history.push(
      `/app/project/${projectId}/manuscript/${currentOutline.id}/${
        firstStagedSegmentId || ''
      }`,
    )
  }

  const handleCreateSegment = (type: SegmentType) => {
    createSegment(projectId, type, (newSegment: Segment) => {
      setSegments([...segments, newSegment])
      const newOutline = {
        ...currentOutline,
        stagedSegmentIds: [...currentOutline.stagedSegmentIds, newSegment.id],
      }
      updateOutline(newOutline, (outline: Outline) => {
        setOutlines(
          outlines.map((out) => (out.id === outline.id ? outline : out)),
        )
      })
    })
  }

  return { handleCreateSegment, handleTrash }
}
