import { createResource } from '../../../../integrations/litwitkit/shared'
import { ConversationPostBody, conversationPostRequest } from './requests/post'

export const createConversation = async (
  conversationPostBody: ConversationPostBody,
  updateState: Function,
): Promise<void> => {
  const storageKeyPrefix = `outline`

  const request = (token: string) => {
    return conversationPostRequest(token, conversationPostBody)
  }

  const transformData = (rawOutline: any) => {
    return rawOutline
  }

  const [status, resource] = await createResource(
    storageKeyPrefix,
    request,
    transformData,
  ).catch((e) => updateState(false))

  updateState(true)
}
