import { Beat, Plotmap, BeatPlacement } from '../../types'
import { updatePlotmap } from '../../resources/update'
import { isKeyHotkey } from 'is-hotkey'

export const titleChangeHandler = (
  plotmap: Plotmap,
  setTitle: React.Dispatch<React.SetStateAction<any>>,
  setListTitle: (title: string) => void,
) => (title: string) => {
  updatePlotmap({ ...plotmap, title })
  setTitle(title)
  setListTitle(title)
}

const setCurrentBeatByOffset = (
  offset: number,
  currentBeatPlacement: BeatPlacement,
  beatPlacements: BeatPlacement[],
  setCurrentBeatId: React.Dispatch<React.SetStateAction<any>>,
) => {
  if (!currentBeatPlacement) {
    return
  }

  const orderedBeatPlacements = beatPlacements
    .filter((bp) => bp.placementY === currentBeatPlacement.placementY)
    .sort((a, b) => a.placementX - b.placementX)
  const currentBeatPlacementIndex = orderedBeatPlacements.findIndex(
    (bp) => bp.beatId === currentBeatPlacement.beatId,
  )
  const newBeatPlacementIndex = currentBeatPlacementIndex + offset
  const newBeatPlacement = orderedBeatPlacements[newBeatPlacementIndex]
  if (!newBeatPlacement) {
    return
  }
  const newCurrentBeatPlacement = beatPlacements.find(
    (bp) => bp.beatId === newBeatPlacement.beatId,
  )

  if (!newCurrentBeatPlacement) {
    return
  }

  setCurrentBeatId(newCurrentBeatPlacement.beatId)
}

export const keyDownHandler = (
  currentBeatId: string,
  beatPlacements: BeatPlacement[],
  setCurrentBeatId: React.Dispatch<React.SetStateAction<any>>,
  setCurrentHotkey: React.Dispatch<React.SetStateAction<any>>,
) => (e: KeyboardEvent) => {
  if (
    document.activeElement &&
    ['contentEditor', 'editableTitle'].includes(
      document.activeElement.getAttribute('data-domlabel'),
    )
  ) {
    return
  }

  const currentBeatPlacement = beatPlacements.find(
    (bp) => bp.beatId === currentBeatId,
  )

  if (isKeyHotkey('mod')(e)) {
    return setCurrentHotkey('mod')
  }
  if (isKeyHotkey('right')(e)) {
    return setCurrentBeatByOffset(
      1,
      currentBeatPlacement,
      beatPlacements,
      setCurrentBeatId,
    )
  }
  if (isKeyHotkey('left')(e)) {
    return setCurrentBeatByOffset(
      -1,
      currentBeatPlacement,
      beatPlacements,
      setCurrentBeatId,
    )
  }
  setCurrentHotkey('')
}

export const keyUpHandler = (
  setCurrentHotkey: React.Dispatch<React.SetStateAction<any>>,
) => (_: KeyboardEvent) => {
  setCurrentHotkey('')
}
