export const placeholderTitle = {
  english: 'Untitled',
  spanish: 'Intitulado',
}

export const title = {
  english: `Brainstorms`,
}

export const showArchived = {
  english: `Show archived`,
}

export const description = {
  english: `Think with your fingertips.`,
}
