import * as requests from './requests/get'
import { getResource } from '../../../../integrations/litwitkit/shared'
import { Manuscript, Outline, Segment, SegmentPlacement } from '../types'

export const getByProjectId = async (
  projectId: string,
  handleSuccess: Function,
  handleError: (e: any) => void,
): Promise<void> => {
  try {
    const storageKey = `project-${projectId}-outlines`
    const request = (token: string) => requests.getByProjectId(token, projectId)

    const resource: Manuscript = await getResource(storageKey, request)
    handleSuccess(resource)
  } catch (e) {
    handleError(e)
  }
}
