import * as requests from './requests/updateById'
import { updateResource } from '../../../../integrations/litwitkit/shared'
import { Setting } from '../types'

export const updateById = async (
  setting: Setting,
  projectId: string,
): Promise<void> => {
  const storageKey = `setting-${setting.id}`

  const request = (token: string) => {
    const {
      archived,
      description,
      category,
      title,
    } = setting

    return requests.updateById(token, setting.id, {
      archived,
      description,
      category,
      projectId,
      title,
    })
  }

  const transformData = (rawSetting: Setting) => {
    return rawSetting
  }

  const resource = await updateResource(
    storageKey,
    setting,
    request,
    transformData,
  )
}
