import { transformLwkResponse } from '../../../../../integrations/litwitkit/shared'
import { Brainstorm } from '../../types'

declare var LWK_API_URL: 'string'

export type UpdateBrainstormRequest = {
  archived: boolean
  brainstormType: string
  content: string
  title: string
  projectId: string
}

export const updateById = async (
  token: string,
  id: string,
  brainstormUpdate: UpdateBrainstormRequest,
): Promise<Brainstorm> => {
  const rawResponse = await fetch(`${LWK_API_URL}/brainstorms/id/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    body: JSON.stringify(brainstormUpdate),
  })

  const { response } = await transformLwkResponse(rawResponse)
  return response as Brainstorm
}
