import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const StatusIcon = styled(FontAwesomeIcon)`
  color: hsl(0, 0%, 40%);
`

export const TelegramStatus = styled.section`
  grid-area: 'telegramStatus';
`
export const MasterSpinnerSection = styled.section`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
  animation: 0.5s ease fadeIn;
  background: hsla(0, 0%, 98%, 0.5);
  display: grid;
  height: 100vh;
  place-items: center;
  position: absolute;
  top: 40px;
  width: 100vw;
  z-index: 50;
`

export const SpinnerIcon = styled(FontAwesomeIcon)`
  color: hsl(0, 0%, 80%);
  font-size: 17vh;
`

export const SuccessText = styled.span`
  color: hsl(0, 0%, 45%);
  margin-left: 7px;
`

export const ErrorText = styled.span`
  color: hsl(0, 0%, 90%);
  margin-left: 7px;
`
