import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { AuthenticatedRouteProps } from '../types'
import { refreshToken } from '../actions'
import * as selectors from '../selectors'

export const authenticatedRoute = (
  WrappedComponent: React.ComponentType<any>,
) => {
  class AuthenticatedRoute extends React.Component<AuthenticatedRouteProps> {
    componentDidMount = () => {
      const { wasTokenVerified, refreshToken } = this.props
      const storedToken = localStorage.getItem('token')
      if (storedToken && !wasTokenVerified) {
        refreshToken(storedToken)
      }
    }

    render = () => {
      if (this.props.isUserUnconfirmed) {
        return <Redirect push to='/account-confirmation-pending' />
      }
      if (!localStorage.getItem('token') && !this.props.wasTokenVerified) {
        return <Redirect push to='/login' />
      } else {
        return <WrappedComponent {...this.props} />
      }
    }
  }

  const mapStateToProps = (state: Object) => ({
    isLoggedIn: selectors.isLoggedIn(state),
    wasTokenVerified: selectors.wasTokenVerified(state),
    isUserUnconfirmed: selectors.isUserUnconfirmed(state),
  })

  const mapDispatchToProps = { refreshToken }

  return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute)
}

export const authenticationAwareRoute = (
  WrappedComponent: React.ComponentType<any>,
) => {
  class AuthenticationAwareRoute extends React.Component<
    AuthenticatedRouteProps
  > {
    componentDidMount = () => {
      const { wasTokenVerified, refreshToken } = this.props
      const storedToken = localStorage.getItem('token')
      if (storedToken && !wasTokenVerified) {
        refreshToken(storedToken)
      }
    }

    render = () => <WrappedComponent {...this.props} />
  }

  const mapStateToProps = (state: Object) => ({
    isLoggedIn: selectors.isLoggedIn(state),
    wasTokenVerified: selectors.wasTokenVerified(state),
  })

  const mapDispatchToProps = { refreshToken }

  return connect(mapStateToProps, mapDispatchToProps)(AuthenticationAwareRoute)
}
