import { keyboardShortcutLegend } from "Constructs/secondary/Editor/Editor.constants"

export const currentlyArchived = {
  english: 'This character is currently archived.',
}

export const deletePermanently = {
  english: 'Delete permanently.',
}

export const noItems = {
  english: `You don't have any characters yet. Create your first one!`,
}

export const help = {
  screenName: 'Characters',
  overview: [
    {
      content: {
        english: `Characters are the lifeblood and driving force of a story. 
        Keep track of them here and their names will be used in auto-completion.
        Link characters together for even smarter auto-completion hints.
        `,
      },
      title: { english: `Your Story's Driving Force` },
    },
    {
      content: {
        english: `Organize your characters by dragging and dropping them between 
        the provided, pre-defined categories.`,
      },
      title: { english: 'Stay Organized' },
    },
    {
      content: {
        english: `You can archive your characters or delete them permanently,`,
      },
      title: { english: `Kill Your Darlings` },
    },
  ],
  keys: [keyboardShortcutLegend],
}