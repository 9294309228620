import * as requests from './requests/deleteById'
import { Setting } from '../types'
import { deleteResource } from '../../../../integrations/litwitkit/shared'

export const deleteById = async (setting: Setting): Promise<void> => {
  const storageKey = `setting-${setting.id}`

  const request = (token: string) => {
    return requests.deleteById(token, setting)
  }

  const transformData = (rawSetting: any) => {
    return rawSetting
  }

  const [status, resource] = await deleteResource(storageKey, request)
}
