import * as React from 'react'
import { EditableTitleInput } from './styles'
import Copy from '../Copy/components/CopyComponent'
import { CopyText, Language } from '../Copy/types'

type Props = {
  className?: string
  disabled?: boolean
  onChange: (text: string) => void
  placeholder: string | CopyText
  value: string
  language?: Language
}

const placeholderIsCopyText = (
  placeholder: string | CopyText,
): placeholder is CopyText => placeholder.hasOwnProperty('english')

export default ({
  className,
  disabled = false,
  onChange,
  placeholder,
  value,
  language,
}: Props) => {
  const [titleWidth, setTitleWidth] = React.useState(0)
  React.useEffect(() => {
    setTitleWidth(calculateTitleWidth(value))
  })

  const titleDouble = React.createRef<HTMLInputElement>()

  const calculateTitleWidth = (title: string) => {
    const titleDoubleCurrent = titleDouble.current as HTMLInputElement
    if (!titleDoubleCurrent) {
      return 0
    }
    titleDoubleCurrent.value = title
    return titleDoubleCurrent.scrollWidth
  }

  const onTitleChange = (ev: React.SyntheticEvent) => {
    const title = (ev.target as HTMLInputElement).value
    setTitleWidth(calculateTitleWidth(title))
    onChange(title)
  }

  return (
    <React.Fragment>
      <EditableTitleInput
        className={className}
        type='text'
        data-domlabel={'editableTitle'}
        placeholder={
          placeholderIsCopyText(placeholder)
            ? (placeholder[language] as string)
            : placeholder
        }
        value={value}
        width={titleWidth}
        onChange={onTitleChange}
        visible={true}
        disabled={disabled}
      />
      <EditableTitleInput
        className={className}
        type='text'
        placeholder={
          placeholderIsCopyText(placeholder)
            ? (placeholder[language] as string)
            : placeholder
        }
        ref={titleDouble}
        visible={false}
        readOnly
      />
    </React.Fragment>
  )
}
