import { ChecklistItem } from '../../../integrations/litwitkit/entities'

export const premiseChecklistItems: ChecklistItem[] = [
  { name: 'Tension', checked: false, disabled: false },
  { name: 'Theme', checked: false, disabled: false },
  { name: 'Intrigue', checked: false, disabled: false },
  { name: 'Conflict', checked: false, disabled: false },
  { name: 'Relationship', checked: false, disabled: false },
]

export const text = {
  checklistEditor: {
    title: {
      english: `Premise Checklist`,
    },
    description: {
      english: `The premise describes the overarching idea behind the story in just a sentence or two. 
      It's the intrigue behind the story, or the promise to the reader that the story should fulfill. 
      It should contain some of the following:`,
    },
  },
}
