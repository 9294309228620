import { Setting } from '../../types'

declare var LWK_API_URL: 'string'

export const deleteById = (token: string, setting: Setting) => {
  return fetch(`${LWK_API_URL}/settings/id/${setting.id}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: token,
    },
    method: 'DELETE',
  })
}
