import * as React from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import Copy from '../../../secondary/Copy/components/CopyComponent'
import * as text from '../text'
import * as telegram from '../../../secondary/Telegram/actions'
import {
  FormScreen,
  FormContainerSection,
  FormTitle,
  Form,
  FormSubmit,
  FormLink,
  BreadcrumbIcon,
  BreadcrumbText,
  TelegramStation,
  FormCenterIcon,
} from './styles'
import { faArrowLeft } from 'Assets/fontawesome-pro-light'
import { faEnvelopeOpenText } from 'Assets/fontawesome-pro-solid'
import { resendConfirmationEmail } from '../resources/requests/resendConfirmationEmail'
import { Signal } from 'Constructs/secondary/Telegram/types'
import { confirmEmail } from '../resources/requests/confirmEmail'
import MasterSpinner from 'Constructs/secondary/Telegram/components/MasterSpinner'

const ConfirmAccount = () => {
  const [telegrams, setTelegrams] = React.useState([
    telegram.createSignal({ type: 'request', text: '', blocking: true }),
  ] as Signal[])
  const [confirmed, setConfirmed] = React.useState(false)

  const history = useHistory()

  let token, userId
  try {
    const queryString: string = location.search || ''
    const queryParams = Object.fromEntries(
      queryString
        .slice(1)
        .split(/&/)
        .map((q) => {
          const match = q.match(/(\w*)=(.*)/)
          return match[1] && match[2] ? [match[1], match[2]] : null
        }),
    )
    token = queryParams.t
    userId = queryParams.uid
  } catch (e) {
    return <Redirect push to='/login' />
  }

  if (!token || !userId) {
    return <Redirect push to='/login' />
  }

  confirmEmail(
    token,
    userId,
    () => setConfirmed(true),
    () =>
      setTelegrams([
        telegram.createSignal({
          type: 'error',
          copy: text.errors['UNKNOWN'],
          momentary: true,
        }),
      ]),
  )

  if (!confirmed) {
    return (
      <FormScreen>
        <MasterSpinner />
      </FormScreen>
    )
  }

  return (
    <FormScreen>
      <FormContainerSection>
        <BreadcrumbText>
          <BreadcrumbIcon icon={faArrowLeft} />
          <FormLink to={`/`}>Return to the intro screen</FormLink>
        </BreadcrumbText>
        <FormTitle>
          <Copy text={text.emailConfirmation.heading} />
        </FormTitle>
        <p>
          <Copy text={text.emailConfirmation.details} />
        </p>
        <FormSubmit
          type='submit'
          value={text.emailConfirmation.button.english as string}
          disabled={false}
          onClick={() => {
            history.push(`/login`)
          }}
        />
      </FormContainerSection>
    </FormScreen>
  )
}

export default ConfirmAccount
