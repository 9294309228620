import { getType } from 'typesafe-actions'

import { CopyReducerState } from './types'
import * as actions from './actions'

const initialState: CopyReducerState = {
  language: 'english',
}

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case getType(actions.setLanguage):
      return { ...state, language: action.payload }

    default:
      return state
  }
}
