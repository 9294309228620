import { getType } from 'typesafe-actions'

import { AuthReducerState } from './types'
import * as actions from './actions'

const initialState: AuthReducerState = {
  token: '',
  isLoggedIn: false,
  wasTokenVerified: false,
  isUserUnconfirmed: false,
}

const token = (state: AuthReducerState, action: any) => {
  switch (action.type) {
    case getType(actions.setToken):
      const token = action.payload
      localStorage.setItem('token', token)
      return { ...state, token, isLoggedIn: true, wasTokenVerified: true }
    case getType(actions.resetToken):
      localStorage.setItem('token', '')
      return { ...state, token: '', isLoggedIn: false, wasTokenVerified: true }
    case getType(actions.setUserUnconfirmed):
      return { ...state, isUserUnconfirmed: true, wasTokenVerified: true }
    default:
      return null
  }
}

export const reducer = (state = initialState, action: any) =>
  token(state, action) || state
